import clsx from "clsx";
import React from "react";
import { SentTaskStatus } from "../../api/models";
import { sentTaskStatusIconRenderer } from "../../services/statusesRendering";
import { CellOuterDivStyled } from "./styledComponents";

export function CellSentTaskStatusComponent({
  sentTaskStatus,
  showTaskStatusDialog,
}: {
  sentTaskStatus: SentTaskStatus | undefined;
  showTaskStatusDialog: boolean;
}) {
  return React.useMemo(() => {
    const failedTaskCell = clsx(showTaskStatusDialog ? "clickable-cell" : "");

    return <CellOuterDivStyled className={failedTaskCell}>{sentTaskStatusIconRenderer(sentTaskStatus)}</CellOuterDivStyled>;
  }, [sentTaskStatus, showTaskStatusDialog]);
}
