import { TextField } from "@material-ui/core";
import { HistoryItem } from "../../api/models";
import { booleanToString, dateWithTimeToString } from "../formatHelper";
import { SpacerHorizontalDivStyled, SpacerSize, SpacerVerticalDivStyled } from "../Spacer";
import { FlexDivStyled } from "../styledComponents";
import GridDialogItemContainer from "./GridDialogItemContainer";

interface SentHistoryItemDialogProps {
  item: HistoryItem;
  isOpen: boolean;
  onCloseAction: () => void;
}

export default function SentHistoryItemDialog({ item, isOpen, onCloseAction }: SentHistoryItemDialogProps) {
  return (
    <GridDialogItemContainer isOpen={isOpen} onCloseAction={onCloseAction}>
      <>
        <FlexDivStyled>
          <TextField value={dateWithTimeToString(item.timestamp)} fullWidth disabled label="Timestamp" variant="filled" size="small" />
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium }} />
          <TextField value={item.appliesTo} fullWidth disabled label="Applies to" variant="filled" size="small" />
        </FlexDivStyled>
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        <FlexDivStyled>
          <TextField
            value={booleanToString(item.isSent)}
            fullWidth
            disabled
            label="Communication with PUESC?"
            variant="filled"
            size="small"
          />
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium }} />
          <TextField value={booleanToString(item.isError)} fullWidth disabled label="Error?" variant="filled" size="small" />
        </FlexDivStyled>
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        <TextField
          label="Description"
          fullWidth
          disabled
          value={item.description}
          multiline
          contentEditable={false}
          rows={12}
          variant="filled"
        />
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
      </>
    </GridDialogItemContainer>
  );
}
