import React from "react";
import API from "../../api/api";
import { DialogPayload, getCommunicationErrorType } from "../../api/common";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { prepareDialogGridData } from "../../services/dialogGrid";
import GridDialogContainer from "./GridDialogContainer";
import { InfoAboutControl } from "../../api/models";
import { logGridError } from "../../api/errorReporter";
import ServerCommunicationError, { CommunicationErrorType } from "../ServerCommunicationIssue";
import { useGridStyles } from "../gridCustomTheme";

interface InfAboutControlDialogProps {
  dialogPayload: DialogPayload;
  isOpen: boolean;
  onCloseAction: () => void;
}

export default function InfAboutControlDialog({ dialogPayload, isOpen, onCloseAction }: InfAboutControlDialogProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [communicationError, setCommunicationError] = React.useState<CommunicationErrorType>();
  const [infoAboutControl, setInfoAboutControl] = React.useState<InfoAboutControl>();

  React.useEffect(() => {
    setInfoAboutControl(undefined);
    setCommunicationError(undefined);
    setLoading(true);

    API.get(`/infoAboutControl/${dialogPayload.tripSourceId}/${dialogPayload.tripId}`)
      .then((response: any) => setInfoAboutControl(response.data as InfoAboutControl))
      .catch((err: any) => setCommunicationError(getCommunicationErrorType(err)))
      .finally(() => setLoading(false));
  }, [dialogPayload]);

  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: "Date",
      width: 120,
      type: "dateTime",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "orderId",
      headerName: "Order ID",
      width: 90,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "placeOfControl",
      flex: 1,
      headerName: "Place of control",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "recommendationsForControl",
      flex: 2,
      headerName: "Recomendations for control",
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  const classes = useGridStyles();

  return (
    <GridDialogContainer
      title="Info about control"
      dialogPayload={dialogPayload}
      isOpen={isOpen}
      loading={loading}
      onCloseAction={onCloseAction}>
      <>
        {communicationError && <ServerCommunicationError errType={communicationError} />}
        {infoAboutControl && (
          <DataGrid
            className={classes.root}
            rows={prepareDialogGridData(infoAboutControl.infosAboutControl)}
            columns={columns}
            density="compact"
            disableColumnReorder
            disableSelectionOnClick
            disableColumnResize
            headerHeight={48}
            pageSize={50}
            rowsPerPageOptions={[]}
            onError={logGridError}></DataGrid>
        )}
      </>
    </GridDialogContainer>
  );
}
