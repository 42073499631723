import React from "react";
import { DataEditMode } from "../../models/common";
import { RowType } from "../../services/gridDataEdit";
import { tooltipRow } from "../../services/statusesRendering";
import { appColors } from "../colors";
import { EditModeCellContainerDivStyled } from "../gridCells/styledComponents";
import GridHeaderIcon from "../gridTooltips/GridHeaderIcon";
import GridHeaderTooltip from "../gridTooltips/GridHeaderTooltip";
import { IconIStyled } from "../styledComponents";
import { TooltipHeaderDivStyled } from "./styledComponents";

export function CellHeaderEditModeComponent({ rowType }: { rowType: RowType }) {
  function editModeCellRenderer(
    editMode: DataEditMode | undefined,
    hasError: boolean | undefined,
    isRowEditable: boolean,
    size: number
  ): React.ReactElement {
    let iconClassName = isRowEditable ? "fas fa-lock-open" : "fas fa-lock";

    let iconColor: string;
    if (isRowEditable) {
      iconColor = hasError ? appColors.red : appColors.green;
    } else {
      iconColor = appColors.grey;
    }

    switch (editMode) {
      case DataEditMode.InSync:
        iconClassName = "fas fa-check";
        break;
      case DataEditMode.HasPendingEdit:
        iconClassName = "fas fas fa-edit";
        break;
      case DataEditMode.SavingInProgress:
        iconClassName = "fas fa-spin fa-spinner";
        iconColor = appColors.orange;
        break;
      case DataEditMode.SavingError:
        iconClassName = "fas fa-exclamation-triangle";
        iconColor = appColors.red;
        break;
    }

    const fontSize = Math.floor(size / 1.7);
    const styledProps = { size: size, fontSize: fontSize, color: appColors.white };
    return (
      <EditModeCellContainerDivStyled {...styledProps}>
        <IconIStyled className={iconClassName} {...{ color: iconColor }} />
      </EditModeCellContainerDivStyled>
    );
  }

  const iconSize = 24;
  return (
    <GridHeaderTooltip
      tooltipContent={
        <div>
          <TooltipHeaderDivStyled>Row edit mode status</TooltipHeaderDivStyled>
          {rowType === RowType.Fuel && tooltipRow(editModeCellRenderer(undefined, undefined, false, iconSize), "Row NOT editable")}
          {rowType === RowType.Fuel && tooltipRow(editModeCellRenderer(undefined, undefined, true, iconSize), "Row editable - no edits")}
          {tooltipRow(
            editModeCellRenderer(DataEditMode.HasPendingEdit, true, true, iconSize),
            "Pending edit - invalid data - hover on icon in specific row to see validation errors"
          )}
          {tooltipRow(editModeCellRenderer(DataEditMode.HasPendingEdit, undefined, true, iconSize), "Pending edit - valid data")}
          {rowType === RowType.Fuel &&
            tooltipRow(editModeCellRenderer(DataEditMode.SavingInProgress, undefined, true, iconSize), "Saving in progress")}
          {rowType === RowType.Fuel && tooltipRow(editModeCellRenderer(DataEditMode.InSync, undefined, true, iconSize), "Data saved")}
          {rowType === RowType.Fuel &&
            tooltipRow(editModeCellRenderer(DataEditMode.SavingError, undefined, true, iconSize), "Saving failed - click to see details")}
        </div>
      }>
      <GridHeaderIcon></GridHeaderIcon>
    </GridHeaderTooltip>
  );
}
