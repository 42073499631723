import styled from "styled-components";
import { appColors } from "../colors";
import { IconIStyled } from "../styledComponents";

const DivStyled = styled.div`
  font-size: 18px;
  color: ${appColors.blue};
`;

export default function GridHeaderIcon() {
  return (
    <DivStyled>
      <IconIStyled className="fas fa-question-circle" {...{ size: 15 }} />
    </DivStyled>
  );
}
