import { GridRowsProp } from "@material-ui/data-grid";
import { HistoryItem, InfoAboutControlItem, OfficialInfoItem } from "../api/models";

type DialogGridItem = InfoAboutControlItem | OfficialInfoItem | HistoryItem;

export function prepareDialogGridData(data: DialogGridItem[] | null | undefined): GridRowsProp {
  if (!data) {
    return [];
  }

  return data
    .sort((x, y) => (x.timestamp! > y.timestamp! ? 1 : 0))
    .map((x, index) => {
      return {
        ...x,
        id: index,
      };
    });
}
