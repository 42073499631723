import styled from "styled-components";
import { appColors } from "./colors";

const LoadingContainerDivStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  background-color: rgb(0, 0, 0, 0.6);
`;

const LoadingSpinnerDivStyled = styled.div`
  display: inline-block;
  width: 64px;
  height: 64px;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 12px;
    border-radius: 50%;
    border: 12px solid ${appColors.orange};
    border-color: ${appColors.red} transparent ${appColors.orange} transparent;
    animation: loading-spinner 1.1s normal infinite;
  }
  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface LoaderProps {
  isLoading: boolean;
}

export default function Loader(props: LoaderProps) {
  if (props.isLoading) {
    return (
      <LoadingContainerDivStyled>
        <LoadingSpinnerDivStyled></LoadingSpinnerDivStyled>
      </LoadingContainerDivStyled>
    );
  }

  return <></>;
}
