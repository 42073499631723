import { ErrorInfo } from "react";
import { getLoggedInUser } from "../common/secrityUtils";
import API from "./api";
import { LogError } from "./models";

enum ErrorSource {
  Global = "global",
  ErrBoundary = "errBoundary",
  Grid = "grid",
}

export function logAppGlobalError(event: Event | string, error?: Error) {
  sendError(ErrorSource.Global, event.toString(), error?.stack, undefined);
}

export function logError(error: Error, errorInfo: ErrorInfo) {
  sendError(ErrorSource.ErrBoundary, error?.message, error?.stack, errorInfo?.componentStack);
}

export function logGridError(params: any) {
  const error = params.error as Error;
  const errorInfo = params.errorInfo as ErrorInfo;

  sendError(ErrorSource.Grid, error?.message, error?.stack, errorInfo?.componentStack);
}

function sendError(source: ErrorSource, errorMessage: string, errorStack: string | undefined, componentStack: string | undefined) {
  const account = getLoggedInUser(false);
  const body: LogError = {
    userName: account?.username || "<anonymous_user>",
    source: source,
    errorMessage: errorMessage,
    errorStack: errorStack,
    componentStack: componentStack,
  };

  return API.post("/logError", body).catch((x) => console.error(x));
}
