import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { DialogPayload } from "../../api/common";
import Loader from "../Loader";
import { SpacerHorizontalDivStyled, SpacerSize } from "../Spacer";
import { DialogTitleContainerDivStyled } from "./DialogTitleContainerDivStyled";

interface DialogProps {
  title: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  height?: number;
  dialogPayload: DialogPayload;
  hideItemKey?: boolean;
  isOpen: boolean;
  loading: boolean;
  onCloseAction: () => void;
  children: JSX.Element | undefined;
}

export default function GridDialogContainer({
  title,
  maxWidth,
  height,
  dialogPayload,
  hideItemKey,
  isOpen,
  loading,
  onCloseAction,
  children,
}: DialogProps) {
  return (
    <>
      <Loader isLoading={loading}></Loader>
      <Dialog open={isOpen} fullWidth maxWidth={maxWidth || "md"} onClose={() => onCloseAction && onCloseAction()}>
        <DialogTitle disableTypography>
          {hideItemKey && <h3>{title}</h3>}
          {!hideItemKey && (
            <DialogTitleContainerDivStyled>
              <h3>{title}</h3>
              <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium }} />
              <h4>
                Vehicle ID: {dialogPayload.vehicleId} | Trip ID: {dialogPayload.tripId} | Order ID: {dialogPayload.orderId}
              </h4>
            </DialogTitleContainerDivStyled>
          )}
        </DialogTitle>
        <DialogContent>
          <div style={{ height: height || 600 }}>{children}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCloseAction && onCloseAction()} variant="outlined" color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
