import { getConfiguration } from "../api/configuration";
import NotificationBox, { NotificationBoxType } from "./Notification";

export enum CommunicationErrorType {
  ConnectionProblem = "connectionProblem",
  ServerErrorResponse = "serverErrorResponse",
}

export default function ServerCommunicationError({ errType }: { errType: CommunicationErrorType }) {
  var configuration = getConfiguration();
  let message = errType === CommunicationErrorType.ConnectionProblem ? configuration.clientErrorMessage : configuration.serverErrorMessage;

  return <NotificationBox type={NotificationBoxType.Error} message={message}></NotificationBox>;
}
