import { createMuiTheme, Dialog, DialogContent, ThemeProvider } from "@material-ui/core";

interface DialogProps {
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  height?: number;
  isOpen: boolean;
  onCloseAction: () => void;
  children: JSX.Element | undefined;
}

const dialogItemsTheme = createMuiTheme({
  palette: {
    text: {
      disabled: "rgba(0, 0, 0, 0.68)",
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
      },
    },
    MuiInputBase: {
      input: {
        color: "black",
        padding: "5px",
      },
      root: {
        color: "black",
        fontSize: "12px",
      },
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingBottom: "5px",
      },
      multiline: {
        paddingTop: "15px",
        paddingBottom: "10px",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "14px",
      },
    },
  },
});

export default function GridDialogItemContainer({ maxWidth, height, isOpen, onCloseAction, children }: DialogProps) {
  return (
    <Dialog open={isOpen} fullWidth maxWidth={maxWidth || "sm"} onClose={() => onCloseAction && onCloseAction()}>
      <DialogContent>
        <ThemeProvider theme={dialogItemsTheme}>
          <div style={{ maxHeight: height || 600 }}>{children}</div>
        </ThemeProvider>
      </DialogContent>
    </Dialog>
  );
}
