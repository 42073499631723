import { Button } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import ckLogo from "../img/cklogo.png";
import visentLogo from "../img/visentlogo.png";
import SentAutomationStatus from "./SentAutomationStatus";
import styled from "styled-components";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginScopes } from "../authConfig";
import { AdminRoutes, LpgFuelRoutes } from "../routes/routeUtils";
//import { checkIfUserInGroup, FUEL_LPG_AD_GROUP_ID } from "./secrityUtils";
import { isUserInRole } from "./secrityUtils";
import { SpacerHorizontalDivStyled, SpacerSize } from "./Spacer";
import { appColors } from "./colors";
import { IconIStyled } from "./styledComponents";

const NavFixedTopStyled = styled.nav`
  min-width: 900px;
  background: #fff;
  transition-duration: 0.4s;
  border-bottom: 1px solid #e7eaec !important;
  position: fixed;
  top: 5px;
  right: 0;
  left: 0;
  z-index: 999;
`;

const DivNavbarHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DivNavbarMenuStyled = styled.div`
  display: flex;
  font-size: 14px;
  margin: 0 15px 5px 15px;
  border-top: 2px solid #e7eaec;
  padding: 3px 0 2px 0;
  min-height: 20px;
`;

const DivNavbarHeaderLeftStyled = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin-left: 20px;
  }
`;

const DivNavbarHeaderRightStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 15px;

  span {
    font-size: 14px;
  }
`;

const LogoImgStyled = styled.img`
  margin: 10px 15px 10px 15px;
`;

const ViSentLogoImgStyled = styled.img`
  margin: 10px 15px 10px 0px;
  height: 50px;
`;

export const NavLinkStyled = styled(NavLink)`
  text-decoration: none;
  color: ${appColors.orange};
  transition: all 0.5s;

  &.active {
    font-weight: 700;
    text-decoration: underline;
  }
  :hover {
    color: ${appColors.red};
  }
`;

export default function Header() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  //const isInFuelLpgGroup = checkIfUserInGroup(FUEL_LPG_AD_GROUP_ID);
  const isPlanner = isUserInRole("Planner");
  const isAdmin = isUserInRole("Admin");

  return (
    <NavFixedTopStyled role="navigation">
      <DivNavbarHeaderStyled>
        <DivNavbarHeaderLeftStyled>
          <LogoImgStyled src={ckLogo} alt="Circle K" />
          <ViSentLogoImgStyled src={visentLogo} alt="ViSENT App" />
          <h2>ViSENT</h2>
        </DivNavbarHeaderLeftStyled>
        <SentAutomationStatus></SentAutomationStatus>
        <DivNavbarHeaderRightStyled>
          {isAuthenticated && (
            <>
              <span>
                <Link to="/userinfo" >
                  <IconIStyled className="fas fa-user" aria-hidden="true" style={{ marginRight: '8px', color: '#444' }} />
                </Link>
                {account?.name}
              </span>
              <SpacerHorizontalDivStyled {...{ size: SpacerSize.Large }} />
              <Button variant="text" color="primary" onClick={() => instance.logout()}>
                Sign out
              </Button>
            </>
          )}
          {!isAuthenticated && (
            <Button variant="contained" color="primary" onClick={() => instance.loginRedirect({ scopes: loginScopes })}>
              Sign in
            </Button>
          )}
        </DivNavbarHeaderRightStyled>
      </DivNavbarHeaderStyled>
      <DivNavbarMenuStyled>
        {/* {isInFuelLpgGroup && ( */}
        {isPlanner && (
          <>
            <NavLinkStyled to={LpgFuelRoutes.Fuel} activeClassName="active">
              Fuel Deliveries
            </NavLinkStyled>
            <SpacerHorizontalDivStyled {...{ size: SpacerSize.Large }} />
            <NavLinkStyled to={LpgFuelRoutes.Lpg} activeClassName="active">
              LPG Deliveries
            </NavLinkStyled>
          </>
        )}

        {isAdmin && (
          <>
            {isPlanner && <SpacerHorizontalDivStyled {...{ size: SpacerSize.Large }} />}
            <NavLinkStyled to={AdminRoutes.AdminPage} activeClassName="active">
              Admin
            </NavLinkStyled>
          </>
        )}





      </DivNavbarMenuStyled>
    </NavFixedTopStyled>
  );
}
