import React from "react";
import { SentStatus } from "../../api/models";
import { sentStatusIconRenderer } from "../../services/statusesRendering";
import { CellOuterDivStyled } from "./styledComponents";

export function CellSentStatusComponent({
  sentStatus,
  closedIncompliant,
}: {
  sentStatus: SentStatus | undefined;
  closedIncompliant: boolean | undefined;
}) {
  return React.useMemo(
    () => <CellOuterDivStyled>{sentStatusIconRenderer(sentStatus, closedIncompliant)}</CellOuterDivStyled>,
    [sentStatus, closedIncompliant]
  );
}
