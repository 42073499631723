import React from "react";
import { IconIStyled } from "../styledComponents";
import { CellMenuDivStyled } from "./styledComponents";

export function CellMenuComponent() {
  return React.useMemo(
    () => (
      <CellMenuDivStyled>
        <IconIStyled className="fas fa-ellipsis-v" />
      </CellMenuDivStyled>
    ),
    []
  );
}
