export function addressLine1(street: string | null | undefined, houseNumber: string | null | undefined) {
  return `${street} ${houseNumber}`.trim();
}

export function addressLine2(postalCode: string | null | undefined, city: string | null | undefined, country: string | null | undefined, district?: string | null) {
  let line = `${postalCode} ${city}`.trim();
  if (district && country) {
    line += `, ${district} ${country}`.trim();
  } else if (district) {
    line += `, ${district}`.trim();
  } else if (country) {
    line += `, ${country}`.trim();
  }

  return line.trim();
}

export function booleanToString(value: boolean | null | undefined) {
  if (value !== null && value !== undefined) {
    return value ? "YES" : "NO";
  }
}

export function dateWithTimeToString(value: Date | string | null | undefined) {
  if (value) {
    return value instanceof Date ? value.toLocaleString() : new Date(value).toLocaleString();
  }
}

export function dateToString(value: Date | string | null | undefined) {
  if (value) {
    //return value instanceof Date ? value.toLocaleDateString() : new Date(value).toLocaleDateString();
    return new Date(value).toJSON().substring(0, 10);
  }
}

export function latLonToString(lat: number | null | undefined, lon: number | null | undefined) {
  if (lat && lon) {
    return `${lat}/${lon}`;
  }
}
