import React, { SyntheticEvent, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AdminRoutes } from "../../routes/routeUtils";
import API from "../../api/api";
import ServerCommunicationError, { CommunicationErrorType } from "../../common/ServerCommunicationIssue";
import { Button, Icon, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { AppUser, AppUserAssignment, IntegrationPartnerSimple, PlannerKit } from "../../api/models";
import { PageContainerDivStyled } from "../pageStyledComponents";
import Loader from "../../common/Loader";

export default function PlannerPage() {
  const history = useHistory();
  const { upn } = useParams<{ upn: string }>();
  const [userEdited, setUserEdited] = useState<AppUser>({
    userPrincipalName: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    assignments: []
  });
  const [userLoaded, setUserLoaded] = useState<AppUser>();
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [userError, setUserError] = useState<CommunicationErrorType>();
  const [userSaving, setUserSaving] = useState<boolean>(false);
  const [userSaveError, setUserSaveError] = useState<any>();
  const [userSaveSuccess, setUserSaveSuccess] = useState<boolean>();

  const [newAssignment, setNewAssignment] = useState<AppUserAssignment>();
  const [partners, setPartners] = useState<IntegrationPartnerSimple[]>();
  const [isAddingOK, setIsAddingOK] = useState<boolean>(false);
  const [anyChange, setAnyChange] = useState<boolean>(false);

  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [noChangesDialogOpen, setNoChangesDialogOpen] = useState<boolean>(false);
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState<boolean>(false);


  // Initial data load
  useEffect(() => {
    setUserLoading(true);
    setUserError(undefined);

    let req: Promise<any>;
    req = API.get(`/Users/PlannerKit/${upn}`);
    req.then(resp => {
      let respContent: PlannerKit = resp.data;
      let user = respContent.appUser as AppUser;
      if (!Array.isArray(user.assignments)) user.assignments = [];
      setUserLoaded(user);
      setUserEdited(user);
      setPartners(respContent.integrationPartners!);
      setUserLoading(false);
    })
      .catch(err => setUserError(err))
      .finally(() => setUserLoading(false))

  }, [upn]);


  // Check if user did any changes
  useEffect(() => {
    // Need to compare userLoaded to userEdited
    if (
      userLoaded?.userPrincipalName !== userEdited?.userPrincipalName ||
      userLoaded?.firstName !== userEdited?.firstName ||
      userLoaded?.lastName !== userEdited?.lastName ||
      userLoaded?.emailAddress !== userEdited?.emailAddress
    ) {
      // console.log("Any change: user data")
      setAnyChange(true);
      return;
    }

    // User-Planner assignments
    let oldAsg: AppUserAssignment[] = userLoaded?.assignments || [];
    let newAsg: AppUserAssignment[] = userEdited?.assignments || [];
    // console.log("Old assignment:", userLoaded.assignments);
    // console.log("New assignment:", userEdited.assignments);

    // Check if both arrays have the same size
    if (oldAsg.length !== newAsg.length) {
      // console.log("Any change: different array sizes")
      setAnyChange(true);
      return;
    }

    // Check if any assignment added
    for (let i = 0; i < newAsg.length; i++) {
      if (!oldAsg.some(a =>
        a.integrationPartnerId === newAsg[i].integrationPartnerId &&
        a.plannerId === newAsg[i].plannerId &&
        a.enabled === newAsg[i].enabled
      )) {
        // console.log("Any change: assignment added")
        setAnyChange(true);
        return;
      }
    }

    // Check if any assignments removed
    // NOTE: This is redundant. Checking two
    // previous condition eliminates need
    // for this check

    // for (let i = 0; i < oldAsg.length; i++) {
    //   if (!newAsg.some(a =>
    //     a.integrationPartnerId === oldAsg[i].integrationPartnerId &&
    //     a.plannerId === oldAsg[i].plannerId &&
    //     a.enabled === oldAsg[i].enabled
    //   )) {
    //     console.log("Any change: assignment removed")
    //     setAnyChange(true);
    //     return;
    //   }
    // }

    // No changes detected
    // console.log("Any change: no changes detected");
    setAnyChange(false);

  }, [userEdited, userLoaded]);


  // Add user-planner assignment
  const handleAddAssignment = () => {
    let curAsg: AppUserAssignment[] = userEdited.assignments || [];
    let newAsg: AppUserAssignment = {
      integrationPartnerId: newAssignment?.integrationPartnerId?.trim(),
      plannerId: newAssignment?.plannerId?.trim(),
      enabled: newAssignment?.enabled
    }
    // Prevent duplicate
    if (!curAsg.some(a => a.integrationPartnerId === newAsg.integrationPartnerId && a.plannerId === newAsg.plannerId)) {
      setUserEdited({ ...userEdited, assignments: [...curAsg, newAsg] });
    }
    setNewAssignment(undefined);
  }


  // Remove user-planner assignment
  const handleDelAssignment = (asgToDelete: AppUserAssignment) => {
    if (userEdited.assignments) {
      let newAssignments: AppUserAssignment[] = userEdited.assignments.filter(a =>
        a.integrationPartnerId !== asgToDelete.integrationPartnerId ||
        a.plannerId !== asgToDelete.plannerId);
      setUserEdited({ ...userEdited, assignments: newAssignments });
    }
  }

  // Close without saving
  const handleClose = (confirmToClose: boolean = true) => {
    if (confirmToClose && (anyChange || (newAssignment && isAddingOK)))
      setConfirmCloseDialogOpen(true);
    else
      history.push(AdminRoutes.Users);
  }

  // Submit data
  function handleSubmit(event: SyntheticEvent | null) {
    event?.preventDefault();

    console.log("Initial User:", userLoaded);
    console.log("Modified User:", userEdited);
    console.log("Posting to API...")

    if (anyChange || (newAssignment && isAddingOK)) {
      setUserSaving(true);
      setUserSaveError(undefined);

      let userToSave: AppUser = userEdited;
      // If there is not completed assignment...
      if ((newAssignment && isAddingOK)) {
        userToSave = { ...userToSave, assignments: [...(userToSave?.assignments || []), newAssignment] }
      }
      console.log("Saving user...", userToSave);

      let request: Promise<any>;
      //request = API.put("/Users", userEdited);
      request = API.put("/Users", userToSave);

      request
        .then(resp => {
          console.log("Successfully posted");
          console.log(resp);
          setUserSaveSuccess(true);
        })
        .catch(err => {
          setUserSaveError(err.response);
          console.log("Ooops! An error occurred.")
          console.log(err.response);
          console.log(err.response?.data?.errors);
          setErrorDialogOpen(true);
        })
        .finally(() => setUserSaving(false));
    }
    else {
      setUserSaveSuccess(true);
      setUserSaving(false);
    }
  };


  useEffect(() => {
    let isOk: boolean = false;

    // Required data
    if (newAssignment?.integrationPartnerId?.trim() && newAssignment?.plannerId?.trim()) { isOk = true }

    // No duplicates
    if (userEdited.assignments?.some(a =>
      a.integrationPartnerId === newAssignment?.integrationPartnerId &&
      a.plannerId === newAssignment?.plannerId)) { isOk = false }

    setIsAddingOK(isOk);
  }, [newAssignment, userEdited])


  // Redirect to 'Users' after successful save
  useEffect(() => { if (userSaveSuccess) history.push(AdminRoutes.Users) }, [history, userSaveSuccess]);

  return (
    <>
      <Loader isLoading={userSaving || userLoading} />

      <PageContainerDivStyled >

        <div className="edit-main">
          <div className="edit-header">Planner</div>

          <div className="edit-contents" style={{ width: "600px" }}>

            {!userError &&
              <>
                <table>
                  <tbody>
                    <tr>
                      <td>User Principal Name</td>
                      <td>{userEdited.userPrincipalName}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{userEdited.firstName} {userEdited.lastName}</td>
                    </tr>
                    <tr>
                      <td>Email Address</td>
                      <td>{userEdited.emailAddress}</td>
                    </tr>
                  </tbody>
                </table>

                <div className="edit-header-section">
                  Assignments
                  <div>

                    <Button title="New Assignment" color="primary" tabIndex={-1} onClick={() => {
                      setNewAssignment({
                        integrationPartnerId: null,
                        plannerId: userEdited.emailAddress,
                        enabled: true
                      });
                    }}><Icon className="fas fa-plus" />New Assignment</Button>

                  </div>
                </div>

                <>
                  <table className="edit-table">
                    <thead>
                      <tr>
                        <th style={{ width: "90px" }}>System</th>
                        <th style={{ width: "380px" }}>User ID</th>
                        <th style={{ width: "80px" }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {userEdited.assignments && userEdited.assignments.map(a =>
                        <tr key={`0&${a.integrationPartnerId}&${a.plannerId}`}>
                          <td key={`1&${a.integrationPartnerId}&${a.plannerId}`}>{a.integrationPartnerId}</td>
                          <td key={`2&${a.integrationPartnerId}&${a.plannerId}`}>{a.plannerId}</td>
                          <td key={`3&${a.integrationPartnerId}&${a.plannerId}`}>
                            <button key={`4&${a.integrationPartnerId}&${a.plannerId}`} onClick={() => handleDelAssignment(a)}>Delete</button>
                          </td>
                        </tr>
                      )}
                      {!userEdited?.assignments?.length && !userLoading && !newAssignment && <tr><td colSpan={3} className="td-warning" style={{ textAlign: "center" }}>No assigments to planning systems</td></tr>}
                      {newAssignment &&
                        <tr>
                          <td>
                            <select style={{ width: "80px" }} defaultValue="" onChange={e => setNewAssignment({ ...newAssignment, integrationPartnerId: e.target.value })}>
                              <option value="">---</option>
                              {partners?.map(p =>
                                <option key={p.partnerId} value={p.partnerId}>
                                  {p.partnerId + ' - ' + p.partnerName}
                                </option>
                              )}
                            </select>
                          </td>

                          <td>
                            <input value={newAssignment.plannerId as string} onChange={e => setNewAssignment({ ...newAssignment, plannerId: e.target.value })} />
                          </td>

                          <td>
                            <button onClick={() => handleAddAssignment()} disabled={!isAddingOK}>Add</button>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </>

                <form id="main-form" onSubmit={handleSubmit}>

                  <div className="edit-form-buttons-bar">

                    <Button id="cancel-button" style={{ width: "100px" }} color="secondary" onClick={() => handleClose(true)}>
                      <Icon className="fas fa-undo" />
                      Cancel
                    </Button>

                    <Button id="save-button" type="submit" color="primary" style={{ width: "100px" }} disabled={!anyChange}>
                      <Icon className="fas fa-save" />
                      Save
                    </Button>

                  </div>
                </form>
              </>
            }

            {userError && <ServerCommunicationError errType={userError} />}

          </div>
        </div>

        <Dialog id="dialog-error-save" className="visent" open={errorDialogOpen} >
          <DialogTitle>Data Save Error</DialogTitle>
          <DialogContent>
            {userSaveError?.status && <DialogContentText>Response status: {userSaveError?.status}</DialogContentText>}
            <DialogContentText>Could not save the data.</DialogContentText>
            {userSaveError?.data?.errors && <DialogContentText>{userSaveError?.data?.errors}</DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setErrorDialogOpen(false)} color="primary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="dialog-no-chnages" className="visent" open={noChangesDialogOpen} >
          <DialogTitle>No Changes</DialogTitle>
          <DialogContent>
            <DialogContentText>No changes were discovered. No need to save data.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNoChangesDialogOpen(false)} color="secondary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="dialog-confirm-close" className="visent" open={confirmCloseDialogOpen} >
          <DialogTitle>Not Saved Changes</DialogTitle>
          <DialogContent>
            <DialogContentText>There are changes that have not been saved.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleSubmit(null)} color="primary" autoFocus>Save & Close</Button>
            <Button onClick={() => setConfirmCloseDialogOpen(false)} color="secondary" autoFocus>Back</Button>
            <Button onClick={() => handleClose(false)} color="secondary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

      </PageContainerDivStyled>
    </>
  );
}
