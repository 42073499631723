import { Fade, Menu, MenuItem } from "@material-ui/core";
import { WebOrder } from "../api/models";
import { RowType } from "../services/gridDataEdit";

export enum MenuItemType {
  SentHistory = "SentHistory",
  SentError = "SentError",
  InformationAboutControl = "InformationAboutControl",
  OfficialInfo = "OfficialInfo",
  ClearSentData = "ClearSentData",
  EmergencyDownload = "EmergencyDownload"
}

interface RowMenuProps {
  anchorEl: null | Element;
  items: MenuItemType[];
  isOpen: boolean;
  onCloseAction: (item: MenuItemType | undefined) => void;
}

export function getRowMenuItems(row: WebOrder | undefined, rowType: RowType) {
  const items: MenuItemType[] = [];
  if (!row) {
    return [];
  }

  items.push(MenuItemType.SentHistory);
  if (showTaskStatusErrorDialog(row)) {
    items.push(MenuItemType.SentError);
  }

  if (row.hasInfoAboutControl) {
    items.push(MenuItemType.InformationAboutControl);
  }

  if (row.hasOfficialInfo) {
    items.push(MenuItemType.OfficialInfo);
  }

  if (rowType === RowType.Fuel && row.isSentDataEditable && (row.sentNumber || row.senderKey || row.carrierKey || row.recipientKey)) {
    items.push(MenuItemType.ClearSentData);
  }

  if (row.emergencyEligible) {
    items.push(MenuItemType.EmergencyDownload);
  }

  return items;
}

export function showTaskStatusErrorDialog(row: WebOrder) {
  return row.hasTaskErrors === true;
}

export default function RowMenu({ anchorEl, items, isOpen, onCloseAction }: RowMenuProps) {
  function getLabel(item: MenuItemType) {
    switch (item) {
      case MenuItemType.SentHistory:
        return "Show SENT registration history";
      case MenuItemType.SentError:
        return "Show SENT errors";
      case MenuItemType.InformationAboutControl:
        return "Show information about control";
      case MenuItemType.OfficialInfo:
        return "Show official info";
      case MenuItemType.ClearSentData:
        return "Clear SENT data";
      case MenuItemType.EmergencyDownload:
        return "Emergency Document Download";
      default:
        throw new Error(`Invalid value: ${item} for MenuItemType`);
    }
  }

  return (
    <Menu anchorEl={anchorEl} keepMounted open={isOpen} onClose={() => onCloseAction(undefined)} TransitionComponent={Fade}>
      {items.map((x, index) => (
        <MenuItem key={index} onClick={() => onCloseAction(x)}>
          {getLabel(x)}
        </MenuItem>
      ))}
    </Menu>
  );
}
