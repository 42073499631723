import { useMsal } from "@azure/msal-react";

export default function UserInfoPage() {
    const { accounts } = useMsal();

    const divStyle = {
        border: 'solid 2px #333',
        backgroundColor: '#ccc'
    }

    return (
        <div>
            <p>Identity token:</p>
            <div style={divStyle}>
                <code dangerouslySetInnerHTML={{__html: JSON.stringify(accounts, null, 2).replaceAll("\n", "<br/>").replaceAll(' ', '&nbsp;')}}/>
            </div>
            <p>Environment variables:</p>
            <p>
                <strong>REACT_APP_BACKEND_API_URL: </strong>{process.env.REACT_APP_BACKEND_API_URL}<br />
                <strong>REACT_APP_AZURE_AD_CLIENT_ID: </strong>{process.env.REACT_APP_AZURE_AD_CLIENT_ID}<br />
                <strong>REACT_APP_AZURE_AD_API_SCOPE: </strong>{process.env.REACT_APP_AZURE_AD_API_SCOPE}<br />
            </p>
        </div>
    );

}