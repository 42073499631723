import { useState, useEffect, useCallback } from "react";
import { AuthenticationResult, SilentRequest } from "@azure/msal-browser";
import { msalInstance } from "../App";


export const useGraph = () => {
    const baseUrl = "https://graph.microsoft.com/v1.0/";
    const [graphToken, setGraphToken] = useState<AuthenticationResult>();

    const getToken = useCallback(() => {
        let now = new Date();
        now.setSeconds(now.getSeconds() + 30);

        if (!graphToken || graphToken.expiresOn! < now) {
            //console.log("Getting MS Graph access token...");
            let allAccounts = msalInstance.getAllAccounts();
            let account = allAccounts[0];
            let req: SilentRequest = { scopes: [".default"], account: account };

            msalInstance.acquireTokenSilent(req)
                .then(tokenResponse => {
                    //console.log("User:", account);
                    //console.log("Token:", tokenResponse);
                    setGraphToken(tokenResponse);
                })
                .catch(async (error) => {
                    return msalInstance.acquireTokenPopup(req);
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }, [graphToken]);

    useEffect(() => getToken(), [getToken]);


    function getAdUser(userPrincipalName: string): Promise<any> {
        // console.log(`Getting '${userPrincipalName}'...`);
        getToken();

        let url = `${baseUrl}users?$filter=userPrincipalName eq '${userPrincipalName}'&$select=userPrincipalName,givenName,surname,displayName,mail`
        let req = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${graphToken?.accessToken}`
            }
        }
        console.log("URL:", url);
        console.log("Request:", req);

        return fetch(url, req)
    }


    function findAdUsers(searchTerm: string): Promise<any> {
        // console.log(`Searching for ${searchTerm}...`);
        getToken();

        let url = `${baseUrl}users?$search="displayName:${searchTerm}" OR "userPrincipalName:${searchTerm}"&$select=userPrincipalName,givenName,surname,displayName,mail`
        let req = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${graphToken?.accessToken}`,
                ConsistencyLevel: "eventual"
            }
        }
        // console.log("URL:", url);
        // console.log("Request:", req);

        return fetch(url, req);
    }



    return { getAdUser, findAdUsers }
}

