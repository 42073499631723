import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../api/api";
import ServerCommunicationError, { CommunicationErrorType } from "../../common/ServerCommunicationIssue";
import { DataGrid, GridColDef, GridRowData, GridValueGetterParams, GridCellParams } from "@material-ui/data-grid";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { Button, Icon, IconButton } from "@material-ui/core";
import { PageContainerDivStyled, PageGridDivStyled } from "../pageStyledComponents";
import { useGridStyles } from "../../common/gridCustomTheme";
import Loader from "../../common/Loader";
import "./admin.css";
import { AdminRoutes } from "../../routes/routeUtils";
import { DepotWeb } from "../../api/models";


export default function DepotsPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [communicationError, setCommunicationError] = useState<CommunicationErrorType>();
  const [apiData, setApiData] = useState<DepotWeb[]>();
  const [pageSize, setPageSize] = useState<number>(20);

  const [deletion, setDeletion] = useState<any>({ showDialog: false, depotId: undefined });
  const [deleting, setDeleting] = useState<boolean>(false);
  const [delError, setDelError] = useState<any>({ showDialog: false, error: undefined });

  const getDepots = useCallback(() => {
    setLoading(true);
    setCommunicationError(undefined);
    setApiData(undefined);

    let request: Promise<any>;
    request = API.get("/Depots")

    request
      .then(response => {
        const respContent: DepotWeb[] = response.data;
        setApiData(respContent);
      })
      .catch(err => setCommunicationError(err))
      .finally(() => setLoading(false))
  }, []);

  useEffect(() => getDepots(), [getDepots]);

  // DELETE Depot
  const handleDepotDelete = () => {
    setDeletion({ showDialog: false, depotId: undefined });
    setDeleting(true);
    setDelError({ showDialog: false, error: undefined });

    console.log(`Deleting depot: ${deletion?.depotId}`);

    API.delete(`/Depots/${deletion?.depotId}`)
      .then(resp => {
        console.log(resp);
        getDepots();
      })
      .catch(err => {
        console.log("'err.response':", err.response);
        console.log("Response status: ", err.response.status);
        console.log(err.response.data.errors);
        setDelError({ showDialog: true, error: err.response });
      })
      .finally(() => { setDeleting(false); console.log("Deletion complete") });
  };




  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 54,
      editable: false
    },
    {
      field: "supplierId",
      headerName: "Supplier",
      width: 70,
      editable: false
    },
    {
      field: "name",
      headerName: "Name",
      width: 255,
      editable: false
    },
    {
      field: "address",
      headerName: "Address",
      width: 175,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        let addr: string = params.row.street;
        if (params.row.houseNumber) {
          addr += params.row.houseNumber.trim().toUpperCase() === "BRAK" || "" ? "" : " " + params.row.houseNumber;
        }
        return addr;
      }
    },
    {
      field: "city_zip",
      headerName: "City",
      width: 175,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => `${params.row.postalCode} ${params.row.city}`
    },
    {
      field: "country",
      headerName: "Country",
      width: 68,
      align: "center",
      headerAlign: "center",
      editable: false
    },
    {
      field: "district",
      headerName: "District",
      width: 160,
      editable: false
    },
    {
      field: "action",
      headerName: "Action",
      width: 90,
      disableColumnMenu: true,
      hideSortIcons: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <IconButton title="Delete" onClick={() => setDeletion({ showDialog: true, depotId: params.id })}>
              <Icon className="fas fa-trash-alt" />
            </IconButton>

            <Link to={AdminRoutes.DepotEdit.replace(":id", params.id as string)} title="Edit" tabIndex={-1}>
              <IconButton>
                <Icon className="fas fa-edit"></Icon>
              </IconButton>
            </Link>
          </>
        );
      }
    }
  ];

  const rows: GridRowData[] = apiData?.map(d => ({
    id: d.id,
    supplierId: d.supplierId,
    name: d.name,
    street: d.street,
    houseNumber: d.houseNumber,
    city: d.city,
    postalCode: d.postalCode,
    country: d.country,
    district: d.district
  })) ?? [];


  const classes = useGridStyles();
  return (
    <>
      <Loader isLoading={loading || deleting} />
      <PageContainerDivStyled style={{ width: "1070px", margin: "auto" }}>
        <div className="actions">
          <h3 className="actions-left">Depots</h3>
          <div className="actions-right">
            {!communicationError &&
              <Link to={AdminRoutes.DepotAdd} className="link-button">
                <Button title="Add Depot" color="primary" tabIndex={-1}><Icon className="fas fa-plus" />New Depot</Button>
              </Link>
            }
          </div>
        </div>
        {communicationError && <ServerCommunicationError errType={communicationError} />}
        {apiData &&
          <PageGridDivStyled className="grid-container">
            <DataGrid
              className={classes.root}
              pagination
              columns={columns}
              rows={rows}
              density="compact"
              showColumnRightBorder={true}
              showCellRightBorder={false}
              pageSize={pageSize}
              onPageSizeChange={(ps) => { setPageSize(ps.pageSize) }}
              rowsPerPageOptions={[20, 50, 100]}
              disableSelectionOnClick={true}
            />
          </PageGridDivStyled>
        }

        <Dialog id="ask-for-deletion" className="visent" open={deletion?.showDialog}>
          <DialogTitle>Depot Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete depot '{deletion?.depotId}'?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setDeletion({ showDialog: false, depotId: undefined })}>Cancel</Button>
            <Button color="primary" onClick={handleDepotDelete}>OK</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="deletion-error" className="visent" open={delError?.showDialog}>
          <DialogTitle>Depot Deletion Error</DialogTitle>
          <DialogContent>
            {delError?.error?.status && <DialogContentText>Response status: {delError?.error?.status}</DialogContentText>}
            <DialogContentText>Could not delete depot</DialogContentText>
            {delError?.error?.data?.errors && <DialogContentText>{delError?.error?.data?.errors}</DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDelError({ ...delError, showDialog: false })}>Close</Button>
          </DialogActions>
        </Dialog>

      </PageContainerDivStyled>
    </>
  );
}


