import React, { useCallback, useEffect, useState } from "react";
import API from "../../api/api";
import ServerCommunicationError, { CommunicationErrorType } from "../../common/ServerCommunicationIssue";
import { AdminChangeObject, AdminAction, AdminLogEntry } from "../../api/models";
import { DataGrid, GridColDef, GridRowData, GridValueGetterParams, GridCellParams } from "@material-ui/data-grid";
import { Button, Icon, IconButton } from "@material-ui/core";
import { PageContainerDivStyled, PageGridDivStyled } from "../pageStyledComponents";
import { useGridStyles } from "../../common/gridCustomTheme";
import Loader from "../../common/Loader";
import { isoToLocalTime } from "../../services/miscellaneous";
import AdminHistoryDetails from "./AdminHistoryDetails";
import "./admin.css";



interface DetailsDialogInfo {
  show: boolean,
  logEntry: AdminLogEntry | undefined
}

interface AdminLogFilter {
  dateFrom: string | undefined,
  dateTo: string | undefined,
  object: AdminChangeObject[] | undefined,
  action: AdminAction[] | undefined,
  user: string | undefined
}

// const emptyFilter: AdminLogFilter = {
//   dateFrom: '',
//   dateTo: '',
//   object: [] as AdminChangeObject[],
//   action: [] as AdminAction[],
//   user: ''
// }


export default function AdminHistoryPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [communicationError, setCommunicationError] = useState<CommunicationErrorType>();
  const [apiData, setApiData] = useState<AdminLogEntry[]>();
  const [pageSize, setPageSize] = useState<number>(20);
  const [detailsDialog, setDetailsDialog] = useState<DetailsDialogInfo>({ show: false, logEntry: undefined })
  // const [logFilter, setLogFilter] = useState<AdminLogFilter>(emptyFilter);
  const [logFilter, setLogFilter] = useState<AdminLogFilter>({ dateFrom: '', dateTo: '', object: [], action: [], user: '' });
  const [filterQuery, setFilterQuery] = useState<string>();

  const getLogEntries = useCallback(() => {
    setLoading(true);
    setCommunicationError(undefined);
    setApiData(undefined);

    let request: Promise<any>;
    request = API.get(`/AdminLog${filterQuery || ''}`)

    return request
      .then(response => {
        const respContent: AdminLogEntry[] = response.data;
        //console.log(respContent);
        //setLoading(false);
        setApiData(respContent);
      })
      .catch(err => setCommunicationError(err))
      .finally(() => setLoading(false))
  }, [filterQuery]);


  useEffect(() => { getLogEntries(); }, [getLogEntries]);

  const clearFilter = () => {
    let clearFilter: AdminLogFilter = {
      dateFrom: '',
      dateTo: '',
      object: [] as AdminChangeObject[],
      action: [] as AdminAction[],
      user: ''
    }
    setLogFilter(clearFilter);
    setFilterQuery(undefined);
  };

  const applyFilter = () => {
    let query: string = "";
    if (logFilter) {
      let filters: string[] = [];
      if (logFilter.dateFrom) { filters.push(`from=${new Date(logFilter.dateFrom + 'T00:00').toISOString()}`) }
      if (logFilter.dateTo) { filters.push(`to=${new Date(logFilter.dateTo + 'T00:00').toISOString()}`) }
      if (logFilter.object) { logFilter.object.map(o => filters.push(`obj=${o}`)) }
      if (logFilter.action) { logFilter.action.map(o => filters.push(`action=${o}`)) }
      if (logFilter.user) { filters.push(`user=${logFilter.user}`) }

      if (filters.length > 0) { query = '?' + filters.join('&') }
    }
    setFilterQuery(query);
  };

  //useEffect(() => { console.log("Log Filter:", logFilter) }, [logFilter]);


  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: `Time (${new Date().toLocaleTimeString("pl-pl", { timeZoneName: "short" }).split(" ").slice(-1)})`,
      width: 110,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => `${isoToLocalTime(params.value as string)}`
    },
    {
      field: "objectType",
      headerName: "Object",
      width: 110,
      editable: false
    },
    {
      field: "action",
      headerName: "Action",
      width: 80,
      editable: false

    },
    {
      field: "userText",
      headerName: "User",
      width: 200,
      editable: false
    },
    {
      field: "info",
      headerName: "Info",
      width: 75,
      disableColumnMenu: true,
      hideSortIcons: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridCellParams) => {
        return (
          <IconButton title="Details" onClick={() => setDetailsDialog({ show: true, logEntry: params.row as AdminLogEntry })} >
            <Icon className="fas fa-info-circle" />
          </IconButton>
        );
      }
    }
  ];

  const rows: GridRowData[] = apiData?.map(log => ({
    id: log.id,
    timestamp: new Date(log.id as string),
    objectType: log.objectType,
    action: log.action,
    userId: log.userId,
    userName: log.userName,
    userText: log.userName || log.userId,
    before: log.before,
    after: log.after
  })) ?? [];


  const gridWidth = "620px";
  const headerHeight = "40px"

  const classes = useGridStyles();
  return (
    <>
      <Loader isLoading={loading} />
      {/* <PageContainerDivStyled id="PageContainer" style={{ width: "950px", margin: "auto" }} className="fng"> */}
      <PageContainerDivStyled id="PageContainer" style={{ margin: "auto" }} className="fng">

        {/* Side Filter */}
        <div className="fng-side-filter">
          {/* <div style={{ height: headerHeight }} ><h3>Filter</h3></div> */}
          <div className="actions" style={{ height: headerHeight }}>
            <h3 className="actions-left">Filter</h3>
          </div>



          {/* <div>Filter content that can appear</div> */}
          <Button title="Apply Filter" color="primary" className="fng-button" onClick={() => applyFilter()} >
            <Icon className="fas fa-filter" />
            Apply Filter
          </Button>
          <Button title="Clear Filter" color="secondary" className="fng-button" onClick={() => clearFilter()} >
            <Icon className="fas fa-times" style={{ color: "red" }} />
            Clear Filter
          </Button>

          <div className="fng-filter-item">
            <label htmlFor="date-from">Date from:</label>
            <div className="fng-filter-item-controls">
              <input id="date-from" type="date" className="visent" value={logFilter.dateFrom} onChange={(e) => { setLogFilter({ ...logFilter, dateFrom: e.target.value }) }}></input>
              <Icon className="fas fa-times" style={{ color: "red" }} onClick={() => setLogFilter({ ...logFilter, dateFrom: '' })} />
            </div>
          </div>

          <div className="fng-filter-item">
            <label htmlFor="date-to">Date to:</label>
            <div className="fng-filter-item-controls">
              <input id="date-to" type="date" className="visent" value={logFilter.dateTo} onChange={(e) => { setLogFilter({ ...logFilter, dateTo: e.target.value }) }}></input>
              <Icon className="fas fa-times" style={{ color: "red" }} onClick={() => setLogFilter({ ...logFilter, dateTo: '' })} />
            </div>
          </div>

          <div className="fng-filter-item">
            <label>Object type:</label>
            <div>
              {Object.values(AdminChangeObject).map(o => {
                return (
                  <div key={`obj-${o}-container`}>
                    <input id={o} key={`obj-${o}-chk`} type="checkbox" checked={logFilter.object?.includes(o)}
                      onChange={(e) => {
                        console.log(`Checkbox clicked: '${o}', checked: ${e.target.checked}`);
                        let newList: AdminChangeObject[];
                        if (e.target.checked) {
                          newList = logFilter.object!;
                          if (!newList.includes(o)) newList.push(o);
                        }
                        else {
                          newList = logFilter.object!.filter(item => item !== o);
                        }
                        console.log("New List of Objects:", newList);
                        setLogFilter({ ...logFilter, object: newList });
                      }} />
                    <label htmlFor={o} key={`obj-${o}-lbl`}>{o}</label>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="fng-filter-item">
            <label>Action:</label>
            <div>
              {Object.values(AdminAction).map(a => {
                return (
                  <div key={`act-${a}-container`}>
                    <input id={a} key={`act-${a}-chk`} type="checkbox" checked={logFilter.action?.includes(a)}
                      onChange={(e) => {
                        console.log(`Checkbox clicked: '${a}', checked: ${e.target.checked}`);
                        let newList: AdminAction[];
                        if (e.target.checked) {
                          newList = logFilter.action!;
                          if (!newList.includes(a)) newList.push(a);
                        }
                        else {
                          newList = logFilter.action!.filter(item => item !== a);
                        }
                        console.log("New List of Actions:", newList);
                        setLogFilter({ ...logFilter, action: newList });
                      }} />
                    <label htmlFor={a} key={`act-${a}-lbl`}>{a}</label>
                  </div>
                )
              })}
            </div>
          </div>


          <div className="fng-filter-item">
            <label htmlFor="user">User:</label>
            <div className="fng-filter-item-controls">
              <input id="user" type="text" className="visent" value={logFilter.user} onChange={(e) => { setLogFilter({ ...logFilter, user: e.target.value }) }}></input>
              <Icon className="fas fa-times" style={{ color: "red" }} onClick={() => setLogFilter({ ...logFilter, user: '' })} />
            </div>
          </div>

        </div>

        {/* Grid */}
        <div className="fng-grid-group" style={{ width: gridWidth }}>
          <div className="actions" style={{ height: headerHeight }}>
            <h3 className="actions-left">Admin Actions History</h3>
          </div>
          {communicationError && <ServerCommunicationError errType={communicationError} />}
          {apiData &&
            <PageGridDivStyled style={{ width: gridWidth }}>
              <DataGrid
                className={classes.root}
                pagination
                columns={columns}
                rows={rows}
                density="compact"
                showColumnRightBorder={true}
                showCellRightBorder={false}
                pageSize={pageSize}
                onPageSizeChange={(ps) => { setPageSize(ps.pageSize) }}
                rowsPerPageOptions={[20, 50, 100]}
                disableSelectionOnClick={true}
              />
            </PageGridDivStyled>
          }
        </div>

        <AdminHistoryDetails show={detailsDialog.show} logEntry={detailsDialog.logEntry!} onClose={() => setDetailsDialog({ show: false, logEntry: undefined })} ></AdminHistoryDetails>

      </PageContainerDivStyled>
    </>
  );
}


