import styled from "styled-components";

const ContainerDivStyled = styled.div`
  margin: 2px 0px 0px 0px;
  padding: 2px;
  display: flex;
`;

interface LabelDivStyledProps {
  width?: number;
}
const gray = "#f5f5f5";
const LabelDivStyled = styled.div`
  width: ${({ width }: LabelDivStyledProps) => (width ? `${width}px` : "65px")};
  padding: 3px 3px 3px 6px;
  border-radius: 10px 0px 0px 10px;
  border: 1px solid ${gray};
  border-right-width: 0px;
  background-color: ${gray};
  font-size: 13px;
`;

interface SectionValueContainerProps {
  label: string;
  labelWidth?: number;
  children: JSX.Element;
}

function SectionValueContainer({ label, labelWidth, children }: SectionValueContainerProps) {
  return (
    <ContainerDivStyled>
      <LabelDivStyled width={labelWidth}>{label}:</LabelDivStyled>
      {children}
    </ContainerDivStyled>
  );
}

interface ValueDivStyledProps {
  isMainValue?: boolean;
  width?: number;
  isCentered?: boolean;
}

const ValueDivStyled = styled.div`
  width: ${({ width }: ValueDivStyledProps) => (width ? `${width}px` : undefined)};
  border: 1px solid ${gray};
  border-radius: 0px 10px 10px 0px;
  padding: 3px 3px 3px 6px;
  font-size: ${({ isMainValue }: ValueDivStyledProps) => (isMainValue ? "13px" : "12px")};
  font-weight: ${({ isMainValue }: ValueDivStyledProps) => (isMainValue ? "600" : "400")};
  text-align: ${({ isCentered }: ValueDivStyledProps) => (isCentered ? "center" : undefined)};
  flex-grow: 1;
`;

interface SectionValueProps {
  label: string;
  labelWidth?: number;
  value: string | null | undefined;
  valueWidth?: number;
  isMainValue?: boolean;
  isCentered?: boolean;
}

export function SectionValue({ label, labelWidth, value, valueWidth, isMainValue, isCentered }: SectionValueProps) {
  return (
    <SectionValueContainer label={label} labelWidth={labelWidth}>
      <ValueDivStyled isMainValue={isMainValue} width={valueWidth} isCentered={isCentered}>
        {value ? value : "n/a"}
      </ValueDivStyled>
    </SectionValueContainer>
  );
}

interface SectionAddressValueProps {
  label: string;
  labelWidth?: number;
  addressLine1: string | null | undefined;
  addressLine2: string | null | undefined;
}

const AddressLine2DivStyled = styled.div`
  margin-top: 4px;
`;

export function SectionAddressValue({ label, labelWidth, addressLine1, addressLine2 }: SectionAddressValueProps) {
  return (
    <SectionValueContainer label={label} labelWidth={labelWidth}>
      <ValueDivStyled>
        <div>{addressLine1}</div>
        <AddressLine2DivStyled>{addressLine2}</AddressLine2DivStyled>
      </ValueDivStyled>
    </SectionValueContainer>
  );
}
