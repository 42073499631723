import { CommunicationErrorType } from "../common/ServerCommunicationIssue";
import { HttpCallError } from "../models/common";

export interface ItemKey {
  tripId: string;
  tripSourceId: string;
  orderId: string;
}

export interface DialogPayload extends ItemKey {
  vehicleId?: string | null;
}

export function compareKeys(left: ItemKey, right: ItemKey) {
  if (!left || !right) {
    return false;
  }

  return left?.tripId === right?.tripId && left?.tripSourceId === right?.tripSourceId && left?.orderId === right?.orderId;
}

export function getKeyString(key: ItemKey) {
  return `${key.tripId}_${key.tripSourceId}_${key.orderId}`;
}

export function getCommunicationErrorType(err: any) {
  if (err.response) {
    return CommunicationErrorType.ServerErrorResponse;
  }

  return CommunicationErrorType.ConnectionProblem;
}

export function getHttpCallError(err: any) {
  let errorInfo: HttpCallError;
  if (err.response) {
    errorInfo = {
      statusCode: err.response.status,
      errorDetails: err.response.data,
    };
  } else {
    errorInfo = {
      statusCode: err.response.status,
      errorDetails: err.message,
    };
  }

  return errorInfo;
}
