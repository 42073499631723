import React from "react";
import { OrderInfo } from "../../../api/models";
import { getOrderStatusText } from "../../../services/statusesRendering";
import { booleanToString } from "../../formatHelper";
import { MasterDataMissing } from "./MasterDataMissing";
import { SectionHeaderDivStyled, SectionPaperStyled, SectionRowContainer } from "./sectionsStyledComponents";
import { SectionValue } from "./SectionValue";

export function OrderInfoSection({ orderId, orderInfo, isLpg }: { orderId: string; orderInfo: OrderInfo | undefined; isLpg: boolean }) {
  const labelWidthCol = 100;
  const valueWidthCol = 75;
  return (
    <SectionPaperStyled elevation={4}>
      <SectionHeaderDivStyled>Order Information</SectionHeaderDivStyled>
      {!orderInfo && <MasterDataMissing />}
      {orderInfo && (
        <>
          <SectionRowContainer>
            <SectionValue label="Order ID" value={orderId} labelWidth={labelWidthCol} valueWidth={valueWidthCol} />
            <SectionValue
              label="Status"
              value={getOrderStatusText(orderInfo.status)}
              labelWidth={labelWidthCol}
              valueWidth={valueWidthCol}
            />
          </SectionRowContainer>
          <SectionRowContainer>
            <SectionValue
              label="Is VMI order"
              value={booleanToString(orderInfo.vmi)}
              labelWidth={labelWidthCol}
              valueWidth={valueWidthCol}
            />
            <SectionValue label="Product type" value={orderInfo.productType} labelWidth={labelWidthCol} valueWidth={valueWidthCol} />
          </SectionRowContainer>
          <SectionRowContainer>
            <SectionValue
              label="Planned amount"
              value={orderInfo.plannedAmount?.toString()}
              labelWidth={labelWidthCol}
              valueWidth={valueWidthCol}
            />
            <SectionValue
              label="Actual amount"
              value={orderInfo.actualAmount?.toString()}
              labelWidth={labelWidthCol}
              valueWidth={valueWidthCol}
            />
          </SectionRowContainer>
          <SectionRowContainer>
            <SectionValue
              label="Is compliant"
              value={booleanToString(orderInfo.isCompliant)}
              labelWidth={labelWidthCol}
              valueWidth={valueWidthCol}
            />
            <SectionValue
              label="Type of non compliance"
              value={orderInfo.typeOfNonCompliance}
              labelWidth={labelWidthCol}
              valueWidth={valueWidthCol}
            />
          </SectionRowContainer>
          {isLpg && (
            <SectionRowContainer>
              <SectionValue
                label="Gas pump meter"
                value={orderInfo.gasPumpMeter?.toString()}
                labelWidth={labelWidthCol}
                valueWidth={valueWidthCol}
              />
              <SectionValue label="Gas pump number" value={orderInfo.gasPumpNumber} labelWidth={labelWidthCol} valueWidth={valueWidthCol} />
            </SectionRowContainer>
          )}
        </>
      )}
    </SectionPaperStyled>
  );
}
