import API from "./api";
import { ClientConfig } from "./models";

export interface ClientConfiguration {
  clientErrorMessage: string;
  serverErrorMessage: string;
}

const clientErrorMessageDefault = "Failed to connect to server - check Your network connection";
const serverErrorMessageDefault = "Server responded with error. Please contact administrator";

export function getConfiguration(): ClientConfiguration {
  const configuration = getConfigFromLocalStorage();
  if (configuration) {
    return configuration;
  }

  return {
    clientErrorMessage: clientErrorMessageDefault,
    serverErrorMessage: serverErrorMessageDefault,
  };
}

export function getClientConfigFromServer() {
  API.get("/clientConfig").then((x) => {
    var clientConfig = x.data as ClientConfig;
    saveConfigInLocalStorage({
      clientErrorMessage: clientConfig.clientErrorMessage || clientErrorMessageDefault,
      serverErrorMessage: clientConfig.serverErrorMessage || serverErrorMessageDefault,
    });
  });
}

const clientConfigKey = "VISENT_client_config";

function saveConfigInLocalStorage(clientConfig: ClientConfiguration) {
  localStorage.setItem(clientConfigKey, JSON.stringify(clientConfig));
}

function getConfigFromLocalStorage(): ClientConfiguration | undefined {
  const value = localStorage.getItem(clientConfigKey);

  if (value) {
    return JSON.parse(value);
  }
}
