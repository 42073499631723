import API from "../../api/api";
import React from "react";
import { DialogPayload, getCommunicationErrorType } from "../../api/common";
import GridDialogContainer from "../dialogs/GridDialogContainer";
import { DepotInfoSection } from "./sections/DepotInfoSection";
import { CarrierInfoSection } from "./sections/CarrierInfoSection";
import { ShipToSection } from "./sections/ShipToSection";
import { VehicleSection } from "./sections/VehicleSection";
import { CustomerSection } from "./sections/CustomerSection";
import { OrderDetails, OrderInfo } from "../../api/models";
import ServerCommunicationError, { CommunicationErrorType } from "../ServerCommunicationIssue";
import styled from "styled-components";
import { SentInformationSection } from "./sections/SentInformationSection";
import { TripInfoSection } from "./sections/TripInfoSection";
import { OrderInfoSection } from "./sections/OrderInfoSection";

const DialogContainerRowStyled = styled.div`
  display: flex;
`;

interface OrderDetailsDialogProps {
  dialogPayload: DialogPayload;
  isOpen: boolean;
  onCloseAction: () => void;
}

export default function OrderDetailsDialog({ dialogPayload, isOpen, onCloseAction }: OrderDetailsDialogProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [communicationError, setCommunicationError] = React.useState<CommunicationErrorType>();
  const [details, setDetails] = React.useState<OrderDetails>();

  React.useEffect(() => {
    setDetails(undefined);
    setCommunicationError(undefined);
    setLoading(true);

    API.get(`/orderDetails/${dialogPayload.tripSourceId}/${dialogPayload.tripId}/${dialogPayload.orderId}`)
      .then((response) => setDetails(response.data as OrderDetails))
      .catch((err) => setCommunicationError(getCommunicationErrorType(err)))
      .finally(() => setLoading(false));
  }, [dialogPayload]);

  function isLpg(orderInfo: OrderInfo | undefined) {
    return orderInfo?.productType?.toUpperCase() === "LPG";
  }

  return (
    <GridDialogContainer
      title={`Details for Order: ${dialogPayload.orderId}`}
      maxWidth="lg"
      height={755}
      dialogPayload={dialogPayload}
      hideItemKey={true}
      isOpen={isOpen}
      loading={loading}
      onCloseAction={onCloseAction}>
      <>
        {communicationError && <ServerCommunicationError errType={communicationError} />}
        {details && (
          <div>
            <DialogContainerRowStyled>
              <SentInformationSection sentInfo={details.sent} isLpg={isLpg(details.order)} />
            </DialogContainerRowStyled>

            <DialogContainerRowStyled>
              <DepotInfoSection depot={details.depot}></DepotInfoSection>
              <CarrierInfoSection carrier={details.carrier}></CarrierInfoSection>
              <ShipToSection shipTo={details.shipTo}></ShipToSection>
            </DialogContainerRowStyled>

            <DialogContainerRowStyled>
              <TripInfoSection tripId={dialogPayload.tripId} tripInfo={details.trip}></TripInfoSection>
              <VehicleSection vehicle={details.vehicle}></VehicleSection>
              <CustomerSection customer={details.customer}></CustomerSection>
            </DialogContainerRowStyled>
            <DialogContainerRowStyled>
              <OrderInfoSection orderId={dialogPayload.orderId} orderInfo={details.order} isLpg={isLpg(details.order)}></OrderInfoSection>
            </DialogContainerRowStyled>
          </div>
        )}
      </>
    </GridDialogContainer>
  );
}
