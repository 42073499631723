import { Link } from "react-router-dom";
import { AdminRoutes } from "../../routes/routeUtils";
import { PageContainerDivStyled } from "../pageStyledComponents";
export default function AdminPage() {

  return (
    <PageContainerDivStyled style={{ maxWidth: "380px", margin: "auto" }}>
      <h1>Administration</h1>
      <div className="box-menu">

        <Link to={AdminRoutes.SystemSettings}>
          <div className="box-menu-item">System Settings </div>
        </Link>

        <Link to={AdminRoutes.Suppliers}>
          <div className="box-menu-item">Suppliers</div>
        </Link>

        <Link to={AdminRoutes.Depots}>
          <div className="box-menu-item">Depots</div>
        </Link>

        <Link to={AdminRoutes.Carriers}>
          <div className="box-menu-item">Carriers</div>
        </Link>

        <Link to={AdminRoutes.Vehicles}>
          <div className="box-menu-item">Vehicles</div>
        </Link>

        <Link to={AdminRoutes.Users}>
          <div className="box-menu-item">Users</div>
        </Link>

        <Link to={AdminRoutes.AdminHistory}>
          <div className="box-menu-item">Change History</div>
        </Link>

      </div>

    </PageContainerDivStyled>
  );

}