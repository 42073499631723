import React from "react";
import { AdminAction, AdminLogEntry } from "../../api/models";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { isoToLocalTime, FormattedJson } from "../../services/miscellaneous";
import "./admin.css";


export interface AdminHistoryDetailsProps {
  show: boolean,
  logEntry: AdminLogEntry,
  onClose: any
}



export default function AdminHistoryDetails(props: AdminHistoryDetailsProps) {
  const labelStyle = { minWidth: "6em" }
  const valueStyle = { minWidth: "17em" }

  if (props.show)
    return (
      <Dialog id="change-details" className="visent dialog-s" open={true} >
        <DialogTitle>Admin Activity Details</DialogTitle>
        <DialogContent>
          <div id="overall" className="det-container">
            <div id="hdr" className="det-header">
              <div id="hdr-time" className="det-item">
                <div id="hdr-time-label" className="det-item-label" style={labelStyle}>Time ({new Date().toLocaleTimeString("pl-pl", { timeZoneName: "short" }).split(" ").slice(-1)}):</div>
                <div id="hdr-time-value" className="det-item-value" style={valueStyle}>{isoToLocalTime(props.logEntry.id as string)}</div>
              </div>
              <div id="hdr-object" className="det-item">
                <div id="hdr-object-label" className="det-item-label" style={labelStyle}>Object:</div>
                <div id="hdr-object-value" className="det-item-value" style={valueStyle}>{props.logEntry.objectType}</div>
              </div>
              <div id="hdr-change" className="det-item">
                <div id="hdr-change-label" className="det-item-label" style={labelStyle}>Action:</div>
                <div id="hdr-change-value" className="det-item-value" style={valueStyle}>{props.logEntry.action}</div>
              </div>
              <div id="hdr-user-id" className="det-item">
                <div id="hdr-user-id-label" className="det-item-label" style={labelStyle}>User ID:</div>
                <div id="hdr-user-id-value" className="det-item-value" style={valueStyle}>{props.logEntry.userId}</div>
              </div>
              <div id="hdr-user-name" className="det-item">
                <div id="hdr-user-name-label" className="det-item-label" style={labelStyle}>User Name:</div>
                <div id="hdr-user-name-value" className="det-item-value" style={valueStyle}>{props.logEntry.userName}</div>
              </div>
            </div>
            <div id="obj" className="json-container">
              {(props.logEntry.action === AdminAction.Update || props.logEntry.action === AdminAction.Delete) &&
                <div id="obj-before" className="json-box before">
                  <div className="json-box title before">Old</div>
                  <FormattedJson obj={props.logEntry.before}></FormattedJson>
                </div>
              }
              {(props.logEntry.action === AdminAction.Update || props.logEntry.action === AdminAction.Create) &&
                <div id="obj-after" className="json-box after">
                  <div className="json-box title after">New</div>
                  <FormattedJson obj={props.logEntry.after}></FormattedJson>
                </div>
              }

            </div>
          </div>

        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={props.onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  else
    return (<></>)
}


