import { createStyles, makeStyles, Theme, Tooltip, withStyles } from "@material-ui/core";
import styled from "styled-components";

const TooltipContentDivStyled = styled.div`
  display: flex;
  flexgrow: 1;
  justify-content: center;
`;

const HeaderTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: 13,
    padding: "10px",
  },
  tooltipPlacementTop: {
    top: 10,
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customWidth: {
      maxWidth: 600,
    },
  })
);

interface GridHeaderTooltipProps {
  children: JSX.Element;
  tooltipContent: JSX.Element | string;
}

export default function GridHeaderTooltip({ children, tooltipContent }: GridHeaderTooltipProps) {
  const classes = useStyles();
  return (
    <HeaderTooltip
      classes={{ tooltip: classes.customWidth }}
      title={tooltipContent}
      arrow
      placement="top"
      enterDelay={500}
      leaveDelay={100}>
      <TooltipContentDivStyled>{children}</TooltipContentDivStyled>
    </HeaderTooltip>
  );
}
