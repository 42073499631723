import styled from "styled-components";

export enum SpacerSize {
  Small,
  Medium,
  Large,
  ExtraLarge,
}

interface SpacerProps {
  size: SpacerSize;
}

interface HorizontalSpacerProps extends SpacerProps {
  width?: number;
}

export const SpacerHorizontalDivStyled = styled.div`
  margin-left: ${({ size }: HorizontalSpacerProps) => getSpacerSize(size)};
  width: ${({ width }: HorizontalSpacerProps) => getSpacerWidth(width)};
`;

export const SpacerVerticalDivStyled = styled.div`
  margin-top: ${({ size }: SpacerProps) => getSpacerSize(size)};
`;

function getSpacerWidth(width: number | undefined) {
  if (width) {
    return `${width}px`;
  }
}

function getSpacerSize(size: SpacerSize) {
  switch (size) {
    case SpacerSize.Small:
      return "5px";
    case SpacerSize.Medium:
      return "10px";
    case SpacerSize.Large:
      return "15px";
    case SpacerSize.ExtraLarge:
      return "20px";
  }
}
