import { Chip, createStyles, FormControl, Input, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import React from "react";
import { SpacerHorizontalDivStyled, SpacerSize } from "./Spacer";
import styled from "styled-components";

export interface OptionItem {
  id: string;
  value: string;
  legend?: React.ReactElement;
  text: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: 3,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
      height: 20,
    },
  })
);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 220,
    },
  },
};

const MenuItemStyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin: 0 5px;
  }
`;

interface FilterMultiSelectProps {
  inputLabel: string;
  placeholderWhenAllSelected?: string;
  options: OptionItem[];
  selectedValues: string[];
  valueToTextSelector: (value: string) => string;
  onSelectedValueChanged: (selectedValues: string[]) => void;
  width?: number;
}

export default function FilterMultiSelect({
  inputLabel,
  placeholderWhenAllSelected,
  options,
  selectedValues,
  valueToTextSelector,
  onSelectedValueChanged,
  width,
}: FilterMultiSelectProps) {
  const isAllSelected = selectedValues.length === options.length;
  const classes = useStyles();

  return (
    <SpacerHorizontalDivStyled {...{ size: SpacerSize.Large, width }}>
      <FormControl fullWidth variant="filled">
        <InputLabel variant="filled">{inputLabel}</InputLabel>
        <Select
          className={classes.formControl}
          variant="standard"
          multiple
          value={selectedValues}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => onSelectedValueChanged(event.target.value as string[])}
          input={<Input />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {isAllSelected && (
                <Chip
                  className={classes.chip}
                  color="secondary"
                  key={placeholderWhenAllSelected || "all"}
                  label={placeholderWhenAllSelected || "All selected"}
                />
              )}
              {!isAllSelected &&
                (selected as string[]).map((value) => (
                  <Chip className={classes.chip} key={value} label={valueToTextSelector(value)}></Chip>
                ))}
            </div>
          )}
          MenuProps={MenuProps}>
          {options.map((x) => (
            <MenuItem key={x.id} value={x.value}>
              {x.legend && (
                <MenuItemStyledDiv>
                  {x.legend}
                  <span>-</span>
                  {x.text}
                </MenuItemStyledDiv>
              )}
              {!x.legend && <MenuItemStyledDiv>{x.text}</MenuItemStyledDiv>}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SpacerHorizontalDivStyled>
  );
}
