import styled from "styled-components";

export const DialogTitleContainerDivStyled = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-size: 16px !important;
    text-transform: uppercase;
    color: #313131;
    font-weight: bold !important;
  }

  h4 {
    font-size: 13px !important;
    color: #313131;
    text-transform: uppercase;
    font-weight: bold !important;
  }
`;
