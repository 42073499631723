import React from "react";
import API from "../../api/api";
import { DialogPayload, getCommunicationErrorType } from "../../api/common";
import { DataGrid, GridCellParams, GridColDef, GridRowParams, GridRowsProp } from "@material-ui/data-grid";
import GridDialogContainer from "./GridDialogContainer";
import TaskErrorItemDialog from "./TaskErrorItemDialog";
import { logGridError } from "../../api/errorReporter";
import ServerCommunicationError, { CommunicationErrorType } from "../ServerCommunicationIssue";
import { CellHeaderTooltipComponent } from "../gridHeaders/CellHeaderTooltipComponent";
import { appColors } from "../colors";
import { IconIStyled, NotificationBoxContainerFlexDivStyled } from "../styledComponents";
import { useGridStyles } from "../gridCustomTheme";
import { TaskErrors, TaskErrorsItem } from "../../api/models";
import NotificationBox, { NotificationBoxType } from "../Notification";

interface TaskErrorsDialogProps {
  dialogPayload: DialogPayload;
  isOpen: boolean;
  onCloseAction: () => void;
}

const renderOnHoldCell = (params: GridCellParams) => {
  const rowData: TaskErrorsItem = params.row as any as TaskErrorsItem;
  if (rowData.onHold) {
    return <IconIStyled className="fas fa-exclamation-circle" {...{ size: 18, color: appColors.orange }} />;
  }

  return <></>;
};

export default function TaskErrorsDialog({ dialogPayload, isOpen, onCloseAction }: TaskErrorsDialogProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [communicationError, setCommunicationError] = React.useState<CommunicationErrorType>();
  const [sentErrors, setSentErrors] = React.useState<TaskErrors>();

  const [isDetailsOpen, setIsDetailsOpen] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<TaskErrorsItem>();

  React.useEffect(() => {
    setSentErrors(undefined);
    setCommunicationError(undefined);
    setLoading(true);

    API.get(`/taskErrors/${dialogPayload.tripSourceId}/${dialogPayload.tripId}/${dialogPayload.orderId}`)
      .then((response: any) => setSentErrors(response.data as TaskErrors))
      .catch((err: any) => setCommunicationError(getCommunicationErrorType(err)))
      .finally(() => setLoading(false));
  }, [dialogPayload]);

  const columns: GridColDef[] = [
    {
      field: "appliesTo",
      headerName: "Applies to",
      width: 60,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "actionKey",
      headerName: "Action key",
      width: 80,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "taskId",
      headerName: "Task Id",
      width: 60,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "onHold",
      width: 30,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      renderHeader: () => <CellHeaderTooltipComponent tooltipText="On hold" />,
      renderCell: renderOnHoldCell,
    },
    {
      field: "firstAttemptAt",
      headerName: "First attempt at",
      width: 120,
      type: "dateTime",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "lastAttemptAt",
      headerName: "Last attempt at",
      width: 120,
      type: "dateTime",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "attemptCount",
      width: 30,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      renderHeader: () => <CellHeaderTooltipComponent tooltipText="Attempts count" />,
    },
    {
      field: "errCategory",
      headerName: "Error category",
      width: 120,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "type",
      headerName: "Type",
      width: 80,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "code",
      headerName: "Code",
      width: 70,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "desc",
      headerName: "Description",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  const onRowClicked = (param: GridRowParams) => {
    var rowData = param.row as any as TaskErrorsItem;
    setSelectedItem(rowData);
    setIsDetailsOpen(true);
  };

  function prepareGridData(data: TaskErrorsItem[] | null | undefined): GridRowsProp {
    if (!data) {
      return [];
    }

    return data
      .sort((x, y) => (x.lastAttemptAt! > y.lastAttemptAt! ? 1 : 0))
      .map((x, index) => {
        return {
          ...x,
          id: index,
        };
      });
  }

  function showNoRowsInfo(sentErrors: TaskErrors | undefined) {
    if (sentErrors) {
      return !sentErrors.errors || sentErrors.errors.length === 0;
    }

    return false;
  }

  function showGrid(sentErrors: TaskErrors | undefined) {
    if (sentErrors) {
      return sentErrors.errors && sentErrors.errors.length > 0;
    }

    return false;
  }

  const classes = useGridStyles();

  return (
    <>
      <GridDialogContainer
        title="Sent errors"
        maxWidth="lg"
        dialogPayload={dialogPayload}
        isOpen={isOpen}
        loading={loading}
        onCloseAction={onCloseAction}>
        <>
          {communicationError && <ServerCommunicationError errType={communicationError} />}
          {showNoRowsInfo(sentErrors) && (
            <NotificationBoxContainerFlexDivStyled>
              <NotificationBox
                type={NotificationBoxType.Warning}
                width={700}
                message="The error list has been cleared. Please check again in a while."
              />
            </NotificationBoxContainerFlexDivStyled>
          )}
          {showGrid(sentErrors) && (
            <DataGrid
              className={classes.root}
              rows={prepareGridData(sentErrors!.errors)}
              columns={columns}
              density="compact"
              disableColumnReorder
              disableSelectionOnClick
              disableColumnResize
              headerHeight={48}
              pageSize={50}
              rowsPerPageOptions={[]}
              onRowClick={onRowClicked}
              onError={logGridError}></DataGrid>
          )}
        </>
      </GridDialogContainer>
      {selectedItem && (
        <TaskErrorItemDialog item={selectedItem} isOpen={isDetailsOpen} onCloseAction={() => setIsDetailsOpen(false)}></TaskErrorItemDialog>
      )}
    </>
  );
}
