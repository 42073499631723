import React from "react";
import { SentStatus } from "../../api/models";
import { getSentStatusText, sentStatusesOrdered, sentStatusIconRenderer, tooltipRow } from "../../services/statusesRendering";
import GridHeaderIcon from "../gridTooltips/GridHeaderIcon";
import GridHeaderTooltip from "../gridTooltips/GridHeaderTooltip";
import { TooltipHeaderDivStyled } from "./styledComponents";

export function CellHeaderSentStatusComponent() {
  return (
    <GridHeaderTooltip
      tooltipContent={
        <div>
          <TooltipHeaderDivStyled>Sent status</TooltipHeaderDivStyled>
          {sentStatusesOrdered.map((x) => {
            if (x === SentStatus.Closed) {
              return (
                <React.Fragment key={x}>
                  <React.Fragment key={`${x}1`}>{tooltipRow(sentStatusIconRenderer(x, false), getSentStatusText(x, false))}</React.Fragment>
                  <React.Fragment key={`${x}2`}>{tooltipRow(sentStatusIconRenderer(x, true), getSentStatusText(x, true))}</React.Fragment>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={x}>{tooltipRow(sentStatusIconRenderer(x, undefined), getSentStatusText(x, undefined))}</React.Fragment>
              );
            }
          })}
        </div>
      }>
      <GridHeaderIcon></GridHeaderIcon>
    </GridHeaderTooltip>
  );
}
