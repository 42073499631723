import React from "react";
import API from "../../api/api";
import { Button, Dialog, DialogActions, DialogTitle, TextField } from "@material-ui/core";
import { FilterCriteria, OrderList, OrderListType, UpdateSent, WebOrder } from "../../api/models";
import ConfirmationDialog from "./ConfirmationDialog";
import Loader from "../Loader";
import { appColors } from "../colors";
import { logGridError } from "../../api/errorReporter";
import { DataGrid, GridCellParams, GridColDef, GridEditCellPropsParams } from "@material-ui/data-grid";
import { LpgGridColumn } from "../../pages/LpgDeliveriesPage";
import { compareKeys, getCommunicationErrorType, getHttpCallError, getKeyString } from "../../api/common";
import { DataEditMode, HttpCallError, LpgDeliveryRowEditRowVm } from "../../models/common";
import {
  carierKeyValidator,
  editableCellClass,
  getEditableCellValue,
  getLpgEditValidationErrors,
  LpgDeliveryDataEdit,
  lpgRowEditOnColumnDataChange,
  multiSentNumberValidator,
  RowType,
  senderKeyValidator,
} from "../../services/gridDataEdit";
import { CellHeaderEditModeComponent } from "../gridHeaders/CellHeaderEditModeComponent";
import { CellEditModeComponent } from "../gridCells/CellEditModeComponent";
import ProcessItemsErrorDialog from "./ProcessItemsErrorDialog";
import ServerCommunicationError, { CommunicationErrorType } from "../ServerCommunicationIssue";
import { SpacerHorizontalDivStyled, SpacerSize } from "../Spacer";
import { DialogTitleContainerDivStyled } from "./DialogTitleContainerDivStyled";
import styled from "styled-components";
import { useGridStyles } from "../gridCustomTheme";

export enum LpgGridEditableColumn {
  SentNumber = "sentNumber",
  RecipientKey = "recipientKey",
  EditMode = "editMode",
}

const InputContainerDivStyled = styled.div`
  display: flex;
  align-items: center;
`;

const CellEditModeComponentContainerDivStyled = styled.div`
  width: 30px;
`;

const ContainerDivStyled = styled.div`
  background-color: ${appColors.gray};
  min-height: 300px;
`;

const InputsDivStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
  padding-top: 15px;
  padding-bottom: 10px;
  background-color: ${appColors.white};
`;

const InputsContainerDivStyled = styled.div`
  display: flex;
`;

const ButtonsContainerDivStyled = styled.div`
  margin-right: 5px;
`;

const GridDivStyled = styled.div`
  height: 400px;
  margin: 10px;
  background-color: ${appColors.white};
`;

interface EditLpgDialogProps {
  tripId: string;
  tripSourceId: string;
  vehicleId: string | null | undefined;
  isOpen: boolean;
  onCloseAction: (isSaved: boolean) => void;
}

export default function EditLpgDialog({ tripId, tripSourceId, vehicleId, isOpen, onCloseAction }: EditLpgDialogProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [communicationError, setCommunicationError] = React.useState<CommunicationErrorType>();
  const [isCloseConfirmationVisible, setIsCloseConfirmationVisible] = React.useState<boolean>(false);
  const [isClearConfirmationVisible, setClearConfirmationVisible] = React.useState<boolean>(false);
  const [httpOperationError, setHttpOperationError] = React.useState<HttpCallError>();
  const [itemsToEdit, setItemsToEdit] = React.useState<Array<WebOrder>>([]);
  const [listDataEdit, setListDataEdit] = React.useState<LpgDeliveryDataEdit[]>([]);

  const [multiSentNumber, setMultiSentNumber] = React.useState<string>("");
  const [carierKey, setCarierKey] = React.useState<string>("");
  const [senderKey, setSenderKey] = React.useState<string>("");

  const multiSentErrors = getMultiSentErrors();
  const multiSentEditMode = getMultiSentEditMode();

  const carierKeyErrors = getCarrierKeyErrors();
  const carierKeyEditMode = getCarierKeyEditMode();

  const senderKeyErrors = getSenderKeyErrors();
  const senderKeyEditMode = getSenderKeyEditMode();

  const hasPendingEdit =
    listDataEdit.some((x) => x.mode !== DataEditMode.NoEdit) ||
    multiSentEditMode !== DataEditMode.NoEdit ||
    carierKeyEditMode !== DataEditMode.NoEdit ||
    senderKeyEditMode;

  const hasInvalidData =
    listDataEdit.some((x) => getLpgEditValidationErrors(x).length > 0) ||
    (multiSentEditMode !== DataEditMode.NoEdit && multiSentErrors.length > 0) ||
    (carierKeyEditMode !== DataEditMode.NoEdit && carierKeyErrors.length > 0) ||
    (senderKeyEditMode !== DataEditMode.NoEdit && senderKeyErrors.length > 0);

  const isSaveButtonEnabled = hasPendingEdit && !hasInvalidData;

  const isClearButtonVisible = itemsToEdit.some(
    (x) => x.multipleSentNumber || x.sentNumber || x.carrierKey || x.recipientKey || x.senderKey
  );

  React.useEffect(() => {
    setLoading(true);
    setCommunicationError(undefined);

    const filterCriteria: FilterCriteria = {
      tripId: tripId,
      tripSourceId: tripSourceId,
    };
    API.post(`/orderList/${OrderListType.LPG}`, {
      filter: filterCriteria,
    })
      .then((response: any) => {
        const respContent: OrderList = response.data;

        const editableRows = respContent.orders?.filter((x) => x.isSentDataEditable) || [];
        setItemsToEdit(editableRows);
        setMultiSentNumber(getValueFromItemsToEdit(editableRows, (x) => x.multipleSentNumber));
        setCarierKey(getValueFromItemsToEdit(editableRows, (x) => x.carrierKey));
        setSenderKey(getValueFromItemsToEdit(editableRows, (x) => x.senderKey));
      })
      .catch((err: any) => setCommunicationError(getCommunicationErrorType(err)))
      .finally(() => setLoading(false));
  }, [tripId, tripSourceId]);

  const saveLpgData = () => {
    const dataToUpdate = getDataToUpdate();

    setLoading(true);
    API.put("/updateSent", dataToUpdate)
      .then(() => onCloseAction(true))
      .catch((err: any) => {
        setHttpOperationError(getHttpCallError(err));
        setLoading(false);
      });
  };

  const onClose = () => {
    if (hasPendingEdit) {
      setIsCloseConfirmationVisible(true);
    } else {
      onCloseAction(false);
    }
  };

  const onCloseConfirmation = (isConfirmed: boolean) => {
    setIsCloseConfirmationVisible(false);
    if (isConfirmed) {
      onCloseAction(false);
    }
  };

  const onClearConfirm = (isConfirmed: boolean) => {
    setClearConfirmationVisible(false);
    if (isConfirmed) {
      setLoading(true);

      const clearSentDataRequest: UpdateSent[] = itemsToEdit.map((x) => {
        return {
          orderId: x.orderId,
          tripId: x.tripId,
          tripSourceId: x.tripSourceId,
          multipleSentNumber: "",
          sentNumber: "",
          senderKey: "",
          carrierKey: "",
          recipientKey: "",
        };
      });

      API.put("/updateSent", clearSentDataRequest)
        .then(() => onCloseAction(true))
        .catch((err: any) => {
          setHttpOperationError(getHttpCallError(err));
          setLoading(false);
        });
    }
  };

  const onEditCellChange = (params: GridEditCellPropsParams) => {
    const row = itemsToEdit.find((row) => getKeyString(row) === params.id);
    if (!row) {
      throw new Error(`Row of id: ${params.id} was not found`);
    }

    let isNewDataEditor = false;
    let dataEditor = listDataEdit.find((x) => x.id === params.id);
    if (!dataEditor) {
      isNewDataEditor = true;
      dataEditor = new LpgDeliveryDataEdit(row, row.sentNumber || "", row.recipientKey || "");
    }

    lpgRowEditOnColumnDataChange(row, dataEditor, params.field, params.props.value?.toString());
    updateListEditorDataState(dataEditor, isNewDataEditor);
  };

  const columns: GridColDef[] = [
    {
      field: LpgGridColumn.OrderId,
      headerName: "Order ID",
      width: 100,
      disableColumnMenu: true,
      editable: false,
    },
    {
      field: LpgGridEditableColumn.SentNumber,
      headerName: "SENT Number",
      flex: 3,
      disableColumnMenu: true,
      editable: true,
      sortable: false,
      cellClassName: (params: GridCellParams) => editableCellClass(params, LpgGridEditableColumn.SentNumber),
    },
    {
      field: LpgGridEditableColumn.RecipientKey,
      headerName: "Receipient Key",
      flex: 2,
      disableColumnMenu: true,
      editable: true,
      sortable: false,
      cellClassName: (params: GridCellParams) => editableCellClass(params, LpgGridEditableColumn.RecipientKey),
    },
    {
      field: LpgGridEditableColumn.EditMode,
      width: 40,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <CellHeaderEditModeComponent rowType={RowType.Lpg} />,
      renderCell: (params: GridCellParams) => {
        const rowData: LpgDeliveryRowEditRowVm = params.row as LpgDeliveryRowEditRowVm;
        const dataEditor = listDataEdit.find((x) => x.id === rowData.id);
        return (
          <CellEditModeComponent
            key={params.id}
            isRowEditable={rowData.isSentDataEditable}
            editMode={dataEditor?.mode}
            valiadationErrorMessage={getLpgEditValidationErrors(dataEditor)}
            size={24}
            rowType={RowType.Lpg}
          />
        );
      },
    },
  ];

  function updateListEditorDataState(dataEditor: LpgDeliveryDataEdit, isNewDataEditor: boolean) {
    if (isNewDataEditor) {
      setListDataEdit([...listDataEdit, dataEditor]);
    } else {
      setListDataEdit(
        listDataEdit.map((editor) => {
          if (editor.rowKey === dataEditor?.rowKey) {
            return { ...dataEditor };
          } else {
            return editor;
          }
        })
      );
    }
  }

  function prepareListData(data: WebOrder[], editorData: LpgDeliveryDataEdit[]): LpgDeliveryRowEditRowVm[] {
    return data.map((row) => {
      const dataEditor = editorData.find((x) => compareKeys(row, x.rowKey));

      return {
        ...row,
        id: getKeyString(row),
        sentNumber: getEditableCellValue(!!dataEditor, dataEditor?.sentNumber.value, row.sentNumber),
        sentNumberHasError: dataEditor?.sentNumber.isValueChangedAndInvalid || false,
        recipientKey: getEditableCellValue(!!dataEditor, dataEditor?.recipientKey.value, row.recipientKey),
        recipientKeyHasError: dataEditor?.recipientKey.isValueChangedAndInvalid || false,
        editMode: dataEditor?.mode,
      };
    });
  }

  function getValueFromItemsToEdit(editableRows: WebOrder[], getter: (row: WebOrder) => string | null | undefined) {
    const rowsWithValueSet = editableRows.filter((x) => !!getter(x));
    if (rowsWithValueSet.length === 0) {
      return "";
    }

    if (rowsWithValueSet.length > 1) {
      const value = getter(rowsWithValueSet[0]);
      const hasRowsWithDifferentValue = rowsWithValueSet.some((x) => getter(x) !== value);

      if (hasRowsWithDifferentValue) {
        throw new Error("Rows with different multisent number found");
      }
    }

    return getter(rowsWithValueSet[0]) || "";
  }

  function getMultiSentEditMode() {
    const initialValue = getValueFromItemsToEdit(itemsToEdit, (x) => x.multipleSentNumber);

    return initialValue === multiSentNumber ? DataEditMode.NoEdit : DataEditMode.HasPendingEdit;
  }

  function getMultiSentErrors() {
    const error = multiSentNumberValidator(multiSentNumber);

    return error ? [error] : [];
  }

  function getCarierKeyEditMode() {
    const initialValue = getValueFromItemsToEdit(itemsToEdit, (x) => x.carrierKey);

    return initialValue === carierKey ? DataEditMode.NoEdit : DataEditMode.HasPendingEdit;
  }

  function getCarrierKeyErrors() {
    const error = carierKeyValidator(carierKey);

    return error ? [error] : [];
  }

  function getSenderKeyEditMode() {
    const initialValue = getValueFromItemsToEdit(itemsToEdit, (x) => x.senderKey);

    return initialValue === senderKey ? DataEditMode.NoEdit : DataEditMode.HasPendingEdit;
  }

  function getSenderKeyErrors() {
    const error = senderKeyValidator(senderKey);

    return error ? [error] : [];
  }

  function getDataToUpdate() {
    var result: Array<UpdateSent> = [];

    itemsToEdit.forEach((row) => {
      const dataEditor = listDataEdit.find((x) => x.id === getKeyString(row));
      if (
        multiSentEditMode !== DataEditMode.NoEdit ||
        carierKeyEditMode !== DataEditMode.NoEdit ||
        senderKeyEditMode !== DataEditMode.NoEdit ||
        (dataEditor && dataEditor.mode !== DataEditMode.NoEdit)
      ) {
        const dataToUpdate: UpdateSent = {
          tripSourceId: row.tripSourceId,
          tripId: row.tripId,
          orderId: row.orderId,
        };

        if (multiSentEditMode !== DataEditMode.NoEdit) {
          dataToUpdate.multipleSentNumber = multiSentNumber;
        }

        if (carierKeyEditMode !== DataEditMode.NoEdit) {
          dataToUpdate.carrierKey = carierKey;
        }

        if (senderKeyEditMode !== DataEditMode.NoEdit) {
          dataToUpdate.senderKey = senderKey;
        }

        if (dataEditor) {
          if (dataEditor.sentNumber.isValueChanged) {
            dataToUpdate.sentNumber = dataEditor.sentNumber.value;
          }

          if (dataEditor.recipientKey.isValueChanged) {
            dataToUpdate.recipientKey = dataEditor.recipientKey.value;
          }
        }

        result.push(dataToUpdate);
      }
    });

    console.log(result);

    return result;
  }

  const classes = useGridStyles();
  return (
    <>
      <Loader isLoading={loading}></Loader>
      <Dialog open={isOpen} fullWidth maxWidth={"md"} onClose={onClose}>
        <DialogTitle disableTypography>
          <DialogTitleContainerDivStyled>
            <h3>Edit LPG deliveries</h3>
            <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium }}></SpacerHorizontalDivStyled>
            <h4>
              Vehicle ID: {vehicleId} | Trip ID: {tripId}
            </h4>
          </DialogTitleContainerDivStyled>
        </DialogTitle>
        <ContainerDivStyled>
          {communicationError && <ServerCommunicationError errType={communicationError} />}

          {itemsToEdit.length > 0 && (
            <>
              <InputsDivStyled>
                <InputsContainerDivStyled>
                  <SpacerHorizontalDivStyled {...{ size: SpacerSize.Small, width: 250 }}>
                    <InputContainerDivStyled>
                      <TextField
                        onChange={(e) => setMultiSentNumber(e.target.value)}
                        value={multiSentNumber}
                        label="Multi SENT Number"
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                      <CellEditModeComponentContainerDivStyled>
                        <CellEditModeComponent
                          key="multiSent"
                          isRowEditable={true}
                          editMode={multiSentEditMode}
                          valiadationErrorMessage={multiSentErrors}
                          size={28}
                          rowType={RowType.Lpg}
                        />
                      </CellEditModeComponentContainerDivStyled>
                    </InputContainerDivStyled>
                  </SpacerHorizontalDivStyled>
                  <SpacerHorizontalDivStyled {...{ size: SpacerSize.ExtraLarge, width: 150 }}>
                    <InputContainerDivStyled>
                      <TextField
                        onChange={(e) => setSenderKey(e.target.value)}
                        value={senderKey}
                        label="Sender Key"
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                      <CellEditModeComponentContainerDivStyled>
                        <CellEditModeComponent
                          key="senderKey"
                          isRowEditable={true}
                          editMode={senderKeyEditMode}
                          valiadationErrorMessage={senderKeyErrors}
                          size={28}
                          rowType={RowType.Lpg}
                        />
                      </CellEditModeComponentContainerDivStyled>
                    </InputContainerDivStyled>
                  </SpacerHorizontalDivStyled>
                  <SpacerHorizontalDivStyled {...{ size: SpacerSize.ExtraLarge, width: 150 }}>
                    <InputContainerDivStyled>
                      <TextField
                        onChange={(e) => setCarierKey(e.target.value)}
                        value={carierKey}
                        label="Carier Key"
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                      <CellEditModeComponentContainerDivStyled>
                        <CellEditModeComponent
                          key="carierKey"
                          isRowEditable={true}
                          editMode={carierKeyEditMode}
                          valiadationErrorMessage={carierKeyErrors}
                          size={28}
                          rowType={RowType.Lpg}
                        />
                      </CellEditModeComponentContainerDivStyled>
                    </InputContainerDivStyled>
                  </SpacerHorizontalDivStyled>
                </InputsContainerDivStyled>
                <SpacerHorizontalDivStyled {...{ size: SpacerSize.ExtraLarge, width: 200 }}>
                  {isClearButtonVisible && (
                    <ButtonsContainerDivStyled>
                      <Button variant="contained" color="primary" size="large" onClick={() => setClearConfirmationVisible(true)} fullWidth>
                        Clear SENT Data
                      </Button>
                    </ButtonsContainerDivStyled>
                  )}
                </SpacerHorizontalDivStyled>
              </InputsDivStyled>
              <GridDivStyled className="grid-container">
                <DataGrid
                  className={classes.root}
                  rows={prepareListData(itemsToEdit, listDataEdit)}
                  columns={columns}
                  density="compact"
                  disableColumnReorder
                  disableSelectionOnClick
                  disableColumnResize
                  headerHeight={48}
                  pageSize={50}
                  rowsPerPageOptions={[]}
                  onEditCellChange={onEditCellChange}
                  onError={logGridError}></DataGrid>
              </GridDivStyled>
            </>
          )}

          {httpOperationError && (
            <ProcessItemsErrorDialog
              isOpen={!!httpOperationError}
              error={httpOperationError}
              onCloseAction={() => setHttpOperationError(undefined)}
            />
          )}
        </ContainerDivStyled>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Close
          </Button>
          <Button onClick={() => saveLpgData()} variant="contained" color="primary" disabled={!isSaveButtonEnabled}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        headerText="Do You want to close the window ?"
        messageText="There are unsaved changes, pressing 'Close' button will close the editing window and changes will be lost."
        continueButtonText="Close"
        isOpen={isCloseConfirmationVisible}
        onCloseAction={onCloseConfirmation}
      />

      <ConfirmationDialog
        headerText="Do You want to clear SENT data for displayed orders ?"
        messageText="Press 'Continue' to remove the SENT data or 'Cancel' to cancel the operation."
        isOpen={isClearConfirmationVisible}
        onCloseAction={onClearConfirm}
      />
    </>
  );
}
