import React from "react";
import API from "../api/api";
import { DataGrid, GridCellParams, GridColDef, GridEditCellPropsParams, GridSortModel, GridValueFormatterParams } from "@material-ui/data-grid";
import Loader from "../common/Loader";
import Filter, { CustomFilter, mapCustomFilterToFilterCriteria } from "../common/Filter";
import NotificationBox, { NotificationBoxType } from "../common/Notification";
import { compareKeys, getCommunicationErrorType, getHttpCallError, getKeyString, ItemKey } from "../api/common";
import { DataEditMode, FuelDeliveryRowVm, LpgDeliveryRowVm, HttpCallError, UpdateError } from "../models/common";
import {
  editableCellClass,
  FuelDeliveryDataEdit,
  FuelGridEditableColumn,
  fuelRowCanSave,
  fuelRowEditOnColumnDataChange,
  fuelRowRevertChanges,
  getEditableCellValue,
  getFuelEditValidationErrors,
  RowType,
} from "../services/gridDataEdit";
import InfAboutControlDialog from "../common/dialogs/InfAboutControlDialog";
import OfficialInfoDialog from "../common/dialogs/OfficialInfoDialog";
import PageTitle, { FilterMode } from "../common/PageTitle";
import TaskErrorsDialog from "../common/dialogs/TaskErrorsDialog";
import RowMenu, { getRowMenuItems, showTaskStatusErrorDialog, MenuItemType } from "../common/RowMenu";
import SentHistoryDialog from "../common/dialogs/SentHistoryDialog";
import { OrderList, OrderListType, OrdersToProcess, UpdateSent, WebOrder } from "../api/models";
import { logGridError } from "../api/errorReporter";
import ServerCommunicationError, { CommunicationErrorType } from "../common/ServerCommunicationIssue";
import { CellEditModeComponent } from "../common/gridCells/CellEditModeComponent";
import { CellClickableComponent, ClickableCellType } from "../common/gridCells/CellClickableComponent";
import { CellIsSelectedComponent } from "../common/gridCells/CellIsSelectedComponent";
import { CellOrderStatusComponent } from "../common/gridCells/CellOrderStatusComponent";
import { CellDetailsComponent } from "../common/gridCells/CellDetailsComponent";
import { CellMenuComponent } from "../common/gridCells/CellMenuComponent";
import { CellSentStatusComponent } from "../common/gridCells/CellSentStatusComponent";
import { CellSentTaskStatusComponent } from "../common/gridCells/CellSentTaskStatusComponent";
import { CellHeaderSentStatusComponent } from "../common/gridHeaders/CellHeaderSentStatusComponent";
import { CellHeaderSentTaskStatusComponent } from "../common/gridHeaders/CellHeaderSentTaskStatusComponent";
import { CellHeaderEditModeComponent } from "../common/gridHeaders/CellHeaderEditModeComponent";
import { CellHeaderTooltipComponent } from "../common/gridHeaders/CellHeaderTooltipComponent";
import { CellHeaderCheckboxComponent } from "../common/gridHeaders/CellHeaderCheckboxComponent";
import { cellClass } from "../services/statusesRendering";
import ProcessItemsErrorDialog from "../common/dialogs/ProcessItemsErrorDialog";
import OrderDetailsDialog from "../common/details/OrderDetailsDialog";
import { SpacerSize, SpacerVerticalDivStyled } from "../common/Spacer";
import { PageContainerDivStyled, PageGridDivStyled } from "./pageStyledComponents";
import { useGridStyles } from "../common/gridCustomTheme";
import { CellHeaderOrderStatusComponent } from "../common/gridHeaders/CellHeaderOrderStatusComponent";
import { dateToString } from "../common/formatHelper";
import { downloadFile, DownloadErrorDialog, DownloadErrorProps } from "../api/fileDownloader";

enum FuelGridColumn {
  IsSelected = "isSelected",
  IsCancelled = "isCancelled",
  PlannedStartDate = "plannedStartDate",
  VehicleId = "vehicleId",
  TripId = "tripId",
  OrderId = "orderId",
  EditMode = "editMode",
  InfoAboutControl = "infoAboutControl",
  OfficialInfo = "officialInfo",
  SentStatus = "sentStatus",
  SentTaskStatus = "sentTaskStatus",
  Details = "details",
  Menu = "menu",
}

export default function FuelDeliveriesPage() {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | Element>(null);
  const [isCustomFiltering, setIsCustomFiltering] = React.useState<boolean>(false);
  const [searchCriteria, setSearchCriteria] = React.useState<CustomFilter>();
  const [sortOrder, setSortOrder] = React.useState<GridSortModel>([{ field: FuelGridColumn.PlannedStartDate, sort: "desc" }]);
  const [pageSize, setPageSize] = React.useState<number>(50);
  const [isAllSelected, setisAllSelected] = React.useState(false);
  const [hasMoreData, setHasMoreData] = React.useState<boolean>();

  const [rowSelectionList, setRowSelectionList] = React.useState<ItemKey[]>([]);
  const showProcessButton = rowSelectionList.length > 0;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [communicationError, setCommunicationError] = React.useState<CommunicationErrorType>();
  const [apiData, setApiData] = React.useState<WebOrder[]>();
  const [listDataEdit, setListDataEdit] = React.useState<FuelDeliveryDataEdit[]>([]);
  const [listDataEditServerErrors, setListDataEditServerErrors] = React.useState<UpdateError[]>([]);

  const [selectedItem, setSelectedItem] = React.useState<FuelDeliveryRowVm>();

  const [infoAboutControlOpen, setInfoAboutControlOpen] = React.useState<boolean>(false);
  const [officialInfoOpen, setOfficialInfoOpen] = React.useState<boolean>(false);
  const [detailsOpen, setDetailsOpen] = React.useState<boolean>(false);
  const [sentHistoryOpen, setSentHistoryOpen] = React.useState<boolean>(false);
  const [taskErrorsOpen, setTaskErrorsOpen] = React.useState<boolean>(false);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const [httpOperationError, setHttpOperationError] = React.useState<HttpCallError>();

  const [downloadErrorDialogOpen, setDownloadErrorDialogOpen] = React.useState<boolean>(false);
  const [downloadErrorProps, setDownloadErrorProps] = React.useState<DownloadErrorProps>({ statusCode: 200, errMessage: "" });

  const fetchGridData = React.useCallback((searchCriteria: CustomFilter | undefined) => {
    setLoading(true);
    setCommunicationError(undefined);
    setApiData(undefined);

    let request: Promise<any>;
    if (searchCriteria) {
      request = API.post(`/orderList/${OrderListType.Fuel}`, {
        filter: mapCustomFilterToFilterCriteria(searchCriteria),
      });
    } else {
      request = API.get(`/orderList/${OrderListType.Fuel}`);
    }

    return request
      .then((response) => {
        const respContent: OrderList = response.data;

        setisAllSelected(false);
        setApiData(respContent.orders || []);
        setListDataEdit([]);
        setListDataEditServerErrors([]);
        setRowSelectionList([]);
        setHasMoreData(respContent.hasMore);
      })
      .catch((err) => setCommunicationError(getCommunicationErrorType(err)))
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    fetchGridData(searchCriteria);
  }, [searchCriteria, fetchGridData]);

  const onIsSelectedChangedChanged = () => {
    if (!apiData) {
      return;
    }

    setisAllSelected((x) => {
      x = !x;
      if (x) {
        setRowSelectionList(apiData.filter((x) => x.isSelectionEnabled).map((x) => x));
      } else {
        setRowSelectionList([]);
      }
      return x;
    });
  };

  const onCellClicked = (param: GridCellParams, e: React.MouseEvent) => {
    const rowData: FuelDeliveryRowVm = param.row as FuelDeliveryRowVm;
    setSelectedItem(rowData);

    if (param.field === FuelGridColumn.IsSelected && rowData.isSelectionEnabled) {
      let rowSelection = rowSelectionList.find((x) => compareKeys(rowData, x));
      if (rowSelection) {
        setRowSelectionList((prev) => prev.filter((x) => !compareKeys(x, rowSelection!)));
      } else {
        setRowSelectionList((prev) => [...prev, rowData]);
      }
    } else if (param.field === FuelGridColumn.Menu) {
      setMenuOpen(true);
      setMenuAnchorEl(e.currentTarget);
    } else if (param.field === FuelGridColumn.InfoAboutControl) {
      if (rowData.hasInfoAboutControl) {
        setInfoAboutControlOpen(true);
      }
    } else if (param.field === FuelGridColumn.OfficialInfo) {
      if (rowData.hasOfficialInfo) {
        setOfficialInfoOpen(true);
      }
    } else if (param.field === FuelGridColumn.SentTaskStatus && showTaskStatusErrorDialog(rowData)) {
      setTaskErrorsOpen(true);
    } else if (param.field === FuelGridColumn.EditMode && rowData.editMode === DataEditMode.SavingError) {
      const updateError = listDataEditServerErrors.find((x) => x.id === rowData.id);
      if (updateError) {
        setHttpOperationError({
          statusCode: updateError.statusCode,
          errorDetails: updateError.errorDetails,
        });
      }
    }
  };

  const onMenuClicked = (item: MenuItemType | undefined) => {
    setMenuOpen(false);
    if (!item) {
      return;
    }

    switch (item) {
      case MenuItemType.SentHistory:
        setSentHistoryOpen(true);
        break;
      case MenuItemType.SentError:
        setTaskErrorsOpen(true);
        break;
      case MenuItemType.InformationAboutControl:
        setInfoAboutControlOpen(true);
        break;
      case MenuItemType.OfficialInfo:
        setOfficialInfoOpen(true);
        break;
      case MenuItemType.ClearSentData:
        if (!selectedItem) {
          throw new Error("No item selected");
        }

        setLoading(true);
        const clearSentDataReques: UpdateSent = {
          orderId: selectedItem.orderId,
          tripId: selectedItem.tripId,
          tripSourceId: selectedItem.tripSourceId,
          sentNumber: "",
          senderKey: "",
          carrierKey: "",
          recipientKey: "",
        };

        API.put("/updateSent", [clearSentDataReques])
          .then(() => fetchGridData(searchCriteria))
          .catch((err: any) => setHttpOperationError(getHttpCallError(err)))
          .finally(() => setLoading(false));
        break;
      case MenuItemType.EmergencyDownload:
        if (!selectedItem) {
          throw new Error("No item selected");
        }

        const emergencyDownloadUrl = `/EmergencyDownload/Fuel/${selectedItem?.tripSourceId}/${selectedItem?.tripId}/${selectedItem?.orderId}`;
        const documentName = `${selectedItem?.tripSourceId}-${selectedItem?.tripId}-${selectedItem?.orderId}-SENT_100.XML`;
        const result = downloadFile(emergencyDownloadUrl, documentName);
        result.then(r => {
          if (!r.IsSuccess) {
            const dlErr: DownloadErrorProps = { statusCode: r.StatusCode ?? 500, errMessage: r.Message ?? "" }
            setDownloadErrorProps(dlErr);
            setDownloadErrorDialogOpen(true);
          }
        });

        break;
      default:
        throw new Error(`Invalid value: ${item} for MenuItemType`);
    }
  };

  const onEditCellChange = (params: GridEditCellPropsParams) => {
    if (!apiData) {
      return;
    }

    const row = apiData.find((row) => getKeyString(row) === params.id);
    if (!row) {
      throw new Error(`Row of id: ${params.id} was not found`);
    }

    let isNewDataEditor = false;
    let dataEditor = listDataEdit.find((x) => x.id === params.id);
    if (!dataEditor) {
      isNewDataEditor = true;
      dataEditor = new FuelDeliveryDataEdit(row, row.sentNumber || "", row.senderKey || "", row.carrierKey || "", row.recipientKey || "");
    }

    fuelRowEditOnColumnDataChange(row, dataEditor, params.field, params.props.value?.toString());
    updateListEditorDataState(dataEditor, isNewDataEditor);
  };

  const onEditCellChangeCommitted = (params: GridEditCellPropsParams) => {
    let dataEditor = listDataEdit.find((x) => x.id === params.id);

    if (dataEditor && fuelRowCanSave(dataEditor)) {
      const updateRequest: UpdateSent = {
        orderId: dataEditor.rowKey.orderId,
        tripId: dataEditor.rowKey.tripId,
        tripSourceId: dataEditor.rowKey.tripSourceId,
        sentNumber: dataEditor.sentNumber.value,
      };

      if (dataEditor.recipientKey.isValueChanged) {
        updateRequest.recipientKey = dataEditor.recipientKey.value;
      }

      if (dataEditor.senderKey.isValueChanged) {
        updateRequest.senderKey = dataEditor.senderKey.value;
      }

      if (dataEditor.carierKey.isValueChanged) {
        updateRequest.carrierKey = dataEditor.carierKey.value;
      }

      dataEditor.mode = DataEditMode.SavingInProgress;
      updateListEditorDataState(dataEditor, false);

      API.put("/updateSent", [updateRequest])
        .then((_: any) => {
          dataEditor!.mode = DataEditMode.InSync;
          updateListEditorDataState(dataEditor!, false);

          const row = apiData!.find((row) => getKeyString(row) === params.id);
          updateApiData(row!, updateRequest);
          setListDataEditServerErrors((x) => [...x.filter((x) => x.id !== dataEditor!.id)]);
        })
        .catch((err: any) => {
          let updateError: UpdateError;
          if (err.response) {
            updateError = {
              id: dataEditor!.id,
              statusCode: err.response.status,
              errorDetails: err.response.data,
            };
          } else {
            updateError = {
              id: dataEditor!.id,
              errorDetails: err.message,
            };
          }

          setListDataEditServerErrors((x) => [...x.filter((x) => x.id !== updateError.id).concat(updateError)]);
          dataEditor!.mode = DataEditMode.SavingError;
          updateListEditorDataState(dataEditor!, false);
        });
    }
  };

  const onCellKeyDown = (params: GridCellParams, event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      let dataEditor = listDataEdit.find((x) => x.id === params.id);
      const row = apiData!.find((row) => getKeyString(row) === params.id);

      fuelRowRevertChanges(row!, dataEditor!, params.field);
      updateListEditorDataState(dataEditor!, false);
    }
  };

  const processSelectedItems = () => {
    setLoading(true);
    const ordersToProcess: OrdersToProcess[] = rowSelectionList.map((x) => {
      return {
        tripId: x.tripId,
        tripSourceId: x.tripSourceId,
        orderId: x.orderId,
      };
    });

    API.post("/ordersToProcess", ordersToProcess)
      .then(() => fetchGridData(searchCriteria))
      .catch((err: any) => setHttpOperationError(getHttpCallError(err)))
      .finally(() => setLoading(false));
  };

  const onFilterModeChanged = (mode: FilterMode) => {
    if (mode === FilterMode.Default) {
      setIsCustomFiltering(false);
      setSearchCriteria(undefined);
    } else {
      setIsCustomFiltering(true);
    }
  };

  const onDetailsClicked = React.useCallback((row: FuelDeliveryRowVm | LpgDeliveryRowVm) => {
    setSelectedItem(row as FuelDeliveryRowVm);
    setDetailsOpen(true);
  }, []);

  const columns: GridColDef[] = [
    {
      field: FuelGridColumn.IsSelected,
      width: 40,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: cellClass,
      renderHeader: () => <CellHeaderCheckboxComponent isAllSelected={isAllSelected} onCheckboxChange={onIsSelectedChangedChanged} />,
      renderCell: (params: GridCellParams) => {
        const rowData: FuelDeliveryRowVm = params.row as FuelDeliveryRowVm;
        return <CellIsSelectedComponent key={params.id} isSelected={rowData.isSelected} isSelectionEnabled={rowData.isSelectionEnabled} />;
      },
    },
    {
      field: FuelGridColumn.IsCancelled,
      width: 35,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: cellClass,
      renderHeader: () => <CellHeaderOrderStatusComponent />,
      renderCell: (params: GridCellParams) => {
        const rowData: FuelDeliveryRowVm = params.row as FuelDeliveryRowVm;
        return <CellOrderStatusComponent key={params.id} orderStatus={rowData.orderStatus} />;
      },
    },
    {
      field: FuelGridColumn.PlannedStartDate,
      headerName: "Planned start date",
      width: 130,
      type: "date",
      valueFormatter: (params: GridValueFormatterParams) => dateToString(params.value as Date),
      disableColumnMenu: true,
      editable: false,
      align: "center",
      cellClassName: cellClass,
    },
    {
      field: FuelGridColumn.VehicleId,
      headerName: "Vehicle ID",
      width: 90,
      disableColumnMenu: true,
      editable: false,
      cellClassName: cellClass,
    },
    {
      field: FuelGridColumn.TripId,
      headerName: "Trip ID",
      width: 75,
      disableColumnMenu: true,
      editable: false,
      cellClassName: cellClass,
    },
    {
      field: FuelGridColumn.InfoAboutControl,
      width: 40,
      disableColumnMenu: true,
      editable: false,
      sortable: false,
      headerAlign: "center",
      renderHeader: () => <CellHeaderTooltipComponent tooltipText="Info about control" />,
      renderCell: (params: GridCellParams) => {
        const rowData: FuelDeliveryRowVm = params.row as FuelDeliveryRowVm;
        const cellType = rowData.hasInfoAboutControl ? ClickableCellType.InfoAboutControl : undefined;

        return <CellClickableComponent key={`${params.id}IAC`} type={cellType} />;
      },
      cellClassName: cellClass,
    },
    {
      field: FuelGridColumn.OfficialInfo,
      width: 40,
      disableColumnMenu: true,
      editable: false,
      sortable: false,
      headerAlign: "center",
      renderHeader: () => <CellHeaderTooltipComponent tooltipText="Official info" />,
      renderCell: (params: GridCellParams) => {
        const rowData: FuelDeliveryRowVm = params.row as FuelDeliveryRowVm;
        const cellType = rowData.hasOfficialInfo ? ClickableCellType.OfficialInfo : undefined;

        return <CellClickableComponent key={`${params.id}OI`} type={cellType} />;
      },
      cellClassName: cellClass,
    },
    {
      field: FuelGridColumn.OrderId,
      headerName: "Order ID",
      width: 100,
      disableColumnMenu: true,
      editable: false,
      cellClassName: cellClass,
    },
    {
      field: FuelGridEditableColumn.SentNumber,
      headerName: "SENT Number",
      width: 155,
      disableColumnMenu: true,
      editable: true,
      cellClassName: (params: GridCellParams) => editableCellClass(params, FuelGridEditableColumn.SentNumber),
    },
    {
      field: FuelGridEditableColumn.SenderKey,
      headerName: "Sender Key",
      width: 110,
      disableColumnMenu: true,
      editable: true,
      sortable: false,
      cellClassName: (params: GridCellParams) => editableCellClass(params, FuelGridEditableColumn.SenderKey),
    },
    {
      field: FuelGridEditableColumn.CarrierKey,
      headerName: "Carrier Key",
      width: 110,
      disableColumnMenu: true,
      editable: true,
      sortable: false,
      cellClassName: (params: GridCellParams) => editableCellClass(params, FuelGridEditableColumn.CarrierKey),
    },
    {
      field: FuelGridEditableColumn.RecipientKey,
      headerName: "Receipient Key",
      width: 110,
      disableColumnMenu: true,
      editable: true,
      sortable: false,
      cellClassName: (params: GridCellParams) => editableCellClass(params, FuelGridEditableColumn.RecipientKey),
    },
    {
      field: FuelGridColumn.EditMode,
      width: 35,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <CellHeaderEditModeComponent rowType={RowType.Fuel} />,
      renderCell: (params: GridCellParams) => {
        const rowData: FuelDeliveryRowVm = params.row as FuelDeliveryRowVm;
        const dataEditor = listDataEdit.find((x) => x.id === rowData.id);
        return (
          <CellEditModeComponent
            key={params.id}
            isRowEditable={rowData.isSentDataEditable}
            editMode={dataEditor?.mode}
            valiadationErrorMessage={getFuelEditValidationErrors(dataEditor)}
            size={24}
            rowType={RowType.Fuel}
          />
        );
      },
      cellClassName: cellClass,
    },
    {
      field: FuelGridColumn.SentStatus,
      width: 35,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      renderHeader: () => <CellHeaderSentStatusComponent />,
      renderCell: (params: GridCellParams) => {
        const rowData: FuelDeliveryRowVm = params.row as FuelDeliveryRowVm;
        return <CellSentStatusComponent key={params.id} sentStatus={rowData.sentStatus} closedIncompliant={rowData.closedIncompliant} />;
      },
      cellClassName: cellClass,
    },
    {
      field: FuelGridColumn.SentTaskStatus,
      width: 35,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      renderHeader: () => <CellHeaderSentTaskStatusComponent />,
      renderCell: (params: GridCellParams) => {
        const rowData: FuelDeliveryRowVm = params.row as FuelDeliveryRowVm;
        return (
          <CellSentTaskStatusComponent
            key={params.id}
            sentTaskStatus={rowData.sentTaskStatus}
            showTaskStatusDialog={showTaskStatusErrorDialog(rowData)}
          />
        );
      },
      cellClassName: cellClass,
    },
    {
      field: FuelGridColumn.Details,
      width: 75,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => <>&nbsp;</>,
      renderCell: (params: GridCellParams) => (
        <CellDetailsComponent row={params.row as FuelDeliveryRowVm} key={params.id} onDetailsCliked={onDetailsClicked} />
      ),
    },
    {
      field: FuelGridColumn.Menu,
      width: 25,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => <>&nbsp;</>,
      renderCell: (params: GridCellParams) => <CellMenuComponent key={params.id} />,
    },
  ];

  function updateListEditorDataState(dataEditor: FuelDeliveryDataEdit, isNewDataEditor: boolean) {
    if (isNewDataEditor) {
      setListDataEdit([...listDataEdit, dataEditor]);
    } else {
      setListDataEdit(
        listDataEdit.map((editor) => {
          if (editor.rowKey === dataEditor?.rowKey) {
            return { ...dataEditor };
          } else {
            return editor;
          }
        })
      );
    }
  }

  function updateApiData(row: WebOrder, updateRequest: UpdateSent) {
    const rowId = getKeyString(row!);

    setApiData((x) =>
      x!.map((y) => {
        if (getKeyString(y) === rowId) {
          return {
            ...y,
            sentNumber: updateRequest.sentNumber,
            senderKey: updateRequest.senderKey,
            carrierKey: updateRequest.carrierKey,
            recipientKey: updateRequest.recipientKey,
          };
        } else {
          return y;
        }
      })
    );
  }

  function prepareListData(data: WebOrder[], rowSelectionList: ItemKey[], editorData: FuelDeliveryDataEdit[]): FuelDeliveryRowVm[] {
    return data.map((row) => {
      const dataEditor = editorData.find((x) => compareKeys(row, x.rowKey));
      const rowSelection = rowSelectionList.find((x) => compareKeys(row, x));
      const hasPendingEdit = dataEditor?.mode === DataEditMode.HasPendingEdit;

      return {
        ...row,
        id: getKeyString(row),
        sentNumber: getEditableCellValue(!!dataEditor, dataEditor?.sentNumber.value, row.sentNumber),
        sentNumberHasError: (hasPendingEdit && dataEditor?.sentNumber.isValid !== true) || false,
        senderKey: getEditableCellValue(!!dataEditor, dataEditor?.senderKey.value, row.senderKey),
        senderKeyHasError: dataEditor?.senderKey.isValueChangedAndInvalid || false,
        carrierKey: getEditableCellValue(!!dataEditor, dataEditor?.carierKey.value, row.carrierKey),
        carrierKeyHasError: dataEditor?.carierKey.isValueChangedAndInvalid || false,
        recipientKey: getEditableCellValue(!!dataEditor, dataEditor?.recipientKey.value, row.recipientKey),
        recipientKeyHasError: dataEditor?.recipientKey.isValueChangedAndInvalid || false,

        isSelected: !!rowSelection || false,
        editMode: dataEditor?.mode,
      };
    });
  }

  function isCellEditable(params: GridCellParams) {
    const rowData = params.row as FuelDeliveryRowVm;
    return rowData.isSentDataEditable === true;
  }

  const classes = useGridStyles();
  return (
    <>
      <Loader isLoading={loading}></Loader>
      <PageContainerDivStyled>
        <PageTitle
          title="Fuel deliveries"
          showProcessButton={showProcessButton}
          onFilteringStatusChanged={onFilterModeChanged}
          onRefreshButtonCliecked={() => fetchGridData(searchCriteria)}
          onProcessButtonClickConfirmed={processSelectedItems}></PageTitle>
        {isCustomFiltering && <Filter onApplyFilter={(x) => setSearchCriteria(x)} onResetFilter={(x) => setSearchCriteria(x)}></Filter>}
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        {communicationError && <ServerCommunicationError errType={communicationError} />}
        {apiData && (
          <>
            {hasMoreData && (
              <NotificationBox
                message={`Too many entries meets search criteria. Only ${apiData.length} rows displayed, please narrow search criteria`}
                type={NotificationBoxType.Warning}></NotificationBox>
            )}
            <PageGridDivStyled className="grid-container">
              <DataGrid
                className={classes.root}
                rows={prepareListData(apiData, rowSelectionList, listDataEdit)}
                columns={columns}
                density="compact"
                disableColumnReorder
                disableColumnSelector
                disableColumnResize
                disableSelectionOnClick
                disableMultipleSelection
                showColumnRightBorder={true}
                headerHeight={48}
                sortingOrder={["asc", "desc"]}
                pageSize={pageSize}
                rowsPerPageOptions={[20, 50, 100]}
                sortModel={sortOrder}
                isCellEditable={isCellEditable}
                onCellClick={onCellClicked}
                onEditCellChange={onEditCellChange}
                onEditCellChangeCommitted={onEditCellChangeCommitted}
                onCellKeyDown={onCellKeyDown}
                onSortModelChange={(x: any) => setSortOrder(x.sortModel)}
                onPageSizeChange={(x: any) => setPageSize(x.pageSize)}
                onError={logGridError}></DataGrid>
            </PageGridDivStyled>
          </>
        )}

        {infoAboutControlOpen && (
          <InfAboutControlDialog
            dialogPayload={selectedItem!}
            isOpen={infoAboutControlOpen}
            onCloseAction={() => setInfoAboutControlOpen(false)}
          />
        )}
        {officialInfoOpen && (
          <OfficialInfoDialog dialogPayload={selectedItem!} isOpen={officialInfoOpen} onCloseAction={() => setOfficialInfoOpen(false)} />
        )}
        {detailsOpen && (
          <OrderDetailsDialog dialogPayload={selectedItem!} isOpen={detailsOpen} onCloseAction={() => setDetailsOpen(false)} />
        )}
        {taskErrorsOpen && (
          <TaskErrorsDialog dialogPayload={selectedItem!} isOpen={taskErrorsOpen} onCloseAction={() => setTaskErrorsOpen(false)} />
        )}
        {sentHistoryOpen && (
          <SentHistoryDialog dialogPayload={selectedItem!} isOpen={sentHistoryOpen} onCloseAction={() => setSentHistoryOpen(false)} />
        )}
        {httpOperationError && (
          <ProcessItemsErrorDialog
            isOpen={!!httpOperationError}
            error={httpOperationError}
            onCloseAction={() => setHttpOperationError(undefined)}
          />
        )}
        {downloadErrorDialogOpen && (
          <DownloadErrorDialog statusCode={downloadErrorProps?.statusCode} errMessage={downloadErrorProps?.errMessage} isOpen={downloadErrorDialogOpen} onCloseAction={() => setDownloadErrorDialogOpen(false)} />
        )}
        <RowMenu
          anchorEl={menuAnchorEl}
          items={getRowMenuItems(selectedItem, RowType.Fuel)}
          isOpen={menuOpen}
          onCloseAction={onMenuClicked}
        />
      </PageContainerDivStyled>
    </>
  );
}
