import React from "react";
import API from "../api/api";
import { DataGrid, GridCellParams, GridColDef, GridSortModel, GridValueFormatterParams } from "@material-ui/data-grid";
import { compareKeys, getCommunicationErrorType, getHttpCallError, getKeyString, ItemKey } from "../api/common";
import Filter, { CustomFilter, mapCustomFilterToFilterCriteria } from "../common/Filter";
import Loader from "../common/Loader";
import NotificationBox, { NotificationBoxType } from "../common/Notification";
import PageTitle, { FilterMode } from "../common/PageTitle";
import { OrderList, OrderListType, OrdersToProcess, WebOrder } from "../api/models";
import { FuelDeliveryRowVm, LpgDeliveryRowVm, HttpCallError } from "../models/common";
import { logGridError } from "../api/errorReporter";
import ServerCommunicationError, { CommunicationErrorType } from "../common/ServerCommunicationIssue";
import { CellIsSelectedComponent } from "../common/gridCells/CellIsSelectedComponent";
import { CellDetailsComponent } from "../common/gridCells/CellDetailsComponent";
import { CellMenuComponent } from "../common/gridCells/CellMenuComponent";
import { CellHeaderCheckboxComponent } from "../common/gridHeaders/CellHeaderCheckboxComponent";
import { cellClass } from "../services/statusesRendering";
import { CellHeaderTooltipComponent } from "../common/gridHeaders/CellHeaderTooltipComponent";
import { CellOrderStatusComponent } from "../common/gridCells/CellOrderStatusComponent";
import { CellHeaderSentStatusComponent } from "../common/gridHeaders/CellHeaderSentStatusComponent";
import { CellSentStatusComponent } from "../common/gridCells/CellSentStatusComponent";
import { CellHeaderSentTaskStatusComponent } from "../common/gridHeaders/CellHeaderSentTaskStatusComponent";
import { CellSentTaskStatusComponent } from "../common/gridCells/CellSentTaskStatusComponent";
import { CellClickableComponent, ClickableCellType } from "../common/gridCells/CellClickableComponent";
import InfAboutControlDialog from "../common/dialogs/InfAboutControlDialog";
import OfficialInfoDialog from "../common/dialogs/OfficialInfoDialog";
import TaskErrorsDialog from "../common/dialogs/TaskErrorsDialog";
import SentHistoryDialog from "../common/dialogs/SentHistoryDialog";
import ProcessItemsErrorDialog from "../common/dialogs/ProcessItemsErrorDialog";
import RowMenu, { showTaskStatusErrorDialog, getRowMenuItems, MenuItemType } from "../common/RowMenu";
import OrderDetailsDialog from "../common/details/OrderDetailsDialog";
import { CellEditLpgsComponent } from "../common/gridCells/CellEditLpgsComponent";
import EditLpgDialog from "../common/dialogs/EditLpgDialog";
import { SpacerSize, SpacerVerticalDivStyled } from "../common/Spacer";
import { PageContainerDivStyled, PageGridDivStyled } from "./pageStyledComponents";
import { useGridStyles } from "../common/gridCustomTheme";
import { RowType } from "../services/gridDataEdit";
import { CellHeaderOrderStatusComponent } from "../common/gridHeaders/CellHeaderOrderStatusComponent";
import { dateToString } from "../common/formatHelper";
import { downloadFile, DownloadErrorDialog, DownloadErrorProps } from "../api/fileDownloader";

export enum LpgGridColumn {
  IsSelected = "isSelected",
  IsCancelled = "isCancelled",
  PlannedStartDate = "plannedStartDate",
  VehicleId = "vehicleId",
  TripId = "tripId",
  InfoAboutControl = "infoAboutControl",
  OfficialInfo = "officialInfo",
  OrderId = "orderId",
  MultipleSentNumber = "multipleSentNumber",
  SentNumber = "sentNumber",
  SenderKey = "senderKey",
  CarrierKey = "carrierKey",
  RecipientKey = "recipientKey",
  Edit = "edit",
  SentStatus = "sentStatus",
  SentTaskStatus = "sentTaskStatus",
  Details = "details",
  Menu = "menu",
}

export default function LpgDeliveriesPage() {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | Element>(null);

  const [isCustomFiltering, setIsCustomFiltering] = React.useState<boolean>(false);

  const [searchCriteria, setSearchCriteria] = React.useState<CustomFilter>();
  const [sortOrder, setSortOrder] = React.useState<GridSortModel>([{ field: LpgGridColumn.PlannedStartDate, sort: "desc" }]);
  const [pageSize, setPageSize] = React.useState<number>(50);

  const [isAllSelected, setisAllSelected] = React.useState(false);
  const [hasMoreData, setHasMoreData] = React.useState<boolean>();

  const [rowSelectionList, setRowSelectionList] = React.useState<ItemKey[]>([]);
  const showProcessButton = rowSelectionList.length > 0;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [communicationError, setCommunicationError] = React.useState<CommunicationErrorType>();
  const [apiData, setApiData] = React.useState<WebOrder[]>();

  const [selectedItem, setSelectedItem] = React.useState<WebOrder>();
  const [editData, setEditData] = React.useState<{ tripId: string; tripSourceId: string; vehicleId: string | null | undefined }>();
  const [infoAboutControlOpen, setInfoAboutControlOpen] = React.useState<boolean>(false);
  const [officialInfoOpen, setOfficialInfoOpen] = React.useState<boolean>(false);
  const [detailsOpen, setDetailsOpen] = React.useState<boolean>(false);
  const [sentHistoryOpen, setSentHistoryOpen] = React.useState<boolean>(false);
  const [taskErrorsOpen, setTaskErrorsOpen] = React.useState<boolean>(false);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const [processItemsError, setProcessItemsError] = React.useState<HttpCallError>();

  const [downloadErrorDialogOpen, setDownloadErrorDialogOpen] = React.useState<boolean>(false);
  const [downloadErrorProps, setDownloadErrorProps] = React.useState<DownloadErrorProps>({ statusCode: 200, errMessage: "" });

  const fetchGridData = React.useCallback((searchCriteria: CustomFilter | undefined) => {
    setLoading(true);
    setCommunicationError(undefined);
    setApiData(undefined);

    let request: Promise<any>;
    if (searchCriteria) {
      request = API.post(`/orderList/${OrderListType.LPG}`, {
        filter: mapCustomFilterToFilterCriteria(searchCriteria),
      });
    } else {
      request = API.get(`/orderList/${OrderListType.LPG}`);
    }

    return request
      .then((response) => {
        const respContent: OrderList = response.data;

        setisAllSelected(false);
        setApiData(respContent.orders || []);
        setRowSelectionList([]);
        setHasMoreData(respContent.hasMore);
      })
      .catch((err) => setCommunicationError(getCommunicationErrorType(err)))
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    fetchGridData(searchCriteria);
  }, [searchCriteria, fetchGridData]);

  const onIsSelectedChangedChanged = () => {
    if (!apiData) {
      return;
    }

    setisAllSelected((x) => {
      x = !x;
      if (x) {
        setRowSelectionList(apiData.filter((x) => x.isSelectionEnabled).map((x) => x));
      } else {
        setRowSelectionList([]);
      }
      return x;
    });
  };

  const onCellClicked = (param: GridCellParams, e: React.MouseEvent) => {
    const rowData: LpgDeliveryRowVm = param.row as LpgDeliveryRowVm;
    setSelectedItem(rowData);

    if (param.field === LpgGridColumn.IsSelected && rowData.isSelectionEnabled) {
      let rowSelection = rowSelectionList.find((x) => compareKeys(rowData, x));
      if (rowSelection) {
        setRowSelectionList((prev) => prev.filter((x) => !compareKeys(x, rowSelection!)));
      } else {
        setRowSelectionList((prev) => [...prev, rowData]);
      }
    } else if (param.field === LpgGridColumn.Menu) {
      setMenuOpen(true);
      setMenuAnchorEl(e.currentTarget);
    } else if (param.field === LpgGridColumn.InfoAboutControl) {
      if (rowData.hasInfoAboutControl) {
        setInfoAboutControlOpen(true);
      }
    } else if (param.field === LpgGridColumn.OfficialInfo) {
      if (rowData.hasOfficialInfo) {
        setOfficialInfoOpen(true);
      }
    } else if (param.field === LpgGridColumn.SentTaskStatus && showTaskStatusErrorDialog(rowData)) {
      setTaskErrorsOpen(true);
    }
  };

  const onMenuClicked = (item: MenuItemType | undefined) => {
    setMenuOpen(false);
    if (!item) {
      return;
    }

    switch (item) {
      case MenuItemType.SentHistory:
        setSentHistoryOpen(true);
        break;
      case MenuItemType.SentError:
        setTaskErrorsOpen(true);
        break;
      case MenuItemType.InformationAboutControl:
        setInfoAboutControlOpen(true);
        break;
      case MenuItemType.OfficialInfo:
        setInfoAboutControlOpen(true);
        break;
      case MenuItemType.EmergencyDownload:
        if (!selectedItem) {
          throw new Error("No item selected");
        }
        const emergencyDownloadUrl = `/EmergencyDownload/LPG/${selectedItem?.tripSourceId}/${selectedItem?.tripId}`;
        const documentName = `${selectedItem?.tripSourceId}-${selectedItem?.tripId}-SENT_120.XML`;
        const result = downloadFile(emergencyDownloadUrl, documentName);
        result.then(r => {
          if (!r.IsSuccess) {
            const dlErr: DownloadErrorProps = { statusCode: r.StatusCode ?? 500, errMessage: r.Message ?? "" }
            setDownloadErrorProps(dlErr);
            setDownloadErrorDialogOpen(true);
          }
        });
        break;
      default:
        throw new Error(`Invalid value: ${item} for MenuItemType`);
    }
  };

  const processSelectedItems = () => {
    setLoading(true);
    const ordersToProcess: OrdersToProcess[] = rowSelectionList.map((x) => {
      return {
        tripId: x.tripId,
        tripSourceId: x.tripSourceId,
        orderId: x.orderId,
      };
    });

    API.post("/ordersToProcess", ordersToProcess)
      .then(() => fetchGridData(searchCriteria))
      .catch((err: any) => setProcessItemsError(getHttpCallError(err)))
      .finally(() => setLoading(false));
  };

  const onFilterModeChanged = (mode: FilterMode) => {
    if (mode === FilterMode.Default) {
      setIsCustomFiltering(false);
      setSearchCriteria(undefined);
    } else {
      setIsCustomFiltering(true);
    }
  };

  const onDetailsClicked = React.useCallback((row: FuelDeliveryRowVm | LpgDeliveryRowVm) => {
    setSelectedItem(row as LpgDeliveryRowVm);
    setDetailsOpen(true);
  }, []);

  const onEditClicked = React.useCallback((row: FuelDeliveryRowVm | LpgDeliveryRowVm) => {
    if (row.isSentDataEditable) {
      setEditData({ tripId: row.tripId, tripSourceId: row.tripSourceId, vehicleId: row.vehicleId });
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: LpgGridColumn.IsSelected,
      headerName: "",
      width: 40,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: cellClass,
      renderHeader: () => <CellHeaderCheckboxComponent isAllSelected={isAllSelected} onCheckboxChange={onIsSelectedChangedChanged} />,
      renderCell: (params: GridCellParams) => {
        const rowData: LpgDeliveryRowVm = params.row as LpgDeliveryRowVm;
        return <CellIsSelectedComponent isSelected={rowData.isSelected} isSelectionEnabled={rowData.isSelectionEnabled} />;
      },
    },
    {
      field: LpgGridColumn.IsCancelled,
      width: 35,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: cellClass,
      renderHeader: () => <CellHeaderOrderStatusComponent />,
      renderCell: (params: GridCellParams) => {
        const rowData: LpgDeliveryRowVm = params.row as LpgDeliveryRowVm;
        return <CellOrderStatusComponent key={params.id} orderStatus={rowData.orderStatus} />;
      },
    },
    {
      field: LpgGridColumn.PlannedStartDate,
      headerName: "Planned start date",
      width: 130,
      type: "date",
      //valueFormatter: (params: GridValueFormatterParams) => new Date(params.value as Date).toJSON().substring(0, 10),
      valueFormatter: (params: GridValueFormatterParams) => dateToString(params.value as Date),
      disableColumnMenu: true,
      align: "center",
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.VehicleId,
      headerName: "Vehicle ID",
      width: 70,
      disableColumnMenu: true,
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.TripId,
      headerName: "Trip ID",
      width: 70,
      disableColumnMenu: true,
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.InfoAboutControl,
      width: 40,
      disableColumnMenu: true,
      editable: false,
      sortable: false,
      headerAlign: "center",
      renderHeader: () => <CellHeaderTooltipComponent tooltipText="Info about control" />,
      renderCell: (params: GridCellParams) => {
        const rowData: LpgDeliveryRowVm = params.row as LpgDeliveryRowVm;
        const cellType = rowData.hasInfoAboutControl ? ClickableCellType.InfoAboutControl : undefined;

        return <CellClickableComponent key={`${params.id}IAC`} type={cellType} />;
      },
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.OfficialInfo,
      width: 40,
      disableColumnMenu: true,
      editable: false,
      sortable: false,
      headerAlign: "center",
      renderHeader: () => <CellHeaderTooltipComponent tooltipText="Official info" />,
      renderCell: (params: GridCellParams) => {
        const rowData: LpgDeliveryRowVm = params.row as LpgDeliveryRowVm;
        const cellType = rowData.hasOfficialInfo ? ClickableCellType.OfficialInfo : undefined;

        return <CellClickableComponent key={`${params.id}OI`} type={cellType} />;
      },
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.OrderId,
      headerName: "Order ID",
      width: 80,
      disableColumnMenu: true,
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.MultipleSentNumber,
      headerName: "Multi SENT Number",
      width: 140,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.SentNumber,
      headerName: "SENT Number",
      width: 130,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.SenderKey,
      headerName: "Sender Key",
      width: 70,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.CarrierKey,
      headerName: "Carrier Key",
      width: 70,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.RecipientKey,
      headerName: "Receipient Key",
      width: 90,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.Edit,
      width: 66,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <CellHeaderTooltipComponent tooltipText="Press 'Edit' button to start editing" />,
      renderCell: (params: GridCellParams) => <CellEditLpgsComponent row={params.row as LpgDeliveryRowVm} onEditClicked={onEditClicked} />,
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.SentStatus,
      width: 35,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      renderHeader: () => <CellHeaderSentStatusComponent />,
      renderCell: (params: GridCellParams) => {
        const rowData: LpgDeliveryRowVm = params.row as LpgDeliveryRowVm;
        return <CellSentStatusComponent key={params.id} sentStatus={rowData.sentStatus} closedIncompliant={rowData.closedIncompliant} />;
      },
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.SentTaskStatus,
      width: 35,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      renderHeader: () => <CellHeaderSentTaskStatusComponent />,
      renderCell: (params: GridCellParams) => {
        const rowData: LpgDeliveryRowVm = params.row as LpgDeliveryRowVm;
        return (
          <CellSentTaskStatusComponent
            key={params.id}
            sentTaskStatus={rowData.sentTaskStatus}
            showTaskStatusDialog={showTaskStatusErrorDialog(rowData)}
          />
        );
      },
      cellClassName: cellClass,
    },
    {
      field: LpgGridColumn.Details,
      width: 75,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => <>&nbsp;</>,
      renderCell: (params: GridCellParams) => (
        <CellDetailsComponent row={params.row as LpgDeliveryRowVm} key={params.id} onDetailsCliked={onDetailsClicked} />
      ),
    },
    {
      field: LpgGridColumn.Menu,
      width: 25,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => <>&nbsp;</>,
      renderCell: () => <CellMenuComponent />,
    },
  ];

  function prepareListData(data: WebOrder[]): LpgDeliveryRowVm[] {
    return data.map((row) => {
      const rowSelection = rowSelectionList.find((x) => compareKeys(row, x));

      return {
        ...row,
        id: getKeyString(row),
        isSelected: !!rowSelection || false,
      };
    });
  }

  function isCellEditable(params: GridCellParams) {
    const rowData = params.row as LpgDeliveryRowVm;
    return rowData.isSentDataEditable === true;
  }

  const classes = useGridStyles();

  return (
    <>
      <Loader isLoading={loading}></Loader>
      <PageContainerDivStyled>
        <PageTitle
          title="Lpg deliveries"
          showProcessButton={showProcessButton}
          onFilteringStatusChanged={onFilterModeChanged}
          onRefreshButtonCliecked={() => fetchGridData(searchCriteria)}
          onProcessButtonClickConfirmed={processSelectedItems}></PageTitle>
        {isCustomFiltering && <Filter onApplyFilter={(x) => setSearchCriteria(x)} onResetFilter={(x) => setSearchCriteria(x)}></Filter>}
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        {communicationError && <ServerCommunicationError errType={communicationError} />}
        {apiData && (
          <>
            {hasMoreData && (
              <NotificationBox
                message={`Too many entries meets search criteria. Only ${apiData.length} rows displayed, please narrow search criteria`}
                type={NotificationBoxType.Warning}></NotificationBox>
            )}
            <PageGridDivStyled className="grid-container">
              <DataGrid
                className={classes.root}
                rows={prepareListData(apiData)}
                columns={columns}
                density="compact"
                disableColumnReorder
                disableColumnSelector
                disableColumnResize
                disableSelectionOnClick
                disableMultipleSelection
                showColumnRightBorder={true}
                headerHeight={48}
                sortingOrder={["asc", "desc"]}
                pageSize={pageSize}
                rowsPerPageOptions={[20, 50, 100]}
                sortModel={sortOrder}
                isCellEditable={isCellEditable}
                onCellClick={onCellClicked}
                onSortModelChange={(x: any) => setSortOrder(x.sortModel)}
                onPageSizeChange={(x: any) => setPageSize(x.pageSize)}
                onError={logGridError}></DataGrid>
            </PageGridDivStyled>
          </>
        )}

        {infoAboutControlOpen && (
          <InfAboutControlDialog
            dialogPayload={selectedItem!}
            isOpen={infoAboutControlOpen}
            onCloseAction={() => setInfoAboutControlOpen(false)}
          />
        )}
        {officialInfoOpen && (
          <OfficialInfoDialog dialogPayload={selectedItem!} isOpen={officialInfoOpen} onCloseAction={() => setOfficialInfoOpen(false)} />
        )}
        {detailsOpen && (
          <OrderDetailsDialog dialogPayload={selectedItem!} isOpen={detailsOpen} onCloseAction={() => setDetailsOpen(false)} />
        )}
        {taskErrorsOpen && (
          <TaskErrorsDialog dialogPayload={selectedItem!} isOpen={taskErrorsOpen} onCloseAction={() => setTaskErrorsOpen(false)} />
        )}
        {sentHistoryOpen && (
          <SentHistoryDialog dialogPayload={selectedItem!} isOpen={sentHistoryOpen} onCloseAction={() => setSentHistoryOpen(false)} />
        )}
        {processItemsError && (
          <ProcessItemsErrorDialog
            isOpen={!!processItemsError}
            error={processItemsError}
            onCloseAction={() => setProcessItemsError(undefined)}
          />
        )}
        {editData && (
          <EditLpgDialog
            isOpen={!!editData}
            tripId={editData.tripId}
            tripSourceId={editData.tripSourceId}
            vehicleId={editData.vehicleId}
            onCloseAction={(isSaved) => {
              setEditData(undefined);
              if (isSaved) {
                fetchGridData(searchCriteria);
              }
            }}
          />
        )}
        {downloadErrorDialogOpen && (
          <DownloadErrorDialog statusCode={downloadErrorProps?.statusCode} errMessage={downloadErrorProps?.errMessage} isOpen={downloadErrorDialogOpen} onCloseAction={() => setDownloadErrorDialogOpen(false)} />
        )}
        <RowMenu
          anchorEl={menuAnchorEl}
          items={getRowMenuItems(selectedItem, RowType.Lpg)}
          isOpen={menuOpen}
          onCloseAction={onMenuClicked}
        />
      </PageContainerDivStyled>
    </>
  );
}
