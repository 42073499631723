import React from "react";
import { ShipToInfo } from "../../../api/models";
import { addressLine1, addressLine2, latLonToString } from "../../formatHelper";
import { MasterDataMissing } from "./MasterDataMissing";
import { SectionHeaderDivStyled, SectionPaperStyled } from "./sectionsStyledComponents";
import { SectionAddressValue, SectionValue } from "./SectionValue";

export function ShipToSection({ shipTo }: { shipTo: ShipToInfo | undefined }) {
  return (
    <SectionPaperStyled elevation={4}>
      <SectionHeaderDivStyled>Place of Delivery</SectionHeaderDivStyled>
      {!shipTo && <MasterDataMissing />}
      {shipTo && (
        <>
          <SectionValue label="ID" value={shipTo.id?.toString()}></SectionValue>
          <SectionAddressValue
            label="Address"
            addressLine1={addressLine1(shipTo.street, shipTo.houseNumber)}
            addressLine2={addressLine2(shipTo.postalCode, shipTo.city, undefined, shipTo.district)}></SectionAddressValue>
          <SectionValue label="Email" value={shipTo.email}></SectionValue>
          <SectionValue label="Lat/Lon" value={latLonToString(shipTo.lat, shipTo.lon)}></SectionValue>
        </>
      )}
    </SectionPaperStyled>
  );
}
