import { TextField } from "@material-ui/core";

import { OfficialInfoItem } from "../../api/models";
import { dateWithTimeToString } from "../formatHelper";
import { SpacerHorizontalDivStyled, SpacerSize, SpacerVerticalDivStyled } from "../Spacer";
import { FlexDivStyled } from "../styledComponents";
import GridDialogItemContainer from "./GridDialogItemContainer";

interface OfficialInfoDialogProps {
  item: OfficialInfoItem;
  isOpen: boolean;
  onCloseAction: () => void;
}

export default function OfficialInfoItemDialog({ item, isOpen, onCloseAction }: OfficialInfoDialogProps) {
  return (
    <GridDialogItemContainer isOpen={isOpen} onCloseAction={onCloseAction}>
      <div>
        <FlexDivStyled>
          <TextField value={dateWithTimeToString(item.timestamp)} fullWidth disabled label="Date" variant="filled" size="small" />
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium }} />
          <TextField value={item.infoType} fullWidth disabled label="Info type" variant="filled" size="small" />
        </FlexDivStyled>
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        <TextField value={item.infoRecipient} fullWidth disabled label="Info receipient" variant="filled" size="small" />
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        <TextField label="Info name" fullWidth disabled value={item.infoName} multiline contentEditable={false} variant="filled" />
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        <TextField label="Info value" fullWidth disabled value={item.infoValue} multiline contentEditable={false} variant="filled" />
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        <TextField
          label="Info description"
          fullWidth
          disabled
          value={item.infoDescription}
          multiline
          contentEditable={false}
          rows={10}
          variant="filled"
        />
      </div>
    </GridDialogItemContainer>
  );
}
