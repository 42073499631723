import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";

class ConfirmationDialogProps {
  public isOpen: boolean = false;
  public headerText?: string = "Do You want to continue ?";
  public messageText?: string;
  public cancellButtonText?: string = "Cancel";
  public continueButtonText?: string = "Continue";
  public onCloseAction: (isConfirmed: boolean) => void = null!;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const headerText = props.headerText || "Do You want to continue ?";
  const cancellButtonText = props.cancellButtonText || "Cancel";
  const continueButtonText = props.continueButtonText || "Continue";
  const messageText = props.messageText || `Press '${continueButtonText}' to start the operation.`;

  const onCloseAction = props.onCloseAction;

  return (
    <Dialog fullWidth maxWidth="xs" open={props.isOpen} onClose={() => onCloseAction(false)}>
      <DialogTitle disableTypography>
        <h3>{headerText}</h3>
      </DialogTitle>
      {!!messageText && (
        <DialogContent>
          <DialogContentText>{messageText}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={() => onCloseAction(false)} variant="outlined" color="secondary">
          {cancellButtonText}
        </Button>
        <Button onClick={() => onCloseAction(true)} variant="contained" color="primary" autoFocus>
          {continueButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
