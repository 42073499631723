import API from "../api/api";
import React from "react";
import { AutomationStatus } from "../api/models";
import styled from "styled-components";
import { appColors } from "./colors";
import { useIsAuthenticated } from "@azure/msal-react";
//import { checkIfUserInGroup, FUEL_LPG_AD_GROUP_ID } from "./secrityUtils";

const ContainerDivStyled = styled.div`
  min-width: 260px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: white;
`;

const TitleDivStyled = styled.div`
  background-color: ${appColors.red};
  padding: 10px;
  margin-left: 5px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  white-space: nowrap;
`;

const PulseOnDivStyled = styled.div`
  background-color: ${appColors.green};
  width: 30px;
  padding: 10px;
  margin-right: 5px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  text-align: center;
  white-space: nowrap;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse-on 2s normal;

  @keyframes pulse-on {
    0% {
      box-shadow: 0 0 0 0 rgb(67, 176, 42, 0.7);
    }

    70% {
      box-shadow: 0 0 0 15px rgb(67, 176, 42, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgb(67, 176, 42, 0);
    }
  }
`;

const PulseOffDivStyled = styled.div`
  background-color: ${appColors.grey};
  width: 30px;
  padding: 10px;
  margin-right: 5px;
  text-align: center;
  white-space: nowrap;
  padding: 10px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse-off 2s normal;

  @keyframes pulse-off {
    0% {
      box-shadow: 0 0 0 0 rgb(128, 128, 128, 0.7);
    }

    70% {
      box-shadow: 0 0 0 15px rgb(128, 128, 128, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgb(128, 128, 128, 0);
    }
  }
`;

const CommChannelDivStyled = styled.div`
background-color: ${appColors.green};
/*width: 30px;*/
padding: 10px;
margin-right: 5px;
border-top-right-radius: 15px;
border-bottom-right-radius: 15px;
text-align: center;
white-space: nowrap;
box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
transform: scale(1);
animation: pulse-on 2s normal;

@keyframes pulse-on {
  0% {
    box-shadow: 0 0 0 0 rgb(67, 176, 42, 0.7);
  }

  70% {
    box-shadow: 0 0 0 15px rgb(67, 176, 42, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(67, 176, 42, 0);
  }
}
`;

export default function SentAutomationStatus() {
  const [sentAutomation, setSentAutomation] = React.useState<AutomationStatus>();
  const isUserAuthenticated = useIsAuthenticated();

  React.useEffect(() => {
    if (isUserAuthenticated) {
      checkAutomationStatus();

      const oneMinute = 60 * 1000;
      const interval = setInterval(() => checkAutomationStatus(), oneMinute);
      return () => clearInterval(interval);
    }
  }, [isUserAuthenticated]);

  function checkAutomationStatus() {
    // OBSOLETE - no need to check user role; it is enough to be authenticated
    // const isInFuelLpgGroup = checkIfUserInGroup(FUEL_LPG_AD_GROUP_ID);

    // if (isInFuelLpgGroup) {
    //   setSentAutomation(undefined);
    //   API.get(`/automationStatus`).then((response) => setSentAutomation(response.data as AutomationStatus));
    // }
    setSentAutomation(undefined);
    API.get(`/automationStatus`).then((response) => setSentAutomation(response.data as AutomationStatus));
  }

  return (
    <>
      {sentAutomation && (
        <ContainerDivStyled>
          <TitleDivStyled>SENT Automation:</TitleDivStyled>
          {sentAutomation.automationEnabled && <PulseOnDivStyled>ON</PulseOnDivStyled>}
          {!sentAutomation.automationEnabled && <PulseOffDivStyled>OFF</PulseOffDivStyled>}
          <TitleDivStyled>PUESC Channel:</TitleDivStyled>
          <CommChannelDivStyled>{sentAutomation.communicationChannel}</CommChannelDivStyled>
        </ContainerDivStyled>
      )}
    </>
  );
}
