import React from "react";
import { VehicleInfo } from "../../../api/models";
import { MasterDataMissing } from "./MasterDataMissing";
import { SectionHeaderDivStyled, SectionPaperStyled, SectionRowContainer } from "./sectionsStyledComponents";
import { SectionValue } from "./SectionValue";

export function VehicleSection({ vehicle }: { vehicle: VehicleInfo | undefined }) {
  const labelWidthLeft = 80;
  const labelWidthRight = 85;
  const valueWidth = 92;
  return (
    <SectionPaperStyled elevation={4}>
      <SectionHeaderDivStyled>Vehicle</SectionHeaderDivStyled>
      {!vehicle && <MasterDataMissing />}
      {vehicle && (
        <>
          <SectionValue label="ID" value={vehicle.id?.toString()} labelWidth={labelWidthLeft} valueWidth={valueWidth}></SectionValue>
          <SectionRowContainer>
            <SectionValue label="Permit road" value={vehicle.permitRoad} labelWidth={labelWidthLeft} valueWidth={valueWidth}></SectionValue>
            <SectionValue
              label="Carrier ID"
              value={vehicle.carrierId?.toString()}
              labelWidth={labelWidthRight}
              valueWidth={valueWidth}></SectionValue>
          </SectionRowContainer>
          <SectionRowContainer>
            <SectionValue label="Geolocator No" value={vehicle.geoLocNo} labelWidth={labelWidthLeft} valueWidth={valueWidth}></SectionValue>
            <SectionValue
              label="Failover geolocator No"
              value={vehicle.failOverGeoLocNo}
              labelWidth={labelWidthRight}
              valueWidth={valueWidth}></SectionValue>
          </SectionRowContainer>
          <SectionRowContainer>
            <SectionValue label="Truck No" value={vehicle.truckNumber} labelWidth={labelWidthLeft} valueWidth={valueWidth}></SectionValue>
            <SectionValue
              label="Trailer No"
              value={vehicle.trailerNumber}
              labelWidth={labelWidthRight}
              valueWidth={valueWidth}></SectionValue>
          </SectionRowContainer>
        </>
      )}
    </SectionPaperStyled>
  );
}
