import React from "react";
import { CustomerInfo } from "../../../api/models";
import { addressLine1, addressLine2 } from "../../formatHelper";
import { MasterDataMissing } from "./MasterDataMissing";
import { SectionHeaderDivStyled, SectionPaperStyled, SectionRowContainer } from "./sectionsStyledComponents";
import { SectionAddressValue, SectionValue } from "./SectionValue";

export function CustomerSection({ customer }: { customer: CustomerInfo | undefined }) {
  const valueWidth = 110;
  return (
    <SectionPaperStyled elevation={4}>
      <SectionHeaderDivStyled>Customer</SectionHeaderDivStyled>
      {!customer && <MasterDataMissing />}
      {customer && (
        <>
          <SectionRowContainer>
            <SectionValue label="ID" value={customer.id?.toString()} valueWidth={valueWidth}></SectionValue>
            <SectionValue label="NIP" value={customer.idNumber} valueWidth={valueWidth}></SectionValue>
          </SectionRowContainer>
          <SectionValue label="Name" value={customer.name}></SectionValue>
          <SectionAddressValue
            label="Address"
            addressLine1={addressLine1(customer.street, customer.houseNumber)}
            addressLine2={addressLine2(customer.postalCode, customer.city, customer.country)}></SectionAddressValue>
        </>
      )}
    </SectionPaperStyled>
  );
}
