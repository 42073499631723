import React from "react";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FilterMultiSelect, { OptionItem } from "./FilterMultiSelect";
import { FilterCriteria, OrderStatus, SentStatus, SentTaskStatus } from "../api/models";
import {
  getOrderStatusText,
  getSentStatusText,
  getSentTaskStatusText,
  orderStatusesOrdered,
  orderStatusIconRenderer,
  sentStatusesOrdered,
  sentStatusIconRenderer,
  sentTaskStatusesOrdered,
  sentTaskStatusIconRenderer,
} from "../services/statusesRendering";
import styled from "styled-components";
import { SpacerHorizontalDivStyled, SpacerSize, SpacerVerticalDivStyled } from "./Spacer";
import { appColors } from "./colors";

export enum OrderType {
  VMIOrders = "VMIOrders",
  NonVMIOrders = "NonVMIOrders",
}

const allOrderTypes = Object.values(OrderType);
const allSentStatus = Object.values(SentStatus);
const allSentTaskStatuses = Object.values(SentTaskStatus);
const allOrderStatuses = Object.values(OrderStatus);

export function mapCustomFilterToFilterCriteria(filter: CustomFilter | undefined): FilterCriteria | undefined {
  if (!filter) {
    return;
  }

  let orderVmi = undefined;
  if (filter.orderTypes.length === 1) {
    if (filter.orderTypes[0] === OrderType.VMIOrders) {
      orderVmi = true;
    } else if (filter.orderTypes[0] === OrderType.NonVMIOrders) {
      orderVmi = false;
    }
  }

  return {
    dateFrom: filter.dateFrom?.toISOString() || undefined,
    dateTo: filter.dateTo?.toISOString() || undefined,
    orderId: filter.orderId,
    tripId: filter.tripId,
    sentNumber: filter.sentNumber,
    vehicleId: filter.vehicleId,
    sentStatus: filter.sentStatus,
    sentTaskStatus: filter.sentTaskStatus,
    orderStatus: filter.orderStatus,
    orderVmi: orderVmi,
  };
}

export interface CustomFilter {
  orderTypes: OrderType[];
  sentStatus: SentStatus[];
  sentTaskStatus: SentTaskStatus[];
  orderStatus: OrderStatus[];
  dateFrom?: Date | null;
  dateTo?: Date | null;
  vehicleId?: string;
  tripId?: string;
  sentNumber?: string;
  orderId?: string;
}

export const defaultFilter: CustomFilter = {
  orderTypes: allOrderTypes,
  sentStatus: allSentStatus,
  sentTaskStatus: allSentTaskStatuses,
  orderStatus: allOrderStatuses,
  dateFrom: new Date(),
  dateTo: null,
};

interface FilterProps {
  onApplyFilter: (filter: CustomFilter) => void;
  onResetFilter: (filter: CustomFilter) => void;
}

const ContainerDivStyled = styled.div`
  display: flex;
  background-color: ${appColors.white};
  padding: 0px 10px 10px 10px;
  justify-content: flex-end;
  align-items: flex-start;
`;

const ContainerInputsDivStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
`;

const ContainerIputsRowDivStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
`;

const ContainerButtonsDivStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
`;

export default function Filter({ onApplyFilter, onResetFilter }: FilterProps) {
  const [orderTypes, setOrderTypes] = React.useState(defaultFilter.orderTypes);
  const [sentTaskStatuses, setSentTaskStatuses] = React.useState(defaultFilter.sentTaskStatus);
  const [sentStatuses, setSentStatuses] = React.useState(defaultFilter.sentStatus);
  const [orderStatuses, setOrderStatuses] = React.useState(defaultFilter.orderStatus);
  const [dateFrom, setDateFrom] = React.useState(defaultFilter.dateFrom);
  const [dateTo, setDateTo] = React.useState(defaultFilter.dateTo);
  const [vehicleId, setVehicleId] = React.useState(defaultFilter.vehicleId || "");
  const [tripId, setTripId] = React.useState(defaultFilter.tripId || "");
  const [orderId, setOrderId] = React.useState(defaultFilter.orderId || "");
  const [sentNumber, setSentNumber] = React.useState(defaultFilter.orderId || "");
  const [sentNumberHasFocus, setSentNumberHasFocus] = React.useState(false);

  const applyFilter = () => {
    const filter: CustomFilter = {
      orderTypes: orderTypes,
      sentTaskStatus: sentTaskStatuses,
      sentStatus: sentStatuses,
      orderStatus: orderStatuses,
      dateFrom: dateFrom,
      dateTo: dateTo,
      vehicleId: vehicleId,
      tripId: tripId,
      sentNumber: sentNumber ? `SENT${sentNumber}` : undefined,
      orderId: orderId,
    };

    onApplyFilter(filter);
  };

  const resetFilter = () => {
    setOrderTypes(defaultFilter.orderTypes);
    setSentTaskStatuses(defaultFilter.sentTaskStatus);
    setSentStatuses(defaultFilter.sentStatus);
    setOrderStatuses(defaultFilter.orderStatus);
    setDateFrom(defaultFilter.dateFrom);
    setDateTo(defaultFilter.dateTo);
    setVehicleId(defaultFilter?.vehicleId || "");
    setTripId(defaultFilter?.tripId || "");
    setOrderId(defaultFilter?.orderId || "");
    setSentNumber(defaultFilter?.sentNumber || "");

    onResetFilter(Object.assign({}, defaultFilter));
  };

  function getOrderTypeOptions(): OptionItem[] {
    var enumValues = Object.values(OrderType);

    return enumValues.map((x) => {
      return {
        id: x.toString(),
        value: x.toString(),
        text: getOrderTypeText(x),
      };
    });
  }

  function getOrderTypeText(x: OrderType | string): string {
    switch (x) {
      case OrderType.NonVMIOrders:
        return "Non VMI orders";
      case OrderType.VMIOrders:
        return "VMI orders";
      default:
        return x;
    }
  }

  function getSentTaskStatusOptions(): OptionItem[] {
    return sentTaskStatusesOrdered.map((x) => {
      return {
        id: x.toString(),
        value: x.toString(),
        legend: sentTaskStatusIconRenderer(x, 20),
        text: getSentTaskStatusText(x),
      };
    });
  }

  function getItemSentStatusOptions(): OptionItem[] {
    return sentStatusesOrdered.map((x) => {
      return {
        id: x.toString(),
        value: x.toString(),
        legend: sentStatusIconRenderer(x, undefined, 20),
        text: getSentStatusText(x, undefined),
      };
    });
  }

  function getItemOrderStatusOptions(): OptionItem[] {
    return orderStatusesOrdered.map((x) => {
      return {
        id: x.toString(),
        value: x.toString(),
        legend: orderStatusIconRenderer(x, 18),
        text: getOrderStatusText(x),
      };
    });
  }

  function onSelectedOrderTypeChanged(values: string[]) {
    setOrderTypes(
      values.map((x) => {
        return x as OrderType;
      })
    );
  }

  function onSelectedSentTaskStatusChanged(values: string[]) {
    setSentTaskStatuses(
      values.map((x) => {
        return x as SentTaskStatus;
      })
    );
  }

  function onSelectedSentStatusChanged(values: string[]) {
    setSentStatuses(
      values.map((x) => {
        return x as SentStatus;
      })
    );
  }

  function onOrderStatusChanged(values: string[]) {
    setOrderStatuses(
      values.map((x) => {
        return x as OrderStatus;
      })
    );
  }

  function processSentNumber(sentNumber: string) {
    setSentNumber(sentNumber.replace("SENT", ""));
  }

  const sentNumberAdorner =
    sentNumber || sentNumberHasFocus ? { startAdornment: <InputAdornment position="start">SENT</InputAdornment> } : {};

  return (
    <ContainerDivStyled>
      <ContainerInputsDivStyled>
        <ContainerIputsRowDivStyle>
          <FilterMultiSelect
            options={getItemOrderStatusOptions()}
            inputLabel="Order status"
            selectedValues={orderStatuses || []}
            valueToTextSelector={(x) => getOrderStatusText(x as OrderStatus)}
            onSelectedValueChanged={onOrderStatusChanged}
            width={170}></FilterMultiSelect>
          <FilterMultiSelect
            options={getItemSentStatusOptions()}
            inputLabel="Sent status"
            selectedValues={sentStatuses || []}
            valueToTextSelector={(x) => getSentStatusText(x as SentStatus, undefined)}
            onSelectedValueChanged={onSelectedSentStatusChanged}
            width={480}></FilterMultiSelect>
          <FilterMultiSelect
            options={getSentTaskStatusOptions()}
            inputLabel="Sent task status"
            selectedValues={sentTaskStatuses || []}
            valueToTextSelector={getSentTaskStatusText}
            onSelectedValueChanged={onSelectedSentTaskStatusChanged}
            width={265}></FilterMultiSelect>
          <FilterMultiSelect
            options={getOrderTypeOptions()}
            inputLabel="Order type"
            selectedValues={orderTypes}
            valueToTextSelector={getOrderTypeText}
            onSelectedValueChanged={onSelectedOrderTypeChanged}
            width={110}></FilterMultiSelect>
        </ContainerIputsRowDivStyle>
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        <ContainerIputsRowDivStyle>
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium, width: 200 }}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              variant="inline"
              size="small"
              //format="MM/dd/yyyy"
              format="yyyy-MM-dd"
              margin="none"
              label="Planned start date from"
              inputVariant="outlined"
              value={dateFrom}
              //onChange={(date) => setDateFrom(date || undefined)}
              //oonChange={(date) => setDateFrom(new Date(date?.setHours(0, 0, 0, 0) ?? 0) || undefined)}
              onChange={(date) => setDateFrom(new Date(Date.UTC(date?.getFullYear() ?? 0, date?.getMonth() ?? 0, date?.getDate() ?? 0)) || undefined)}
            />
          </SpacerHorizontalDivStyled>
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium, width: 200 }}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              variant="inline"
              size="small"
              //format="MM/dd/yyyy"
              format="yyyy-MM-dd"
              margin="none"
              label="Planned start date to"
              inputVariant="outlined"
              value={dateTo}
              //onChange={(date) => setDateTo(date || undefined)}
              //onChange={(date) => setDateTo(new Date(date?.setHours(0, 0, 0, 0) ?? 0) || undefined)}
              onChange={(date) => setDateTo(new Date(Date.UTC(date?.getFullYear() ?? 0, date?.getMonth() ?? 0, date?.getDate() ?? 0)) || undefined)}
            />
          </SpacerHorizontalDivStyled>
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium, width: 120 }}>
            <TextField
              fullWidth
              onChange={(e) => setVehicleId(e.target.value)}
              value={vehicleId}
              label="Vehicle ID"
              variant="outlined"
              size="small"
            />
          </SpacerHorizontalDivStyled>
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium, width: 120 }}>
            <TextField
              fullWidth
              onChange={(e) => setTripId(e.target.value)}
              value={tripId}
              label="Trip ID"
              variant="outlined"
              size="small"
            />
          </SpacerHorizontalDivStyled>
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium, width: 120 }}>
            <TextField
              fullWidth
              onChange={(e) => setOrderId(e.target.value)}
              value={orderId}
              label="Order ID"
              variant="outlined"
              size="small"
            />
          </SpacerHorizontalDivStyled>
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium, width: 170 }}>
            <TextField
              fullWidth
              onChange={(e) => processSentNumber(e.target.value)}
              onFocus={() => setSentNumberHasFocus(true)}
              onBlur={() => setSentNumberHasFocus(false)}
              value={sentNumber}
              label="SENT Number"
              variant="outlined"
              size="small"
              InputProps={sentNumberAdorner}
            />
          </SpacerHorizontalDivStyled>
        </ContainerIputsRowDivStyle>
      </ContainerInputsDivStyled>

      <ContainerButtonsDivStyle>
        <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium, width: 150 }}>
          <Button fullWidth variant="contained" color="primary" size="large" onClick={applyFilter}>
            Apply filter
          </Button>
          <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
          <Button variant="outlined" fullWidth color="secondary" size="large" onClick={resetFilter}>
            Reset
          </Button>
        </SpacerHorizontalDivStyled>
      </ContainerButtonsDivStyle>
    </ContainerDivStyled>
  );
}
