//import createMuiTheme, { Theme } from "@material-ui/core/styles/createMuiTheme";
import { createMuiTheme, Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/styles";

const defaultTheme = createMuiTheme();

export const useGridStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        "& .MuiDataGrid-cellEditing": {
          backgroundColor: "rgb(237, 139, 0, 0.3)",
        },
        "& .MuiDataGrid-cell": {
          padding: "0 2px",
        },
        "& .MuiDataGrid-columnsContainer": {
          backgroundColor: "#ffd100",
          "& .MuiDataGrid-colCell": {
            padding: "0 5px",
          },
        },
      },
    }),
  { defaultTheme }
);
