import clsx from "clsx";
import React, { ReactElement } from "react";
import { DataEditMode } from "../../models/common";
import { RowType } from "../../services/gridDataEdit";
import { appColors } from "../colors";
import GridHeaderTooltip from "../gridTooltips/GridHeaderTooltip";
import { IconIStyled } from "../styledComponents";
import { EditModeCellContainerDivStyled } from "./styledComponents";

interface CellEditModeComponentProps {
  isRowEditable: boolean | undefined;
  editMode: DataEditMode | undefined;
  valiadationErrorMessage: string[];
  size: number;
  rowType: RowType;
}

export function CellEditModeComponent({ isRowEditable, editMode, valiadationErrorMessage, size, rowType }: CellEditModeComponentProps) {
  return React.useMemo(() => {
    const hasErrorInRow = valiadationErrorMessage.length > 0;
    let iconClassName = "";
    if (rowType === RowType.Fuel) {
      iconClassName = isRowEditable ? "fas fa-lock-open" : "fas fa-lock";
    }

    let iconColor: string;
    if (isRowEditable) {
      iconColor = hasErrorInRow ? appColors.red : appColors.green;
    } else {
      iconColor = appColors.grey;
    }

    switch (editMode) {
      case DataEditMode.InSync:
        iconClassName = "fas fa-check";
        break;
      case DataEditMode.HasPendingEdit:
        iconClassName = "fas fas fa-edit";
        break;
      case DataEditMode.SavingInProgress:
        iconClassName = "fas fa-spin fa-spinner";
        iconColor = appColors.orange;
        break;
      case DataEditMode.SavingError:
        iconClassName = "fas fa-exclamation-triangle";
        iconColor = appColors.red;
        break;
    }

    const failedTaskCell = clsx(editMode === DataEditMode.SavingError ? "clickable-cell" : "");

    let tooltipContent: ReactElement | undefined = undefined;
    if (valiadationErrorMessage.length > 0) {
      tooltipContent = (
        <>
          <ul>
            {valiadationErrorMessage.map((x) => (
              <li key={x}>{x}</li>
            ))}
          </ul>
        </>
      );
    }

    const fontSize = Math.floor(size / 1.7);
    const styledProps = { fontSize: fontSize };
    return (
      <EditModeCellContainerDivStyled {...styledProps} className={failedTaskCell}>
        {tooltipContent && (
          <GridHeaderTooltip tooltipContent={tooltipContent}>
            <IconIStyled className={iconClassName} {...{ color: iconColor }} />
          </GridHeaderTooltip>
        )}
        {!tooltipContent && <IconIStyled className={iconClassName} {...{ color: iconColor }} />}
      </EditModeCellContainerDivStyled>
    );
  }, [editMode, valiadationErrorMessage, isRowEditable, size, rowType]);
}
