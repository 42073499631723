import { msalInstance } from "../App";

export const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;
export const APP_VERSION = process.env.REACT_APP_BACKEND_APP_VERSION;

export const FUEL_LPG_AD_GROUP_ID = process.env.REACT_APP_AZURE_AD_FUEL_LPG_GROUP_ID!;
export const AZURE_AD_SETTINGS = {
  clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID!,
  authority: process.env.REACT_APP_AZURE_AD_AUTHORITY!,
  postLogoutRedirectUri: process.env.REACT_APP_AZURE_AD_POST_LOGOUT_REDIRECT!,
  azureAdApiScopes: process.env.REACT_APP_AZURE_AD_API_SCOPE!,
};

export function getLoggedInUser(throwIfNotFound: boolean = true) {
  var accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    if (throwIfNotFound) {
      throw Error("Requesting data from api requires user to be signed in");
    }

    return;
  }

  return accounts[0];
}

export function checkIfUserInGroup(groupId: string) {
  var account = getLoggedInUser(false);
  if (!account) {
    return false;
  }

  const userRoles: string[] = (account.idTokenClaims as any).roles || [];
  return !!userRoles.find((x) => x === groupId);
}



/**
 * Checks current user's mebership in application role(s)
 * @param role - comma-separated list of application roles
 * @returns true if user is a member of any of the specified roles
 */
export function isUserInRole(role: string) {
  var account = getLoggedInUser(false);
  if (!account || role === undefined || !role) { return false };

  const testedRoles: string[] = role.split(',').map(x => x.trim().toUpperCase()).filter(x => x);
  const userRoles: string[] = (account.idTokenClaims as any).roles?.map((x: string) => x.trim().toUpperCase());

  let isInRole = userRoles?.some(u => testedRoles.includes(u));

  return isInRole;
}

