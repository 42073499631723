import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import { HttpCallError } from "../../models/common";
import NotificationBox, { NotificationBoxType } from "../Notification";

interface ProcessItemsErrorDialogProps {
  isOpen: boolean;
  error: HttpCallError;
  onCloseAction: () => void;
}

export default function ProcessItemsErrorDialog({ isOpen, error, onCloseAction }: ProcessItemsErrorDialogProps) {
  const message = error.statusCode ? `Operation failed - server response with statusCode: ${error.statusCode}` : "Operation failed";
  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={() => onCloseAction()}>
      <DialogTitle disableTypography>
        <NotificationBox type={NotificationBoxType.Error} message={message}></NotificationBox>
      </DialogTitle>

      <DialogContent>
        <div style={{ overflow: "auto" }}>
          <pre>{JSON.stringify(error.errorDetails, null, 2)}</pre>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onCloseAction()} variant="outlined" color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
