import axios from "axios";
import { parseISO } from "date-fns";
import { msalInstance } from "../App";
import { apiScopes } from "../authConfig";
import { BACKEND_URL, getLoggedInUser } from "../common/secrityUtils";

const baseAxios = axios.create({
  baseURL: BACKEND_URL,
});

// baseAxios.defaults.headers = {
//   "Cache-Control": "no-cache",
//   Pragma: "no-cache",
//   Expires: "0",
// };

baseAxios.interceptors.request.use((originalRequest: any) => {
  const account = getLoggedInUser();

  return msalInstance.acquireTokenSilent({ scopes: apiScopes, account: account }).then((tokenResponse) => {
    originalRequest.headers = { Authorization: `Bearer ${tokenResponse.accessToken}` };

    return Promise.resolve(originalRequest);
  });
});

baseAxios.interceptors.response.use((originalResponse: any) => {
  handleDates(originalResponse.data);
  return originalResponse;
});

const dateFormatWithTimeZone = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d{0,}\+\d{2}:\d{2}$/;
const dateFormatWithoutTimeZone = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d{0,}$/;

function isDateString(value: any): boolean {
  const isDate = value && typeof value === "string" && (dateFormatWithoutTimeZone.test(value) || dateFormatWithTimeZone.test(value));
  return isDate;
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object") return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isDateString(value)) {
      body[key] = parseISO(value);
    } else if (typeof value === "object") {
      handleDates(value);
    }
  }
}

export default baseAxios;
