import React from "react";
import API from "./api";
import { AxiosError } from "axios";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"


export interface DownloadFileResult {
  IsSuccess: boolean,
  StatusCode: number | undefined,
  Message: string | undefined | null
}

export interface DownloadErrorDialogProps {
  statusCode: number;
  errMessage: string;
  isOpen: boolean;
  onCloseAction: () => void;
}

export interface DownloadErrorProps {
  statusCode: number;
  errMessage: string;
}

export async function downloadFile(uri: string, fileName: string | undefined | null): Promise<DownloadFileResult> {
  let result: DownloadFileResult;
  try {
    const response = await API.get(uri, { responseType: "blob" });
    const docBlob = new Blob([response.data], { type: "text/xml" });
    const url = window.URL.createObjectURL(docBlob);
    const tmpLink = document.createElement("a");
    tmpLink.href = url;
    tmpLink.rel = "noopener noreferrer";
    tmpLink.download = fileName ?? "ViSENT-document.download";
    document.body.appendChild(tmpLink);
    tmpLink.click();
    document.body.removeChild(tmpLink);
    window.URL.revokeObjectURL(url);
    result = { IsSuccess: true, StatusCode: response.status, Message: null };
  }
  catch (error) {
    const err = error as AxiosError<any, any>;
    const errBlob = new Blob([err.response?.data], { type: err.response?.data.type });
    let errMsg = await errBlob.text();
    if (errMsg === undefined) { errMsg = "Uncaught error" };
    console.log('Error message:\n', errMsg);
    result = { IsSuccess: false, StatusCode: err.response?.status ?? 500, Message: errMsg };
  }
  return result;
}


export function DownloadErrorDialog({ statusCode, errMessage, isOpen, onCloseAction }: DownloadErrorDialogProps) {
  return (
    <Dialog open={isOpen} onClose={onCloseAction}>
      <DialogTitle style={{ padding: "16px" }}>File Downloading Error ({statusCode})</DialogTitle>
      <DialogContent style={{ padding: "16px" }}>{errMessage}</DialogContent>
      <DialogActions>
        <Button onClick={onCloseAction}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
