import React from "react";
import { getSentTaskStatusText, sentTaskStatusesOrdered, sentTaskStatusIconRenderer, tooltipRow } from "../../services/statusesRendering";
import GridHeaderIcon from "../gridTooltips/GridHeaderIcon";
import GridHeaderTooltip from "../gridTooltips/GridHeaderTooltip";
import { TooltipHeaderDivStyled } from "./styledComponents";

export function CellHeaderSentTaskStatusComponent() {
  return (
    <GridHeaderTooltip
      tooltipContent={
        <div>
          <TooltipHeaderDivStyled>Sent task status</TooltipHeaderDivStyled>
          {sentTaskStatusesOrdered.map((x) => {
            return <React.Fragment key={x}>{tooltipRow(sentTaskStatusIconRenderer(x), getSentTaskStatusText(x))}</React.Fragment>;
          })}
        </div>
      }>
      <GridHeaderIcon></GridHeaderIcon>
    </GridHeaderTooltip>
  );
}
