import { GuardFunctionRouteProps, GuardToRoute, Next } from "react-router-guards/dist/types";
import { MissingRightsToAccess, AnyUserRoutes } from "../routeUtils";

import { msalInstance } from "../../App";

export default function anyUserGuard(to: GuardToRoute, _: GuardFunctionRouteProps | null, next: Next): void {
    const supportedRoutes = Object.values(AnyUserRoutes);
    const allAccounts = msalInstance.getAllAccounts();
    const userSignedIn = Array.isArray(allAccounts) && allAccounts.length > 0;

    if (supportedRoutes.find((x) => x === to.history.location.pathname)) {
        if (!userSignedIn) {
            next.redirect(MissingRightsToAccess);
            return;
        }
    }

    next();
    return;
}
