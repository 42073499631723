import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../api/api";
import ServerCommunicationError, { CommunicationErrorType } from "../../common/ServerCommunicationIssue";
import { AppUser } from "../../api/models";
import { DataGrid, GridColDef, GridRowData, GridCellParams } from "@material-ui/data-grid";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { Button, Icon, IconButton } from "@material-ui/core";
import { PageContainerDivStyled, PageGridDivStyled } from "../pageStyledComponents";
import { useGridStyles } from "../../common/gridCustomTheme";
import Loader from "../../common/Loader";
import "./admin.css";
import { AdminRoutes } from "../../routes/routeUtils";



export default function UsersPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [communicationError, setCommunicationError] = useState<CommunicationErrorType>();
  const [apiData, setApiData] = useState<AppUser[]>();
  const [pageSize, setPageSize] = useState<number>(20);

  const [deletion, setDeletion] = useState<any>({ showDialog: false, upn: undefined });
  const [deleting, setDeleting] = useState<boolean>(false);
  const [delError, setDelError] = useState<any>({ showDialog: false, error: undefined });

  const getAppUsers = useCallback(() => {
    setLoading(true);
    setCommunicationError(undefined);
    setApiData(undefined);

    let request: Promise<any>;
    request = API.get("/Users/App")

    return request
      .then(response => {
        const respContent: AppUser[] = response.data;
        //setLoading(false);
        setApiData(respContent);
      })
      .catch(err => setCommunicationError(err))
      .finally(() => setLoading(false))
  }, []);

  useEffect(() => { getAppUsers(); }, [getAppUsers]);


  // DELETE User
  const handleUserDelete = () => {
    setDeletion({ showDialog: false, upn: undefined });
    setDeleting(true);
    setDelError({ showDialog: false, error: undefined });

    console.log(`Deleting user: ${deletion?.upn}`);

    API.delete(`/Users/${deletion?.upn}`)
      .then(resp => {
        console.log(resp);
        getAppUsers();
      })
      .catch(err => {
        console.log("'err.response':", err.response);
        console.log("Response status: ", err.response.status);
        console.log(err.response.data.errors);
        setDelError({ showDialog: true, error: err.response });
      })
      .finally(() => { setDeleting(false); console.log("Deletion complete") });
  };




  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "User Principal Name",
      width: 254,
      editable: false
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 120,
      editable: false
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 120,
      editable: false

    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      width: 254,
      editable: false
    },
    {
      field: "action",
      headerName: "Action",
      width: 140,
      disableColumnMenu: true,
      hideSortIcons: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <IconButton title="Delete" onClick={() => setDeletion({ showDialog: true, upn: params.id })}>
              <Icon className="fas fa-trash-alt" />
            </IconButton>

            <Link to={AdminRoutes.UserEdit.replace(":upn", params.id as string)} title="Edit" tabIndex={-1}>
              <IconButton>
                <Icon className="fas fa-edit"></Icon>
              </IconButton>
            </Link>

            <Link to={AdminRoutes.Planner.replace(":upn", params.id as string)} title="Planner Assignment" tabIndex={-2}>
              <IconButton>
                <Icon className="fas fa-route"></Icon>
              </IconButton>
            </Link>
          </>
        );
      }
    }
  ];

  const rows: GridRowData[] = apiData?.map(u => ({
    id: u.userPrincipalName,
    firstName: u.firstName,
    lastName: u.lastName,
    emailAddress: u.emailAddress
  })) ?? [];


  const classes = useGridStyles();
  return (
    <>
      <Loader isLoading={loading || deleting} />
      <PageContainerDivStyled style={{ width: "912px", margin: "auto" }}>
        <div className="actions">
          <h3 className="actions-left">Application Users</h3>
          <div className="actions-right">
            {!communicationError &&
              <Link to={AdminRoutes.UserAdd} className="link-button">
                <Button title="Add User" color="primary" tabIndex={-1}><Icon className="fas fa-plus" />New User</Button>
              </Link>
            }
          </div>
        </div>
        {communicationError && <ServerCommunicationError errType={communicationError} />}
        {apiData &&
          <PageGridDivStyled className="grid-container">
            <DataGrid
              className={classes.root}
              pagination
              columns={columns}
              rows={rows}
              density="compact"
              showColumnRightBorder={true}
              showCellRightBorder={false}
              pageSize={pageSize}
              onPageSizeChange={(ps) => { setPageSize(ps.pageSize) }}
              rowsPerPageOptions={[20, 50, 100]}
              disableSelectionOnClick={true}
            />
          </PageGridDivStyled>
        }

        <Dialog id="ask-for-deletion" className="visent" open={deletion?.showDialog}>
          <DialogTitle>User Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete user '{deletion?.upn}'?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setDeletion({ showDialog: false, upn: undefined })}>Cancel</Button>
            <Button color="primary" onClick={handleUserDelete}>OK</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="deletion-error" className="visent" open={delError?.showDialog}>
          <DialogTitle>User Deletion Error</DialogTitle>
          <DialogContent>
            {delError?.error?.status && <DialogContentText>Response status: {delError?.error?.status}</DialogContentText>}
            <DialogContentText>Could not delete user</DialogContentText>
            {delError?.error?.data?.errors && <DialogContentText>{delError?.error?.data?.errors}</DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDelError({ ...delError, showDialog: false })}>Close</Button>
          </DialogActions>
        </Dialog>

      </PageContainerDivStyled>
    </>
  );
}


