import React from "react";
import GridHeaderIcon from "../gridTooltips/GridHeaderIcon";
import GridHeaderTooltip from "../gridTooltips/GridHeaderTooltip";

export function CellHeaderTooltipComponent({ tooltipText }: { tooltipText: string }) {
  return (
    <GridHeaderTooltip tooltipContent={tooltipText}>
      <GridHeaderIcon></GridHeaderIcon>
    </GridHeaderTooltip>
  );
}
