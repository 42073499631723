import React, { SyntheticEvent, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AdminRoutes } from "../../routes/routeUtils";
import API from "../../api/api";
import ServerCommunicationError, { CommunicationErrorType } from "../../common/ServerCommunicationIssue";
import { Button, Icon, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { PageContainerDivStyled } from "../pageStyledComponents";
import { validateIdNumber, validatePostalCode } from "../../services/miscellaneous";
import Loader from "../../common/Loader";
import { VWebSupplier, SupplierKit, IdTypeSimple } from "../../api/models";


export default function SupplierEdit() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [supplierEdited, setSupplierEdited] = useState<VWebSupplier>();
  const [supplierLoaded, setSupplierLoaded] = useState<VWebSupplier>();
  const [supplierLoading, setSupplierLoading] = useState<boolean>(false);
  const [supplierError, setSupplierError] = useState<CommunicationErrorType>();
  const [idTypes, setIdTypes] = useState<IdTypeSimple[]>();

  const [supplierSaving, setSupplierSaving] = useState<boolean>(false);
  const [supplierSaveError, setSupplierSaveError] = useState<any>();
  const [supplierSaveSuccess, setSupplierSaveSuccess] = useState<boolean>();
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [noChangesDialogOpen, setNoChangesDialogOpen] = useState<boolean>(false);

  const [anyChange, setAnyChange] = useState<boolean>(false);
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState(false);
  const [isPostalCodeValid, setIsPostalCodeValid] = useState<boolean>(true);
  const [isIdNumberValid, setIsIdNumberValid] = useState<boolean>(true);


  useEffect(() => {
    setSupplierLoading(true);
    setSupplierError(undefined);

    console.log("Retrieving supplier kit...");
    let req: Promise<any>;
    req = API.get(`/Suppliers/SupplierKit/${id || '0'}`);
    req.then(resp => {
      let respContent: SupplierKit = resp.data;
      let tmpSupplier: VWebSupplier = {
        id: NaN,
        name: '',
        idType: '',
        idNumber: '',
        street: '',
        houseNo: '',
        city: '',
        postalCode: '',
        country: 'PL',
        email: '',
        own: false,
        sentCreator: false,
        sent120Sender: false,
        canBeOwn: false,
        canSent120: false
      };

      if (id && respContent.supplier) tmpSupplier = respContent?.supplier!;

      setSupplierLoaded(tmpSupplier);
      setSupplierEdited(tmpSupplier);
      setIdTypes(respContent?.idTypes || []);
    })
      .catch(err => setSupplierError(err))
      .finally(() => setSupplierLoading(false))
  }, [id]);


  useEffect(() => {
    // Discover changes
    let hasChanged: boolean =
      Number.isFinite(supplierLoaded?.id) !== Number.isFinite(supplierEdited?.id) ||
      supplierLoaded?.id !== supplierEdited?.id ||
      supplierLoaded?.name !== supplierEdited?.name ||
      supplierLoaded?.idType !== supplierEdited?.idType ||
      supplierLoaded?.idNumber !== supplierEdited?.idNumber ||
      supplierLoaded?.street !== supplierEdited?.street ||
      supplierLoaded?.houseNo !== supplierEdited?.houseNo ||
      supplierLoaded?.city !== supplierEdited?.city ||
      supplierLoaded?.postalCode !== supplierEdited?.postalCode ||
      supplierLoaded?.country !== supplierEdited?.country ||
      supplierLoaded?.email !== supplierEdited?.email ||
      supplierLoaded?.own !== supplierEdited?.own ||
      supplierLoaded?.sentCreator !== supplierEdited?.sentCreator ||
      supplierLoaded?.sent120Sender !== supplierEdited?.sent120Sender;

    // Validate postal code
    let isCodeOk: boolean = validatePostalCode(supplierEdited?.postalCode, supplierEdited?.country);

    // Validate ID number
    let isIdNumberOk: boolean = validateIdNumber(supplierEdited?.idType, supplierEdited?.idNumber);

    // Validate form data
    let isAllOk: boolean = true;
    if (!Number.isFinite(supplierEdited?.id)) isAllOk = false;
    if (!supplierEdited?.name?.trim()) isAllOk = false;
    if (!supplierEdited?.idType?.trim()) isAllOk = false;
    if (!validateIdNumber(supplierEdited?.idType, supplierEdited?.idNumber, false)) isAllOk = false;
    if (!supplierEdited?.street?.trim()) isAllOk = false;
    // if (!supplierEdited?.houseNo?.trim()) isAllOk = false;
    if (!supplierEdited?.city?.trim()) isAllOk = false;
    if (!validatePostalCode(supplierEdited?.postalCode, supplierEdited?.country, false)) isAllOk = false;
    if (!supplierEdited?.country?.trim()) isAllOk = false;

    // Memorize state
    setIsAllValid(isAllOk);
    setAnyChange(hasChanged);
    setIsPostalCodeValid(isCodeOk);
    setIsIdNumberValid(isIdNumberOk);

    console.log(`isAllOk: ${isAllOk}\nhasChanged: ${hasChanged}\nisCodeOk: ${isCodeOk}\nisIdNumberOk: ${isIdNumberOk}`);
    if (hasChanged) {
      console.log("Loaded:", supplierLoaded);
      console.log("Edited:", supplierEdited);
    }
  }, [supplierEdited, supplierLoaded]);


  // Submit data
  function handleSubmit(event: SyntheticEvent | null) {
    event?.preventDefault();

    console.log("Initial Supplier:", supplierLoaded);
    console.log("Modified Supplier:", supplierEdited);
    console.log("Posting to API...")

    setSupplierSaving(true);
    setSupplierSaveError(undefined);
    let request: Promise<any>;
    if (id) {
      request = API.put("/Suppliers", supplierEdited);
    }
    else {
      request = API.post("/Suppliers", supplierEdited);
    }

    request
      .then(resp => {
        console.log("Successfully posted");
        console.log(resp);
        setSupplierSaveSuccess(true);
      })
      .catch(err => {
        setSupplierSaveError(err.response);
        console.log("Ooops! An error occurred.")
        console.log(err.response);
        console.log("Errors:", err.response?.data?.errors);
        setErrorDialogOpen(true);
      })
      .finally(() => setSupplierSaving(false));

  };


  // Close without saving
  const handleClose = (confirmToClose: boolean = true) => {
    if (confirmToClose && anyChange && isAllValid)
      setConfirmCloseDialogOpen(true);
    else
      history.push(AdminRoutes.Suppliers);
  }


  // Redirect to 'Suppliers' after successful save
  useEffect(() => { if (supplierSaveSuccess) history.push(AdminRoutes.Suppliers) }, [history, supplierSaveSuccess]);

  return (
    <>
      <Loader isLoading={supplierSaving || supplierLoading} />

      <PageContainerDivStyled >

        <div className="edit-main" style={{ width: "500px" }}>
          <div className="edit-header">{id ? "Edit Supplier" : "Add Supplier"}</div>
          <div className="edit-contents">

            {!supplierError &&
              <form onSubmit={handleSubmit}>

                <div style={{ display: "flex", justifyContent: "space-between" }}>

                  <div style={{ width: "6em" }}>
                    <label htmlFor="supplierId">Supplier ID</label>
                    {!id && <input type="number" id="supplierId" name="supplierId" className="visent" style={{ width: "5em"}} value={supplierEdited?.id || NaN} required onChange={(e) => setSupplierEdited({ ...supplierEdited, id: parseInt(e.target.value) } as VWebSupplier)} />}
                    {id && <input type="number" id="supplierId" name="supplierId" className="visent" style={{ width: "5em"}} defaultValue={id} required disabled />}
                  </div>

                  <div style={{ width: "26em" }}>
                    <label htmlFor="supplierName">Name</label>
                    <input type="text" id="supplierName" name="supplierName" className="visent" style={{ width: "25em" }} value={supplierEdited?.name || ''} required onChange={(e) => setSupplierEdited({ ...supplierEdited, name: e.target.value } as VWebSupplier)} />
                  </div>

                </div>


                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "22em" }}>
                    <label htmlFor="idType">ID Type</label>
                    <select id="idType" name="idType" className="visent" style={{ width: "21em" }} required value={supplierEdited?.idType?.toString()} onChange={(e) => setSupplierEdited({ ...supplierEdited, idType: e.target.value } as VWebSupplier)}>
                      <option key="-" defaultValue="-">--pick ID type--</option>
                      {idTypes?.map((s) => (<option key={s.typeId?.toString()} value={s.typeId?.toString()} >{`${s.typeId} - ${s.typeName}`}</option>))}
                    </select>
                  </div>

                  <div style={{ width: "10em" }}>
                    <label htmlFor="idNumber">ID Number</label>
                    <input type="text" id="idNumber" name="idNumber" className={isIdNumberValid ? "visent" : "visent edit-input-error"} style={{ maxWidth: "9em" }} required value={supplierEdited?.idNumber || ''} onChange={(e) => setSupplierEdited({ ...supplierEdited, idNumber: e.target.value })} />
                  </div>
                </div>


                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "22em" }}>
                    <label htmlFor="street">Street</label>
                    <input type="text" id="street" name="street" className="visent" style={{ width: "21em" }} required value={supplierEdited?.street || ''} onChange={(e) => setSupplierEdited({ ...supplierEdited, street: e.target.value } as VWebSupplier)} />
                  </div>

                  <div style={{ width: "10em" }}>
                    <label htmlFor="houseNo">House Number</label>
                    <input type="text" id="houseNo" name="houseNo" className="visent" style={{ maxWidth: "9em" }} value={supplierEdited?.houseNo || ''} onChange={(e) => setSupplierEdited({ ...supplierEdited, houseNo: e.target.value } as VWebSupplier)} />
                  </div>
                </div>


                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ maxWidth: "7em" }}>
                    <label htmlFor="postalCode">Postal Code</label>
                    <input type="text" id="postalCode" name="postalCode" className={isPostalCodeValid ? "visent" : "visent edit-input-error"} style={{ maxWidth: "6em" }} value={supplierEdited?.postalCode || ''} required onChange={(e) => setSupplierEdited({ ...supplierEdited, postalCode: e.target.value } as VWebSupplier)} />
                  </div>

                  <div style={{ width: "14em" }}>
                    <label htmlFor="city">City</label>
                    <input type="text" id="city" name="city" className="visent" style={{ width: "13em" }} value={supplierEdited?.city || ''} required onChange={(e) => setSupplierEdited({ ...supplierEdited, city: e.target.value })} />
                  </div>

                  <div style={{ width: "10em" }}>
                    <label htmlFor="country">Country</label>
                    <select id="country" name="country" className="visent" style={{ maxWidth: "11em" }} value={supplierEdited?.country || ''} required onChange={(e) => setSupplierEdited({ ...supplierEdited, country: e.target.value })}>
                      <option value="" >--</option>
                      <option value="PL" >PL - Poland</option>
                      <option value="IE" >IE - Ireland</option>
                      <option value="CH" >CH - Switzerland</option>
                      <option value="LT" >LT - Lithuania</option>
                      <option value="LV" >LV - Latvia</option>
                      <option value="DE" >DE - Germany</option>
                      <option value="SK" >SK - Slovakia</option>
                      <option value="CZ" >CZ - Czech Rep.</option>
                      <option value="RU" >RU - Russia</option>
                      <option value="BY" >BY - Belarus</option>
                    </select>
                  </div>
                </div>


                <label htmlFor="email">Email</label>
                <input type="mail" id="email" name="email" className="visent" value={supplierEdited?.email || ''} onChange={(e) => setSupplierEdited({ ...supplierEdited, email: e.target.value })} />


                <div className="edit-inline-checkboxes">
                  <div>
                    <input type="checkbox" id="own" name="own" className="visent" disabled={!supplierEdited?.canBeOwn} checked={supplierEdited?.own || false} onChange={(e) => setSupplierEdited({ ...supplierEdited, own: e.target.checked })} />
                    <label htmlFor="own">Own</label>
                  </div>

                  <div>
                    <input type="checkbox" id="sentCreator" name="sentCreator" className="visent" checked={supplierEdited?.sentCreator || false} onChange={(e) => setSupplierEdited({ ...supplierEdited, sentCreator: e.target.checked })} />
                    <label htmlFor="sentCreator">SENT Creator</label>
                  </div>

                  <div>
                    <input type="checkbox" id="sent120Sender" name="sent120Sender" className="visent" disabled={!supplierEdited?.canSent120} checked={supplierEdited?.sent120Sender || false} onChange={(e) => setSupplierEdited({ ...supplierEdited, sent120Sender: e.target.checked })} />
                    <label htmlFor="own">SENT_120 Sender</label>
                  </div>

                </div>

                <div className="edit-form-buttons-bar">

                  <Button style={{ width: "100px" }} color="secondary" onClick={() => handleClose(true)}>
                    <Icon className="fas fa-undo" />
                    Cancel
                  </Button>

                  <Button type="submit" color="primary" style={{ width: "100px" }} disabled={!isAllValid || !anyChange}>
                    <Icon className="fas fa-save" />
                    Save
                  </Button>

                </div>
              </form>
            }

            {supplierError && <ServerCommunicationError errType={supplierError} />}

          </div>

        </div>

        <Dialog id="dialog-error-save" className="visent" open={errorDialogOpen} >
          <DialogTitle>Data Save Error</DialogTitle>
          <DialogContent>
            {supplierSaveError?.status && <DialogContentText>Response status: {supplierSaveError?.status}</DialogContentText>}
            <DialogContentText>Could not save the data.</DialogContentText>
            {supplierSaveError?.data?.errors &&
              <DialogContentText>
                {typeof (supplierSaveError?.data?.errors) === "string" ? supplierSaveError?.data?.errors : JSON.stringify(supplierSaveError?.data?.errors)}
              </DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setErrorDialogOpen(false)} color="primary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="dialog-no-chnages" className="visent" open={noChangesDialogOpen} >
          <DialogTitle>No Changes</DialogTitle>
          <DialogContent>
            <DialogContentText>No changes were discovered. No need to save data.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNoChangesDialogOpen(false)} color="secondary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="dialog-confirm-close" className="visent" open={confirmCloseDialogOpen} >
          <DialogTitle>Not Saved Changes</DialogTitle>
          <DialogContent>
            <DialogContentText>There are changes that have not been saved.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleSubmit(null)} color="primary" autoFocus>Save & Close</Button>
            <Button onClick={() => setConfirmCloseDialogOpen(false)} color="secondary" autoFocus>Back</Button>
            <Button onClick={() => handleClose(false)} color="secondary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

      </PageContainerDivStyled>
    </>
  );
}
