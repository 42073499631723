import React from "react";
import API from "../../api/api";

import { DialogPayload, getCommunicationErrorType } from "../../api/common";
import { prepareDialogGridData } from "../../services/dialogGrid";
import { DataGrid, GridColDef, GridRowParams } from "@material-ui/data-grid";
import GridDialogContainer from "./GridDialogContainer";
import { OfficialInfo, OfficialInfoItem } from "../../api/models";
import OfficialInfoItemDialog from "./OfficialInfoItemDialog";
import { logGridError } from "../../api/errorReporter";
import ServerCommunicationError, { CommunicationErrorType } from "../ServerCommunicationIssue";
import { useGridStyles } from "../gridCustomTheme";

interface OfficialInfoDialogProps {
  dialogPayload: DialogPayload;
  isOpen: boolean;
  onCloseAction: () => void;
}

export default function OfficialInfoDialog({ dialogPayload, isOpen, onCloseAction }: OfficialInfoDialogProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [communicationError, setCommunicationError] = React.useState<CommunicationErrorType>();
  const [officialInfo, setOfficialInfo] = React.useState<OfficialInfo>();

  const [isDetailsOpen, setIsDetailsOpen] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<OfficialInfoItem>();

  React.useEffect(() => {
    setOfficialInfo(undefined);
    setCommunicationError(undefined);
    setLoading(true);

    API.get(`/officialInfo/${dialogPayload.tripSourceId}/${dialogPayload.tripId}`)
      .then((response: any) => setOfficialInfo(response.data as OfficialInfo))
      .catch((err: any) => setCommunicationError(getCommunicationErrorType(err)))
      .finally(() => setLoading(false));
  }, [dialogPayload]);

  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: "Date",
      width: 120,
      type: "dateTime",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "infoType",
      headerName: "Info type",
      width: 70,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "infoRecipient",
      width: 100,
      headerName: "Info receipient",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "infoName",
      flex: 2,
      headerName: "Info name",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "infoValue",
      flex: 2,
      headerName: "Info value",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "infoDescription",
      flex: 3,
      headerName: "Info description",
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  const onRowClicked = (param: GridRowParams) => {
    var rowData = param.row as any as OfficialInfoItem;
    setSelectedItem(rowData);
    setIsDetailsOpen(true);
  };

  const classes = useGridStyles();

  return (
    <>
      <GridDialogContainer
        title="Official info"
        maxWidth="lg"
        dialogPayload={dialogPayload}
        isOpen={isOpen}
        loading={loading}
        onCloseAction={onCloseAction}>
        <>
          {communicationError && <ServerCommunicationError errType={communicationError} />}
          {officialInfo && (
            <DataGrid
              className={classes.root}
              rows={prepareDialogGridData(officialInfo.officialInfos)}
              columns={columns}
              density="compact"
              disableColumnReorder
              disableSelectionOnClick
              disableColumnResize
              headerHeight={48}
              pageSize={50}
              rowsPerPageOptions={[]}
              onRowClick={onRowClicked}
              onError={logGridError}></DataGrid>
          )}
        </>
      </GridDialogContainer>
      {selectedItem && (
        <OfficialInfoItemDialog
          item={selectedItem}
          isOpen={isDetailsOpen}
          onCloseAction={() => setIsDetailsOpen(false)}></OfficialInfoItemDialog>
      )}
    </>
  );
}
