import { GuardFunctionRouteProps, GuardToRoute, Next } from "react-router-guards/dist/types";
//import { checkIfUserInGroup, FUEL_LPG_AD_GROUP_ID } from "../../common/secrityUtils";
import { isUserInRole } from "../../common/secrityUtils";
import { MissingRightsToAccess, LpgFuelRoutes } from "../routeUtils";

export default function fuelLpgGuard(to: GuardToRoute, _: GuardFunctionRouteProps | null, next: Next): void {
  const supportedRoutes = Object.values(LpgFuelRoutes);

  // OBSOLETE - based on Azure AD group membership
  // if (supportedRoutes.find((x) => x === to.history.location.pathname)) {
  //   const isInFuelLpgGroup = checkIfUserInGroup(FUEL_LPG_AD_GROUP_ID);
  //   if (!isInFuelLpgGroup) {
  //     next.redirect(MissingRightsToAccess);
  //     return;
  //   }
  // }


  if (supportedRoutes.find((x) => x === to.history.location.pathname)) {
    if (!isUserInRole("Planner")) {
      next.redirect(MissingRightsToAccess);
      return;
    }
  }

  next();
  return;
}
