import React from "react";
import { OrderStatus } from "../../api/models";
import { orderStatusIconRenderer } from "../../services/statusesRendering";
import { CellOuterDivStyled } from "./styledComponents";

export function CellOrderStatusComponent({ orderStatus }: { orderStatus: OrderStatus | undefined }) {
  return React.useMemo(() => {
    return <CellOuterDivStyled>{orderStatusIconRenderer(orderStatus)}</CellOuterDivStyled>;
  }, [orderStatus]);
}
