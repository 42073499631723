import React from "react";
import styled from "styled-components";
import NotificationBox, { NotificationBoxType } from "../common/Notification";

const NoAccessDivStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default function AcessForbidenPage() {
  return (
    <NoAccessDivStyled>
      <NotificationBox
        type={NotificationBoxType.Error}
        message="You don't have access to requested page - please contact administrator"></NotificationBox>
    </NoAccessDivStyled>
  );
}
