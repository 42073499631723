import styled from "styled-components";
import { appColors } from "./colors";
import { APP_VERSION } from "./secrityUtils";

const FooterDivStyled = styled.div`
  min-width: 900px;
  font-size: 12px;
  background: #fff;
  transition-duration: 0.4s;
  border-bottom: 1px solid ${appColors.gray} !important;
  bottom: 5px;
  left: 0;
  right: 0;
  padding: 10px 20px;
  position: fixed;
  z-index: 999;
`;

export default function Footer() {
  return (
    <FooterDivStyled>
      <strong>Copyright</strong> Circle K © {new Date().getFullYear()} - Version {APP_VERSION}
    </FooterDivStyled>
  );
}
