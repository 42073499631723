import { GridCellParams } from "@material-ui/data-grid";
import React from "react";
import styled from "styled-components";
import { OrderStatus, SentStatus, SentTaskStatus } from "../api/models";
import { appColors } from "../common/colors";
import { SentStatusClosedIncompliantContainerDivStyled } from "../common/gridCells/styledComponents";
import { IconIStyled, StatusIconDivStyled } from "../common/styledComponents";
import { FuelDeliveryRowVm, LpgDeliveryRowVm } from "../models/common";

export const sentStatusesOrdered: Array<SentStatus> = [
  SentStatus.None,
  SentStatus.Registered,
  SentStatus.Ready,
  SentStatus.Closed,
  SentStatus.Cancelled,
  SentStatus.Expired,
  SentStatus.Emergency
];

export const orderStatusesOrdered: Array<OrderStatus> = [OrderStatus.Planned, OrderStatus.Closed, OrderStatus.Cancelled];

export const sentTaskStatusesOrdered: Array<SentTaskStatus> = [SentTaskStatus.None, SentTaskStatus.Enqueued, SentTaskStatus.Failed];

const TooltipRowDivStyled = styled.div`
  display: flex;
  margin-top: 5px;
  align-items: center;

  div {
    flex-shrink: 1;
  }
  span {
    margin: 0 5px;
  }
`;

export function tooltipRow(icon: React.ReactElement, desc: string, width?: number) {
  return (
    <TooltipRowDivStyled>
      <div style={{ display: "flex", justifyContent: "center", width: width ? `${width}px` : undefined }}>{icon}</div>
      <span>-</span>
      {desc}
    </TooltipRowDivStyled>
  );
}

export function sentStatusIconRenderer(
  sentStatus: SentStatus | undefined,
  closedIncompliant: boolean | undefined,
  size: number = 20
): React.ReactElement {
  let iconColor = "";
  switch (sentStatus) {
    case SentStatus.None: {
      iconColor = appColors.white;
      break;
    }
    case SentStatus.Registered: {
      iconColor = appColors.orange;
      break;
    }
    case SentStatus.Ready: {
      iconColor = appColors.green;
      break;
    }
    case SentStatus.Closed: {
      iconColor = appColors.blue;
      break;
    }
    case SentStatus.Expired: {
      iconColor = appColors.lightgrey;
      break;
    }
    case SentStatus.Cancelled: {
      iconColor = appColors.grey;
      break;
    }
    case SentStatus.Emergency: {
      iconColor = appColors.red;
      break;
    }
  }

  if (sentStatus === SentStatus.Emergency) {
    return (
      <SentStatusClosedIncompliantContainerDivStyled {...{ iconColor: iconColor, size: size }}>
        <IconIStyled className="fas fa-exclamation" />
      </SentStatusClosedIncompliantContainerDivStyled>
    );
  }

  if (sentStatus === SentStatus.Closed && closedIncompliant) {
    return (
      <SentStatusClosedIncompliantContainerDivStyled {...{ iconColor: iconColor, size: size }}>
        <IconIStyled className="fas fa-info" />
      </SentStatusClosedIncompliantContainerDivStyled>
    );
  }

  return <StatusIconDivStyled {...{ size: size, iconColor: iconColor }}></StatusIconDivStyled>;
}

export function getSentStatusText(sentStatus: SentStatus | string | undefined, closedIncompliant: boolean | undefined) {
  switch (sentStatus) {
    case SentStatus.None: {
      return "Imported to ViSENT but not yet reported to SENT";
    }
    case SentStatus.Registered: {
      return "SENT Registered";
    }
    case SentStatus.Ready: {
      return "SENT Ready (for Transportation)";
    }
    case SentStatus.Closed: {
      return closedIncompliant ? "SENT Closed with Incompliance" : "SENT Closed";
    }
    case SentStatus.Cancelled: {
      return "SENT Cancelled";
    }
    case SentStatus.Expired: {
      return "SENT Expired (Closed by the System)";
    }
    case SentStatus.Emergency: {
      return "PUESC Emergency"
    }
  }

  return "";
}

export function sentTaskStatusIconRenderer(sentTaskStatus: SentTaskStatus | undefined, size: number = 20): React.ReactElement {
  let iconColor = "";
  switch (sentTaskStatus) {
    case SentTaskStatus.None: {
      iconColor = appColors.white;
      break;
    }
    case SentTaskStatus.Enqueued: {
      iconColor = appColors.orange;
      break;
    }
    case SentTaskStatus.Failed: {
      iconColor = appColors.red;
      break;
    }
  }

  return <StatusIconDivStyled {...{ size: size, iconColor: iconColor }}></StatusIconDivStyled>;
}

export function getSentTaskStatusText(sentTaskStatus: SentTaskStatus | string) {
  switch (sentTaskStatus) {
    case SentTaskStatus.None: {
      return "No tasks in the queue";
    }
    case SentTaskStatus.Enqueued: {
      return "At least one task in the queue";
    }
    case SentTaskStatus.Failed: {
      return "Current task failed and further processing is halted";
    }
  }

  return "";
}

export function orderStatusIconRenderer(orderStatus: OrderStatus | undefined, isonSize: number = 16) {
  let className = "";
  let color = "";
  switch (orderStatus) {
    case OrderStatus.Cancelled:
      className = "fas fa-ban";
      color = appColors.orange;
      break;
    case OrderStatus.Planned:
      className = "fas fa-clipboard-list";
      color = appColors.black;
      break;
    case OrderStatus.Closed:
      className = "fas fa-clipboard-check";
      color = appColors.blue;
      break;
  }

  return <IconIStyled className={className} {...{ size: isonSize, color: color }} />;
}

export function getOrderStatusText(orderStatus: OrderStatus | undefined) {
  switch (orderStatus) {
    case OrderStatus.Cancelled:
      return "Cancelled";
    case OrderStatus.Planned:
      return "Planned";
    case OrderStatus.Closed:
      return "Closed";
  }

  return "";
}

export function cellClass(params: GridCellParams) {
  var rowData = params.row as FuelDeliveryRowVm | LpgDeliveryRowVm;
  switch (rowData.orderStatus) {
    case OrderStatus.Cancelled:
      return "cancelled-row-cell";
    case OrderStatus.Closed:
      return "closed-row-cell";
  }
  return "";
}
