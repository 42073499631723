import { Button } from "@material-ui/core";
import React from "react";
import { LpgDeliveryRowVm } from "../../models/common";

interface CellEditLpgsComponentProps {
  row: LpgDeliveryRowVm;
  onEditClicked: (row: LpgDeliveryRowVm) => void;
}

export function CellEditLpgsComponent({ row, onEditClicked }: CellEditLpgsComponentProps) {
  return React.useMemo(
    () => (
      <Button onClick={() => onEditClicked(row)} variant="contained" color="primary" disabled={!row.isSentDataEditable}>
        Edit
      </Button>
    ),
    [row, onEditClicked]
  );
}
