import clsx from "clsx";
import React from "react";
import { IconIStyled } from "../styledComponents";
import { CellOuterDivStyled } from "./styledComponents";

export enum ClickableCellType {
  InfoAboutControl,
  OfficialInfo,
}

export function CellClickableComponent({ type }: { type: ClickableCellType | undefined }) {
  return React.useMemo(() => {
    let iconClassName = "";
    switch (type) {
      case ClickableCellType.InfoAboutControl:
        iconClassName = "fas fa-flag";
        break;
      case ClickableCellType.OfficialInfo:
        iconClassName = "fa fa-envelope";
        break;
    }

    return (
      <CellOuterDivStyled className={clsx(iconClassName ? "clickable-cell" : "")}>
        <IconIStyled className={iconClassName} />
      </CellOuterDivStyled>
    );
  }, [type]);
}
