import React, { useCallback, useEffect, useState } from "react";
import API from "../../api/api";
import { useHistory } from "react-router-dom";
import { AdminRoutes } from "../../routes/routeUtils";
import ServerCommunicationError from "../../common/ServerCommunicationIssue";
import { Button, Icon, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, RadioGroup, Radio } from "@material-ui/core";
import { FormControlLabel, Switch } from "@material-ui/core";
import { PageContainerDivStyled } from "../pageStyledComponents";
import Loader from "../../common/Loader";
import { PuescChannel, SystemSettings } from "../../api/models";


export default function SystemSettingsPage() {
  const history = useHistory();
  const [loadState, setLoadState] = useState<any>({ loading: false, error: undefined });
  const [curSettings, setCurSettings] = useState<SystemSettings>();
  const [newSettings, setNewSettings] = useState<SystemSettings>();
  const [saveSettings, setSaveSettings] = useState({ showDialog: false, saving: false, error: { status: undefined, data: undefined } });


  const getSettings = useCallback(() => {
    let newLoadState = { loading: true, error: undefined }
    setLoadState(newLoadState);
    setCurSettings(undefined);
    setNewSettings(undefined);

    let request: Promise<any> = API.get("/SystemSettings");

    return request
      .then(response => {
        const settings: SystemSettings = response.data;
        console.log(settings);
        setCurSettings(settings);
        setNewSettings(settings);
        setLoadState({ loading: false, error: undefined });
      })
      .catch(err => {
        console.log("Error:", err);
        console.log("Error response:", err.response);
        setLoadState({ loading: false, error: err.response || err });
      })

  }, []);


  useEffect(() => { getSettings() }, [getSettings]);


  function anyChange(): boolean {
    if (newSettings?.automationStatus === curSettings?.automationStatus &&
      newSettings?.communicationChannel === curSettings?.communicationChannel) {
      return true;
    }
    return false;
  }


  const saveChanges = () => {
    setSaveSettings({ showDialog: false, saving: true, error: { status: undefined, data: undefined } });

    API.post("/SystemSettings", newSettings)
      .then(response => {
        console.log(`Status: ${response.status}`, response.data);
        setSaveSettings({ showDialog: false, saving: false, error: { status: undefined, data: undefined } });
        setCurSettings({ ...newSettings });
        //history.push(AdminRoutes.AdminPage)
      })
      .catch(err => {
        console.log(`Failed saving system settings (status: ${err.response?.status})`, err.response)
        setSaveSettings({ showDialog: true, saving: false, error: err.response })
      })
  }


  return (
    <>
      {/* {newSettings && <div>{JSON.stringify(newSettings, null, 2)}</div>} */}
      <Loader isLoading={loadState.loading || saveSettings.saving} />
      <PageContainerDivStyled >
        <div className="edit-main" style={{ minHeight: "400px", minWidth: "400px" }}>
          <div className="edit-header">System Settings</div>
          {loadState.error !== undefined && <ServerCommunicationError errType={loadState.error} />}
          {newSettings &&
            <>
              <div className="edit-contents" style={{ minHeight: "200px" }}>

                <div className="edit-contents-item">
                  <div>Automation</div>
                  <div>
                    OFF<Switch color="secondary" checked={newSettings.automationStatus || false} onChange={(e) => setNewSettings({ ...newSettings, automationStatus: e.target.checked })} disabled />ON
                  </div>
                </div>

                <div className="edit-contents-item">
                  <div>Communication Channel</div>
                  <RadioGroup name="communicationChannel" value={newSettings.communicationChannel} onChange={(e) => setNewSettings({ ...newSettings, communicationChannel: e.target.value as PuescChannel })}>
                    <FormControlLabel value={PuescChannel.WebService} label="Web Service" control={<Radio />} />
                    <FormControlLabel value={PuescChannel.Email} label="Email" control={<Radio />} />
                  </RadioGroup>
                </div>

              </div>

              <div className="edit-form-buttons-bar">
                <Button style={{ width: "100px" }} color="secondary" onClick={() => history.push(AdminRoutes.AdminPage)}>
                  <Icon className="fas fa-times" />
                  Close
                </Button>

                <Button color="primary" style={{ width: "100px" }} disabled={anyChange()} onClick={saveChanges} >
                  <Icon className="fas fa-save" />
                  Save
                </Button>
              </div>

            </>
          }
        </div>
      </PageContainerDivStyled>

      <Dialog id="dialog-error-save" className="visent" open={saveSettings.showDialog} >
        <DialogTitle>Data Save Error</DialogTitle>
        <DialogContent>
          {saveSettings.error?.status && <DialogContentText>Response status: {saveSettings.error?.status}</DialogContentText>}
          <DialogContentText>Could not save the data.</DialogContentText>
          {/*
          {saveSettings?.error?.status && <DialogContentText>Response status: {vehiclePostError?.status}</DialogContentText>}
          {saveSettings?.error?.data?.errors && <DialogContentText>{vehiclePostError?.data?.errors}</DialogContentText>}
          */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSaveSettings({ ...saveSettings, showDialog: false })} color="primary" autoFocus>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}