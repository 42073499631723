import { Button } from "@material-ui/core";
import React from "react";
import { FuelDeliveryRowVm, LpgDeliveryRowVm } from "../../models/common";

interface CellDetailsComponentProps {
  row: FuelDeliveryRowVm | LpgDeliveryRowVm;
  onDetailsCliked: (row: FuelDeliveryRowVm | LpgDeliveryRowVm) => void;
}

export function CellDetailsComponent({ row, onDetailsCliked }: CellDetailsComponentProps) {
  return React.useMemo(
    () => (
      <Button onClick={() => onDetailsCliked(row)} variant="contained" color="secondary">
        Details
      </Button>
    ),
    [row, onDetailsCliked]
  );
}
