import React, { SyntheticEvent, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AdminRoutes } from "../../routes/routeUtils";
import API from "../../api/api";
import ServerCommunicationError, { CommunicationErrorType } from "../../common/ServerCommunicationIssue";
import { Button, Icon, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { PageContainerDivStyled } from "../pageStyledComponents";
import Loader from "../../common/Loader";
import { DepotWeb, DepotKit, SupplierSimple } from "../../api/models";
import { DistrictsPL } from "../../models/common";
import { validatePostalCode } from "../../services/miscellaneous";



export default function DepotEdit() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [depotEdited, setDepotEdited] = useState<DepotWeb>();
  const [depotLoaded, setDepotLoaded] = useState<DepotWeb>();
  const [depotLoading, setDepotLoading] = useState<boolean>(false);
  const [depotError, setDepotError] = useState<CommunicationErrorType>();
  const [suppliers, setSuppliers] = useState<SupplierSimple[]>();

  const [depotSaving, setDepotSaving] = useState<boolean>(false);
  const [depotSaveError, setDepotSaveError] = useState<any>();
  const [depotSaveSuccess, setDepotSaveSuccess] = useState<boolean>();
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [noChangesDialogOpen, setNoChangesDialogOpen] = useState<boolean>(false);

  const [anyChange, setAnyChange] = useState<boolean>(false);
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState(false);
  const [isPostalCodeValid, setIsPostalCodeValid] = useState<boolean>(true);
  const [isDistrictValid, setIsDistrictValid] = useState<boolean>(true);


  useEffect(() => {
    setDepotLoading(true);
    setDepotError(undefined);

    console.log("Retrieving depots...");
    let req: Promise<any>;
    req = API.get(`/Depots/DepotKit/${id || '0'}`);
    req.then(resp => {
      let respContent: DepotKit = resp.data;
      let tmpDepot: DepotWeb = {
        id: NaN,
        supplierId: NaN,
        name: '',
        street: '',
        houseNumber: '',
        city: '',
        postalCode: '',
        country: 'PL',
        district: ''
      };

      if (id && respContent.depot) tmpDepot = respContent?.depot!;

      setDepotLoaded(tmpDepot);
      setDepotEdited(tmpDepot);
      setSuppliers(respContent?.suppliers || []);
    })
      .catch(err => setDepotError(err))
      .finally(() => setDepotLoading(false))
  }, [id]);


  useEffect(() => {
    // Discover changes
    let hasChanged: boolean =
      Number.isFinite(depotLoaded?.id) !== Number.isFinite(depotEdited?.id) ||
      depotLoaded?.id !== depotEdited?.id ||
      Number.isFinite(depotLoaded?.supplierId) !== Number.isFinite(depotEdited?.supplierId) ||
      depotLoaded?.supplierId !== depotEdited?.supplierId ||
      depotLoaded?.name !== depotEdited?.name ||
      depotLoaded?.street !== depotEdited?.street ||
      depotLoaded?.houseNumber !== depotEdited?.houseNumber ||
      depotLoaded?.city !== depotEdited?.city ||
      depotLoaded?.postalCode !== depotEdited?.postalCode ||
      depotLoaded?.country !== depotEdited?.country ||
      depotLoaded?.district !== depotEdited?.district;

    // Validate postal code
    let isCodeOk: boolean = validatePostalCode(depotEdited?.postalCode, depotEdited?.country);

    // Validate district
    let isDistrictOk: boolean = (depotEdited?.country === "PL" && depotEdited?.district !== "") || (depotEdited?.country !== "PL" && depotEdited?.district === "");

    // Validate form data
    let isAllOk: boolean = true;
    if (!Number.isFinite(depotEdited?.id)) isAllOk = false;
    if (!Number.isFinite(depotEdited?.supplierId)) isAllOk = false;
    if (!depotEdited?.name?.trim()) isAllOk = false;
    if (!depotEdited?.street?.trim()) isAllOk = false;
    // if (!depotEdited?.houseNumber?.trim()) isAllOk = false;
    if (!depotEdited?.city?.trim()) isAllOk = false;
    if (!depotEdited?.country?.trim()) isAllOk = false;
    if (!validatePostalCode(depotEdited?.postalCode, depotEdited?.country, false)) isAllOk = false;
    if (!isDistrictOk) isAllOk = false;

    // Memorize state
    setIsAllValid(isAllOk);
    setAnyChange(hasChanged);
    setIsPostalCodeValid(isCodeOk);
    setIsDistrictValid(isDistrictOk);

    console.log(`isAllOk: ${isAllOk}\nhasChanged: ${hasChanged}\nisCodeOk: ${isCodeOk}\nisDistrictOk: ${isDistrictOk}`);
    if (hasChanged) {
      console.log("Loaded:", depotLoaded);
      console.log("Edited:", depotEdited);
    }
  }, [depotEdited, depotLoaded]);


  // useEffect(() => {
  //   let isCodeOk: boolean = validatePostalCode(depotEdited?.postalCode, depotEdited?.country);
  //   let isDistrictOk: boolean = (depotEdited?.country === "PL" && depotEdited?.district !== "") || (depotEdited?.country !== "PL" && depotEdited?.district === "");
  //   setIsPostalCodeValid(isCodeOk);
  //   setIsDistrictValid(isDistrictOk);

  //   console.log(`HELLO!\nisCodeOk: ${isCodeOk}\nisDistrictOk: ${isDistrictOk}`);
  // }, [depotEdited?.country, depotEdited?.postalCode, depotEdited?.district])

  // function anyChange(): boolean {
  //   return (
  //     depotLoaded?.id !== depotEdited?.id ||
  //     depotLoaded?.name !== depotEdited?.name ||
  //     depotLoaded?.street !== depotEdited?.street ||
  //     depotLoaded?.houseNumber !== depotEdited?.houseNumber ||
  //     depotLoaded?.city !== depotEdited?.city ||
  //     depotLoaded?.postalCode !== depotEdited?.postalCode ||
  //     depotLoaded?.country !== depotEdited?.country ||
  //     depotLoaded?.district !== depotEdited?.district
  //   );
  // }


  // Submit data
  function handleSubmit(event: SyntheticEvent | null) {
    event?.preventDefault();

    console.log("Initial Depot:", depotLoaded);
    console.log("Modified Depot:", depotEdited);
    console.log("Posting to API...")

    setDepotSaving(true);
    setDepotSaveError(undefined);
    let request: Promise<any>;
    if (id) {
      request = API.put("/Depots", depotEdited);
    }
    else {
      request = API.post("/Depots", depotEdited);
    }

    request
      .then(resp => {
        console.log("Successfully posted");
        console.log(resp);
        setDepotSaveSuccess(true);
      })
      .catch(err => {
        setDepotSaveError(err.response);
        console.log("Ooops! An error occurred.")
        console.log(err.response);
        console.log("Errors:", err.response?.data?.errors);
        setErrorDialogOpen(true);
      })
      .finally(() => setDepotSaving(false));

  };


  // Close without saving
  const handleClose = (confirmToClose: boolean = true) => {
    if (confirmToClose && anyChange && isAllValid)
      setConfirmCloseDialogOpen(true);
    else
      history.push(AdminRoutes.Depots);
  }


  // Redirect to 'Depots' after successful save
  useEffect(() => { if (depotSaveSuccess) history.push(AdminRoutes.Depots) }, [history, depotSaveSuccess]);

  return (
    <>
      <Loader isLoading={depotSaving || depotLoading} />

      <PageContainerDivStyled >

        <div className="edit-main" style={{ width: "400px" }}>
          <div className="edit-header">{id ? "Edit Depot" : "Add Depot"}</div>
          <div className="edit-contents">

            {!depotError &&
              <form onSubmit={handleSubmit}>

                <label htmlFor="depotId">Depot ID</label>
                {!id && <input type="number" id="depotId" name="depotId" className="visent" value={depotEdited?.id} required onChange={(e) => setDepotEdited({ ...depotEdited, id: parseInt(e.target.value) } as DepotWeb)} />}
                {id && <input type="number" id="depotId" name="depotId" className="visent" defaultValue={depotEdited?.id} required disabled />}

                <label htmlFor="supplier">Supplier</label>
                <select id="supplier" name="supplier" className="visent" value={depotEdited?.supplierId} required onChange={(e) => setDepotEdited({ ...depotEdited, supplierId: parseInt(e.target.value) } as DepotWeb)}>
                  <option key="-" defaultValue={NaN}>--pick supplier--</option>
                  {suppliers?.map((s) => (<option key={s.supplierId.toString()} value={s.supplierId} >{`${s.supplierId} ${s.supplierName}`}</option>))}
                </select>

                <label htmlFor="supplierName">Name</label>
                <input type="text" id="supplierName" name="supplierName" value={depotEdited?.name || ''} required className="visent" onChange={(e) => setDepotEdited({ ...depotEdited, name: e.target.value } as DepotWeb)} />

                <label htmlFor="street">Street</label>
                <input type="text" id="street" name="street" value={depotEdited?.street || ''} className="visent" onChange={(e) => setDepotEdited({ ...depotEdited, street: e.target.value } as DepotWeb)} />

                <label htmlFor="houseNo">House Number</label>
                <input type="text" id="houseNo" name="houseNo" className="visent" value={depotEdited?.houseNumber || ''} onChange={(e) => setDepotEdited({ ...depotEdited, houseNumber: e.target.value } as DepotWeb)} />



                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ minWidth: "60px" }}>
                    <label htmlFor="postalCode">Postal Code</label>
                    <input type="text" id="postalCode" name="postalCode" className={isPostalCodeValid ? "visent" : "visent edit-input-error"} style={{ width: "65%" }} value={depotEdited?.postalCode || ''} required onChange={(e) => setDepotEdited({ ...depotEdited, postalCode: e.target.value } as DepotWeb)} />
                  </div>

                  <div style={{ minWidth: "220px" }}>
                    <label htmlFor="city">City</label>
                    <input type="text" id="city" name="city" className="visent" style={{ width: "100%" }} value={depotEdited?.city || ''} required onChange={(e) => setDepotEdited({ ...depotEdited, city: e.target.value } as DepotWeb)} />
                  </div>
                </div>


                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <label htmlFor="country">Country</label>
                    <select id="country" name="country" className="visent" value={depotEdited?.country || ''} required disabled onChange={(e) => setDepotEdited({ ...depotEdited, country: e.target.value, district: (e.target.value === "PL" ? depotEdited?.district : "") } as DepotWeb)}>
                      <option value="" >--pick country--</option>
                      <option value="PL" >PL - Poland</option>
                      <option value="LT" >LT - Lithuania</option>
                      <option value="DE" >DE - Germany</option>
                      <option value="SK" >SK - Slovakia</option>
                      <option value="CZ" >CZ - Czech Rep.</option>
                      <option value="RU" >RU - Russia</option>
                      <option value="BY" >BY - Belarus</option>
                      <option value="LV" >LV - Latvia</option>
                    </select>
                  </div>

                  <div style={{ minWidth: "220px" }}>
                    <label htmlFor="district">District</label>
                    <select id="district" name="district" className="visent" value={depotEdited?.district || ''} disabled={depotEdited?.country !== "PL"} required onChange={(e) => setDepotEdited({ ...depotEdited, district: e.target.value } as DepotWeb)}>
                      <option key="-" value="">--pick district--</option>
                      {DistrictsPL.map((d) => (<option key={d} value={d} >{`${d}`}</option>))}
                    </select>
                  </div>
                </div>

                <div className="edit-form-buttons-bar">

                  <Button style={{ width: "100px" }} color="secondary" onClick={() => handleClose(true)}>
                    <Icon className="fas fa-undo" />
                    Cancel
                  </Button>

                  <Button type="submit" color="primary" style={{ width: "100px" }} disabled={!isAllValid || !anyChange}>
                    <Icon className="fas fa-save" />
                    Save
                  </Button>

                </div>
              </form>
            }

            {depotError && <ServerCommunicationError errType={depotError} />}

          </div>

        </div>

        <Dialog id="dialog-error-save" className="visent" open={errorDialogOpen} >
          <DialogTitle>Data Save Error</DialogTitle>
          <DialogContent>
            {depotSaveError?.status && <DialogContentText>Response status: {depotSaveError?.status}</DialogContentText>}
            <DialogContentText>Could not save the data.</DialogContentText>
            {depotSaveError?.data?.errors &&
              <DialogContentText>
                {typeof (depotSaveError?.data?.errors) === "string" ? depotSaveError?.data?.errors : JSON.stringify(depotSaveError?.data?.errors)}
              </DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setErrorDialogOpen(false)} color="primary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="dialog-no-chnages" className="visent" open={noChangesDialogOpen} >
          <DialogTitle>No Changes</DialogTitle>
          <DialogContent>
            <DialogContentText>No changes were discovered. No need to save data.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNoChangesDialogOpen(false)} color="secondary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="dialog-confirm-close" className="visent" open={confirmCloseDialogOpen} >
          <DialogTitle>Not Saved Changes</DialogTitle>
          <DialogContent>
            <DialogContentText>There are changes that have not been saved.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleSubmit(null)} color="primary" autoFocus>Save & Close</Button>
            <Button onClick={() => setConfirmCloseDialogOpen(false)} color="secondary" autoFocus>Back</Button>
            <Button onClick={() => handleClose(false)} color="secondary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

      </PageContainerDivStyled>
    </>
  );
}
