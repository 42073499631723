import { ItemKey } from "../api/common";
import { WebOrder } from "../api/models";

export interface UpdateError {
  id: string;
  statusCode?: number;
  errorDetails: any;
}

export interface HttpCallError {
  statusCode: number | undefined;
  errorDetails: any;
}

export interface FuelDeliveryRowVm extends WebOrder {
  id: string;
  isSelected: boolean;
  editMode: DataEditMode | undefined;
  sentNumberHasError: boolean;
  senderKeyHasError: boolean;
  carrierKeyHasError: boolean;
  recipientKeyHasError: boolean;
}

export interface LpgDeliveryRowVm extends WebOrder {
  id: string;
  isSelected: boolean;
}

export interface LpgDeliveryRowEditRowVm extends WebOrder {
  id: string;
}

export class EditDataPoint {
  private validator: (value: string | null | undefined) => string | null | undefined;
  private initialValue: string | null | undefined;

  private _value: string | null | undefined;
  public get value(): string | null | undefined {
    return this._value;
  }

  private _validationError: string | null | undefined;
  public get validationError(): string | null | undefined {
    return this._validationError;
  }

  public get isValueChanged() {
    return this.initialValue !== this._value;
  }

  public get isValueChangedAndInvalid(): boolean {
    return this.isValueChanged && !this.isValid;
  }

  public get isValid(): boolean {
    return !this.validationError;
  }

  constructor(value: string | null | undefined, validator: (value: string | null | undefined) => string | null | undefined) {
    this.validator = validator;
    this.initialValue = value;
    this.setValue(value);
  }

  public setValue(value: string | null | undefined) {
    this._value = value;
    this._validationError = this.validator(this._value);
  }
}

export enum DataEditMode {
  NoEdit,
  InSync,
  HasPendingEdit,
  SavingInProgress,
  SavingError,
}

export class BaseDataEdit {
  id: string;
  rowKey: ItemKey;
  mode: DataEditMode = DataEditMode.NoEdit;

  constructor(id: string, rowKey: ItemKey) {
    this.id = id;
    this.rowKey = rowKey;
  }
}


export let DistrictsPL: string[] = [
  "DOLNOŚLĄSKIE",
  "KUJAWSKO-POMORSKIE",
  "ŁÓDZKIE",
  "LUBELSKIE",
  "LUBUSKIE",
  "MAŁOPOLSKIE",
  "MAZOWIECKIE",
  "OPOLSKIE",
  "PODKARPACKIE",
  "PODLASKIE",
  "POMORSKIE",
  "ŚLĄSKIE",
  "ŚWIĘTOKRZYSKIE",
  "WARMIŃSKO-MAZURSKIE",
  "WIELKOPOLSKIE",
  "ZACHODNIOPOMORSKIE",
]
