import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AdminRoutes } from "../../routes/routeUtils";
import API from "../../api/api";
import ServerCommunicationError, { CommunicationErrorType } from "../../common/ServerCommunicationIssue";
import { Button, Icon, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { AdUser, AppUser } from "../../api/models";
import { PageContainerDivStyled } from "../pageStyledComponents";
import Loader from "../../common/Loader";
import { useGraph } from "../../services/graphClient";

export default function UserEdit() {
  const history = useHistory();
  const { upn } = useParams<{ upn: string }>(); // NULL -> new user; NOT NULL -> edit existing
  const [userEdited, setUserEdited] = useState<AppUser>({
    userPrincipalName: '',
    firstName: '',
    lastName: '',
    emailAddress: ''
  });
  const [userLoaded, setUserLoaded] = useState<AppUser>();
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [userError, setUserError] = useState<CommunicationErrorType>();
  const [userSaving, setUserSaving] = useState<boolean>(false);
  const [userSaveError, setUserSaveError] = useState<any>();
  const [userSaveSuccess, setUserSaveSuccess] = useState<boolean>();
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [noChangesDialogOpen, setNoChangesDialogOpen] = useState<boolean>(false);

  const [adUsers, setAdUsers] = useState<AdUser[]>([]);
  const [userSearchTerm, setUserSearchTerm] = useState<string>();

  const { findAdUsers } = useGraph();

  const getUser = useCallback(() => {
    setUserLoading(true);
    setUserError(undefined);

    //console.log("Retrieving users...");

    if (!upn) {
      console.log("Empty user");
      const user: AppUser = {
        userPrincipalName: '',
        firstName: '',
        lastName: '',
        emailAddress: ''
      }
      setUserLoaded(user);
      setUserEdited(user);
      setUserLoading(false);
    }
    else {
      console.log("User from database");
      let req: Promise<any>;
      req = API.get(`/Users/App/${upn}`);
      req.then(resp => {
        let respContent: AppUser = resp.data;
        setUserLoaded(respContent);
        setUserEdited(respContent);
        setUserLoading(false);
      })
        .catch(err => setUserError(err))
        .finally(() => setUserLoading(false))
    }
  }, [upn]);



  useEffect(() => {
    getUser();
  }, [getUser]);



  useEffect(() => {
    if (userSearchTerm) {
      findAdUsers(userSearchTerm as string)
        .then(resp => {
          //console.log("Response:", resp);
          return resp.json();
        })
        .then(data => {
          //console.log("Data:", data);
          setAdUsers(data.value || []);
        })
        .catch(err => {
          console.error("Error searching users:", err);
        })
    }
    else {
      setAdUsers([]);
    }
  }, [userSearchTerm])


  function anyChange(): boolean {
    return (
      userLoaded?.userPrincipalName !== userEdited?.userPrincipalName ||
      userLoaded?.firstName !== userEdited?.firstName ||
      userLoaded?.lastName !== userEdited?.lastName ||
      userLoaded?.emailAddress !== userEdited?.emailAddress
    );
  }

  const copyUserData = (userPrincipalName: string) => {
    let pickedUser: AdUser | undefined = adUsers.find(x => x.userPrincipalName === userPrincipalName);
    if (pickedUser) {
      let newUser: AppUser = {
        userPrincipalName: pickedUser.userPrincipalName as string,
        firstName: pickedUser.givenName || '',
        lastName: pickedUser.surname || '',
        emailAddress: pickedUser.mail || ''
      }
      setUserEdited(newUser);
      document.getElementById("save-button")?.focus();
    }
  }

  // Submit data
  function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();

    console.log("Initial User:", userLoaded);
    console.log("Modified User:", userEdited);
    console.log("Posting to API...")

    setUserSaving(true);
    setUserSaveError(undefined);
    let request: Promise<any>;
    if (upn) {
      request = API.put("/Users", userEdited);
    }
    else {
      request = API.post("/Users", userEdited);
    }

    request
      .then(resp => {
        console.log("Successfully posted");
        console.log(resp);
        setUserSaveSuccess(true);
      })
      .catch(err => {
        setUserSaveError(err.response);
        console.log("Ooops! An error occurred.")
        console.log(err.response);
        console.log(err.response?.data?.errors);
        setErrorDialogOpen(true);
      })
      .finally(() => setUserSaving(false));

  };


  // Redirect to 'Users' after successful save
  useEffect(() => { if (userSaveSuccess) history.push(AdminRoutes.Users) }, [history, userSaveSuccess]);

  return (
    <>
      <Loader isLoading={userSaving || userLoading} />

      <PageContainerDivStyled >

        <div className="edit-main">
          <div className="edit-header">
            {upn ? "Edit User" : "Add User"}

            {/* Searching AD - only for new users */}
            {!upn &&
              <div className="edit-dropdown">
                <div>
                  {/* Search box */}
                  <input type="search" id="dropdown-searchbox" placeholder="Search AD..." className="visent" style={{ width: "200px" }} value={userSearchTerm} onChange={e => setUserSearchTerm(e.target.value)}
                    onKeyUp={e => {
                      if (e.code === "ArrowDown") {
                        let dropDown = document.getElementById("dropdown-list") as HTMLSelectElement;
                        if (dropDown) {
                          dropDown.selectedIndex = 0;
                          dropDown.focus();
                        }
                      }
                    }}
                    onFocus={e => {
                      let dropDown = document.getElementById("dropdown-list") as HTMLSelectElement;
                      if (dropDown) {
                        dropDown.hidden = false;
                        dropDown.selectedIndex = -1;
                      }
                    }}
                    onBlur={e => {
                      let dropDown = document.getElementById("dropdown-list") as HTMLSelectElement;
                      dropDown?.focus();
                    }}
                  />

                  {/* Drop down */}
                  <select id="dropdown-list" size={10} className="dropdown" style={{ minWidth: "180px", position: "fixed" }} hidden={true}
                    onBlur={e => {
                      e.target.hidden = true;
                    }}
                    onClick={e => {
                      copyUserData(e.currentTarget.value);
                      e.currentTarget.hidden = true;
                    }}
                    onKeyUp={e => {
                      if (e.code === "Enter") {
                        console.log("Value: ", e.currentTarget.value);
                        copyUserData(e.currentTarget.value);
                        e.currentTarget.hidden = true;
                      }
                      else if (e.currentTarget.selectedIndex <= 0 && e.code === "ArrowUp") {
                        let searchBox = document.getElementById("dropdown-searchbox") as HTMLInputElement;
                        searchBox?.focus();
                      }
                    }}
                  >
                    {adUsers.map(u => {
                      return (<option key={u.userPrincipalName} value={u.userPrincipalName as string}>
                        {`${u.displayName} (${u.userPrincipalName?.split("@")[0]})`}
                      </option>);
                    })}
                  </select>
                </div>
              </div>
            }
          </div>

          <div className="edit-contents" style={{ width: "400px" }}>


            {!userError &&
              <form onSubmit={handleSubmit}>

                <label htmlFor="upn">User Principal Name</label>
                {!upn && <input type="text" id="upn" name="upn" className="visent" value={userEdited.userPrincipalName} required onChange={(e) => setUserEdited({ ...userEdited, userPrincipalName: e.target.value } as AppUser)} />}
                {upn && <input type="text" id="upn" name="upn" className="visent" defaultValue={userEdited.userPrincipalName} required disabled />}

                <label htmlFor="firstName">First Name</label>
                <input type="text" id="firstName" name="firstName" className="visent" value={userEdited.firstName as string} onChange={(e) => setUserEdited({ ...userEdited, firstName: e.target.value } as AppUser)} />

                <label htmlFor="lastName">Last Name</label>
                <input type="text" id="lastName" name="lastName" className="visent" value={userEdited.lastName as string} onChange={(e) => setUserEdited({ ...userEdited, lastName: e.target.value } as AppUser)} />

                <label htmlFor="emailAddress">Email Address</label>
                <input type="text" id="emailAddress" name="emailAddress" className="visent" value={userEdited.emailAddress as string} onChange={(e) => setUserEdited({ ...userEdited, emailAddress: e.target.value } as AppUser)} />

                <div className="edit-form-buttons-bar">

                  <Button id="cancel-button" style={{ width: "100px" }} color="secondary" onClick={() => history.push(AdminRoutes.Users)}>
                    <Icon className="fas fa-undo" />
                    Cancel
                  </Button>

                  <Button id="save-button" type="submit" color="primary" style={{ width: "100px" }} disabled={!anyChange()}>
                    <Icon className="fas fa-save" />
                    Save
                  </Button>

                </div>
              </form>
            }

            {userError && <ServerCommunicationError errType={userError} />}

          </div>
        </div>

        <Dialog id="dialog-error-save" className="visent" open={errorDialogOpen} >
          <DialogTitle>Data Save Error</DialogTitle>
          <DialogContent>
            {userSaveError?.status && <DialogContentText>Response status: {userSaveError?.status}</DialogContentText>}
            <DialogContentText>Could not save the data.</DialogContentText>
            {userSaveError?.data?.errors && <DialogContentText>{userSaveError?.data?.errors}</DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setErrorDialogOpen(false)} color="primary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="dialog-no-chnages" className="visent" open={noChangesDialogOpen} >
          <DialogTitle>No Changes</DialogTitle>
          <DialogContent>
            <DialogContentText>No changes were discovered. No need to save data.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNoChangesDialogOpen(false)} color="secondary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

      </PageContainerDivStyled>
    </>
  );
}
