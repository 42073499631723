import { GridCellParams } from "@material-ui/data-grid";
import { getKeyString, ItemKey } from "../api/common";
import { WebOrder } from "../api/models";
import { LpgGridEditableColumn } from "../common/dialogs/EditLpgDialog";

import { BaseDataEdit, DataEditMode, EditDataPoint } from "../models/common";
import { cellClass } from "./statusesRendering";

export enum RowType {
  Fuel,
  Lpg,
}

export enum FuelGridEditableColumn {
  SentNumber = "sentNumber",
  SenderKey = "senderKey",
  CarrierKey = "carrierKey",
  RecipientKey = "recipientKey",
}

export class FuelDeliveryDataEdit extends BaseDataEdit {
  sentNumber: EditDataPoint;
  senderKey: EditDataPoint;
  carierKey: EditDataPoint;
  recipientKey: EditDataPoint;

  constructor(rowKey: ItemKey, sentNumber: string, senderKey: string, carierKey: string, recipientKey: string) {
    super(getKeyString(rowKey), rowKey);

    this.sentNumber = new EditDataPoint(sentNumber, fuelSentNumberValidator);
    this.senderKey = new EditDataPoint(senderKey, senderKeyValidator);
    this.carierKey = new EditDataPoint(carierKey, carierKeyValidator);
    this.recipientKey = new EditDataPoint(recipientKey, recipientKeyValidator);
  }
}

export class LpgDeliveryDataEdit extends BaseDataEdit {
  sentNumber: EditDataPoint;
  recipientKey: EditDataPoint;

  constructor(rowKey: ItemKey, sentNumber: string, recipientKey: string) {
    super(getKeyString(rowKey), rowKey);

    this.sentNumber = new EditDataPoint(sentNumber, lpgSentNumberValidator);
    this.recipientKey = new EditDataPoint(recipientKey, recipientKeyValidator);
  }
}

export function getFuelEditValidationErrors(fuelEditor: FuelDeliveryDataEdit | undefined): string[] {
  const validationMessages = [];

  if (!fuelEditor) {
    return [];
  }

  if (!fuelEditor.sentNumber.isValid) {
    validationMessages.push(fuelEditor.sentNumber.validationError!);
  }

  if (fuelEditor.senderKey.isValueChangedAndInvalid) {
    validationMessages.push(fuelEditor.senderKey.validationError!);
  }

  if (fuelEditor.carierKey.isValueChangedAndInvalid) {
    validationMessages.push(fuelEditor.carierKey.validationError!);
  }

  if (fuelEditor.recipientKey.isValueChangedAndInvalid) {
    validationMessages.push(fuelEditor.recipientKey.validationError!);
  }

  return validationMessages;
}

export function getLpgEditValidationErrors(lpgEditor: LpgDeliveryDataEdit | undefined): string[] {
  const validationMessages = [];

  if (!lpgEditor) {
    return [];
  }

  if (lpgEditor.mode === DataEditMode.NoEdit) {
    return [];
  }

  if (lpgEditor.sentNumber.isValueChangedAndInvalid) {
    validationMessages.push(lpgEditor.sentNumber.validationError!);
  }

  if (lpgEditor.recipientKey.isValueChangedAndInvalid) {
    validationMessages.push(lpgEditor.recipientKey.validationError!);
  }

  return validationMessages;
}

export function multiSentNumberValidator(value: string | null | undefined) {
  if (!value) {
    return "Multi Sent number - value is required";
  }

  var regex = /^SENTZB[1234567890]{14}$/;
  if (value.match(regex)) {
    return undefined;
  }

  return "Multi Sent number - value must contain 'SENTZB' prefix followed by 14 digits - eg. SENTZB12345678901234";
}

export function fuelSentNumberValidator(value: string | null | undefined) {
  return sentNumberValidator(value, true);
}

export function lpgSentNumberValidator(value: string | null | undefined) {
  return sentNumberValidator(value, false);
}

function sentNumberValidator(value: string | null | undefined, isValueRequired: boolean) {
  if (!value) {
    if (isValueRequired) {
      return "Sent number - value is required";
    } else {
      return undefined;
    }
  }

  var regex = /^SENT[1234567890]{14}$/;
  if (value.match(regex)) {
    return undefined;
  }

  return "Sent number - value must contain 'SENT' prefix followed by 14 digits - eg. SENT12345678901234";
}

export function senderKeyValidator(value: string | null | undefined) {
  if (!value) {
    return undefined;
  }

  var regex = /^KS-[123456789]{2}[XLANTCYDRUEFZPHMJSKW]{2}$/;
  if (value.match(regex)) {
    return undefined;
  }

  return "Sender key - value must contain 'KS-' prefix followed by 2 digits and two characters from set 'XLANTCYDRUEFZPHMJSKW' - eg. KS-12XL";
}

export function carierKeyValidator(value: string | null | undefined) {
  if (!value) {
    return undefined;
  }

  var regex = /^KD-[123456789]{2}[XLANTCYDRUEFZPHMJSKW]{2}$/;
  if (value.match(regex)) {
    return undefined;
  }

  return "Carier key - value must contain 'KD-' prefix followed by 2 digits and two characters from set 'XLANTCYDRUEFZPHMJSKW' - eg. KD-12XL";
}

export function recipientKeyValidator(value: string | null | undefined) {
  if (!value) {
    return undefined;
  }

  var regex = /^KR-[123456789]{2}[XLANTCYDRUEFZPHMJSKW]{2}$/;
  if (value.match(regex)) {
    return undefined;
  }

  return "Receipient key - value must contain 'KR-' prefix followed by 2 digits and two characters from set 'XLANTCYDRUEFZPHMJSKW' - eg. KR-12XL";
}

export function getEditableCellValue(hasEditor: boolean, editorValue: string | null | undefined, apiValue: string | null | undefined) {
  if (hasEditor) {
    return editorValue || "";
  }

  return apiValue || "";
}

export function editableCellClass(params: GridCellParams, column: FuelGridEditableColumn | LpgGridEditableColumn) {
  const hasError = params.row[`${column}HasError`];
  return hasError ? "editable-cell-error" : cellClass(params);
}

export function fuelRowEditOnColumnDataChange(row: WebOrder, editor: FuelDeliveryDataEdit, column: string, newValue: string | undefined) {
  switch (column) {
    case FuelGridEditableColumn.SentNumber:
      editor.sentNumber.setValue(newValue);
      break;
    case FuelGridEditableColumn.SenderKey:
      editor.senderKey.setValue(newValue);
      break;
    case FuelGridEditableColumn.CarrierKey:
      editor.carierKey.setValue(newValue);
      break;
    case FuelGridEditableColumn.RecipientKey:
      editor.recipientKey.setValue(newValue);
      break;
    default:
      throw new Error(`Column ${column} is not editable`);
  }

  editor.mode = fuelDetermineEditMode(row, editor);
}

export function lpgRowEditOnColumnDataChange(row: WebOrder, editor: LpgDeliveryDataEdit, column: string, newValue: string | undefined) {
  switch (column) {
    case LpgGridEditableColumn.SentNumber:
      editor.sentNumber.setValue(newValue);
      break;
    case LpgGridEditableColumn.RecipientKey:
      editor.recipientKey.setValue(newValue);
      break;
    default:
      throw new Error(`Column ${column} is not editable`);
  }

  editor.mode = lpgDetermineEditMode(row, editor);
}

export function fuelRowRevertChanges(row: WebOrder, editor: FuelDeliveryDataEdit, column: string) {
  if (!editor) {
    return;
  }

  switch (column) {
    case FuelGridEditableColumn.SentNumber:
      editor.sentNumber.setValue(row.sentNumber);
      break;
    case FuelGridEditableColumn.SenderKey:
      editor.senderKey.setValue(row.senderKey);
      break;
    case FuelGridEditableColumn.CarrierKey:
      editor.carierKey.setValue(row.carrierKey);
      break;
    case FuelGridEditableColumn.RecipientKey:
      editor.recipientKey.setValue(row.recipientKey);
      break;
    default:
      throw new Error(`Column ${column} is not editable`);
  }

  editor.mode = fuelDetermineEditMode(row, editor);
}

export function fuelRowCanSave(editor: FuelDeliveryDataEdit | undefined) {
  if (editor) {
    return editor.mode === DataEditMode.HasPendingEdit && fuelRowIsValid(editor);
  }

  return false;
}

function fuelDetermineEditMode(row: WebOrder, editor: FuelDeliveryDataEdit) {
  const hasDataChanged =
    row.sentNumber !== editor.sentNumber.value ||
    row.senderKey !== editor.senderKey.value ||
    row.carrierKey !== editor.carierKey.value ||
    row.recipientKey !== editor.recipientKey.value;

  return hasDataChanged ? DataEditMode.HasPendingEdit : DataEditMode.NoEdit;
}

function lpgDetermineEditMode(row: WebOrder, editor: LpgDeliveryDataEdit) {
  const hasDataChanged = row.sentNumber !== editor.sentNumber.value || row.recipientKey !== editor.recipientKey.value;

  return hasDataChanged ? DataEditMode.HasPendingEdit : DataEditMode.NoEdit;
}

function fuelRowIsValid(editor: FuelDeliveryDataEdit | undefined) {
  if (editor) {
    if (!editor.sentNumber.isValid) {
      return false;
    }

    if (editor.senderKey.isValueChangedAndInvalid) {
      return false;
    }

    if (editor.carierKey.isValueChangedAndInvalid) {
      return false;
    }

    if (editor.recipientKey.isValueChangedAndInvalid) {
      return false;
    }
  }

  return true;
}
