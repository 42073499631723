
/**
 * Tests the validity of a geolocation device numnber by checking the number pattern and check digit.
 * The number must be compliant with the specification provided by Polish Ministry of Finance in the
 * documentation for SENT-GEO services.
 * @param {string} geoloc - Geolocation device number to verify
 * @param {boolean} isFailover - Is it failover device?
 * @returns - true if the number is valid (or empty)
 */
export function validateGeoloc(geoloc: string, isFailover: boolean = false) {
    // Only evaluate if not empty
    if (!geoloc) { return true; }

    // Check RegEx pattern
    let regex = '';
    if (isFailover) {
        regex = "[AMU][0-9]{2}-[ABCEFGHKMNPRSTWXYZ]{2}[0-9]{2}[ABCEFGHKMNPRSTWXYZ]{2}-[0-9]";
    }
    else {
        regex = "[AMUZ][0-9]{2}-[ABCEFGHKMNPRSTWXYZ]{2}[0-9]{2}[ABCEFGHKMNPRSTWXYZ]{2}-[0-9]";
    }
    if (!geoloc.match(regex)) { return false; }

    // Test check digit
    const multi = [9, 7, 3, 0, 1, 9, 7, 3, 1, 9];
    var total = 0;
    for (let i = 0; i < multi.length; i++) {
        var value = 0;
        var code = geoloc.charCodeAt(i);

        if (code > 47 && code < 58) { value = code - 48; }
        else if (code > 64 && code < 91) { value = code - 55 }

        total += multi[i] * value;
        // console.debug(`${geoloc.charAt(i)} - ${code} - ${value}`)
    }

    const checkDigit = (total % 10).toString();
    const lastChar = geoloc.charAt(geoloc.length - 1);
    // console.debug(`Last digit: ${lastChar}, check digit: ${checkDigit}`);

    if (lastChar === checkDigit) { return true; } else { return false; }

}


/**
 * Converts a string representing date (and time) in ISO format to human readable string in local time zone.
 * @param {string} iso - string in the format 'yyyy-MM-ddTHH:mm:ss.fffZ'
 * @param {boolean} includeTime - true (default) if the output should contain time part
 * @param {boolean} includeSeconds - true (default: false) if time part should contain seconds
 * @returns - date and time as string, e.g. '2024-08-25, 16:37'
 */
export function isoToLocalTime(iso: string | undefined, includeTime: boolean = true, includeSeconds: boolean = false): string {
    let options: Intl.DateTimeFormatOptions;
    options = { year: "numeric", month: "2-digit", day: "2-digit" }
    if (includeTime) options = { ...options, hour12: false, hour: "2-digit", minute: "2-digit" };
    if (includeTime && includeSeconds) options = { ...options, second: "2-digit" };

    let sDate: string;
    try {
        sDate = Intl.DateTimeFormat("en-CA", options).format(new Date(iso as string));
    } catch (error) {
        sDate = iso as string;
    }

    return sDate;
}



/**
 * Converts an object into a JSON string and returns it in <code> element.
 * @param {any} props - the object to be converted into a JSON
 * @returns - indented JSON text inside <code> element
 */
export function FormattedJson(props: any) {
    if (!props?.obj || typeof props?.obj !== 'object') { return (<></>); }

    return (
        <code
            dangerouslySetInnerHTML={{ __html: JSON.stringify(props!.obj, null, 2).replaceAll("\n", "<br/>").replaceAll(' ', '&nbsp;') }}
            style={props?.style}
            className={props?.className}>
        </code>
    );
}

/**
 * Verifies a postal code depending on the country
 * @param code postal code to verify
 * @param country - two upper case letters country code
 * @returns true if the postal code matches the country-specific pattern
 */
export function validatePostalCode(code?: string | null, country?: string | null, allowEmpty: boolean = true): boolean {
    if (!code?.trim() && allowEmpty) return true;

    let regex: string;
    switch (country?.toUpperCase()) {
        case "CH":
            regex = "^(CH-)?\\d{4}$";
            break;
        case "CZ":
            regex = "^(CZ-)?\\d{3}[ ]?\\d{2}$";
            break;
        case "DE":
            regex = "^(DE)?\\d{5}$";
            break;
        case "DK":
            regex = "^(DK-)?\\d{4}$";
            break;
        case "EE":
            regex = "^\\d{5}$";
            break;
        case "IE":
            regex = "^[A-Z]\\d{2} [0-9A-Z]{4}$";
            break;
        case "LT":
            regex = "^(LT-)?\\d{5}$";
            break;
        case "LV":
            regex = "^LV-\\d{4}$";
            break;
        case "NO":
            regex = "^\\d{4}$";
            break;
        case "PL":
            regex = "^\\d{2}-\\d{3}$";
            break;
        case "RU" || "BY":
            regex = "^\\d{6}$";
            break;
        case "SE":
            regex = "^\\d{3}[ ]?\\d{2}$";
            break;
        case "SK":
            regex = "^(SK-)?\\d{3}[ ]?\\d{2}$";
            break;
        default:
            regex = ".";
            break;
    }

    if (code?.match(regex))
        return true;
    else
        return false;
}

/**
 * Validates ID number depending on its type
 * @param idType Type of the ID. Supported types: NIP, PESEL, DO (for Dowód Osobisty)
 * @param idNumber ID/number to verify
 * @param allowEmpty If true, empty ID is not validated and true is returned
 * @returns True if: 1) the tested ID is valid according to its type; 2) ID type is not supported; 3) ID is null or empty and 'allowEmpty' is true
 */
export function validateIdNumber(idType?: string | null, idNumber?: string | null, allowEmpty: boolean = true): boolean {
    if (!idNumber?.trim() && allowEmpty) return true;

    const isNip = (nip: string) => {
        // Test pattern
        if (!nip?.match("^\\d{10}$")) return false;

        // Test check digit
        let multi = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        var total = 0;
        for (let i = 0; i < multi.length; i++) {
            var value = 0;
            var code = nip.charCodeAt(i);

            if (code > 47 && code < 58) { value = code - 48; }
            else if (code > 64 && code < 91) { value = code - 55 }

            total += multi[i] * value;
        }
        const checkDigit = (total % 11 % 10).toString();
        return (nip.charAt(9) === checkDigit);
    }

    const isPesel = (pesel: string) => {
        // Test pattern
        if (!pesel?.match("^\\d{11}$")) return false;

        // Test check digit
        let multi = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
        var total = 0;
        for (let i = 0; i < multi.length; i++) {
            var value = 0;
            var code = pesel.charCodeAt(i);

            if (code > 47 && code < 58) { value = code - 48; }
            else if (code > 64 && code < 91) { value = code - 55 }

            total += multi[i] * value;
        }
        const checkDigit = (total % 10).toString();
        return (pesel.charAt(10) === checkDigit);
    }

    const isDowOs = (dowOs: string) => {
        // Test pattern
        if (!dowOs?.match("^[A-Z]{3}\\d{6}$")) return false;

        // Test check digit
        let multi = [7, 3, 1, 0, 7, 3, 1, 7, 3];
        var total = 0;
        for (let i = 0; i < multi.length; i++) {
            var value = 0;
            var code = dowOs.charCodeAt(i);

            if (code > 47 && code < 58) { value = code - 48; }
            else if (code > 64 && code < 91) { value = code - 55 }

            total += multi[i] * value;
        }
        const checkDigit = (total % 10).toString();
        return (dowOs.charAt(3) === checkDigit);
    }

    switch (idType?.toUpperCase()) {
        case "NIP":
            return isNip(idNumber!);

        case "PESEL":
            return isPesel(idNumber!);

        case "DO":
            return isDowOs(idNumber!);

        default:
            break;
    }

    return true;
}
