import React, { useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../api/api";
import ServerCommunicationError, { CommunicationErrorType } from "../../common/ServerCommunicationIssue";
import { DataGrid, GridColDef, GridRowData, GridCellParams } from "@material-ui/data-grid";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { Button, Icon, IconButton } from "@material-ui/core";
import { PageContainerDivStyled, PageGridDivStyled } from "../pageStyledComponents";
import { useGridStyles } from "../../common/gridCustomTheme";
import Loader from "../../common/Loader";
import "./admin.css";
import { AdminRoutes } from "../../routes/routeUtils";
import { VWebCarrier } from "../../api/models";

export default function CarriersPage() {
    const [loading, setLoading] = useState<boolean>(false);
    const [communicationError, setCommunicationError] = useState<CommunicationErrorType>();
    const [apiData, setApiData] = useState<VWebCarrier[]>();
    const [pageSize, setPageSize] = useState<number>(20);

    const [deletion, setDeletion] = useState<any>({ showDialog: false, carrierId: undefined });
    const [deleting, setDeleting] = useState<boolean>(false);
    const [delError, setDelError] = useState<any>({ showDialog: false, error: undefined });

    const getCarriers = useCallback(() => {
        setLoading(true);
        setCommunicationError(undefined);
        setApiData(undefined);

        let request: Promise<any>;
        request = API.get("/Carriers")

        request
            .then(response => {
                const respContent: VWebCarrier[] = response.data;
                setApiData(respContent);
            })
            .catch(err => setCommunicationError(err))
            .finally(() => setLoading(false))
    }, []);

    useEffect(() => getCarriers(), [getCarriers]);

    // DELETE Carrier
    const handleCarrierDelete = () => {
        setDeletion({ showDialog: false, carrierId: undefined });
        setDeleting(true);
        setDelError({ showDialog: false, error: undefined });

        console.log(`Deleting carrier: ${deletion?.carrierId}`);

        API.delete(`/Carriers/${deletion?.carrierId}`)
            .then(resp => {
                console.log(resp);
                getCarriers();
            })
            .catch(err => {
                console.log("'err.response':", err.response);
                console.log("Response status: ", err.response.status);
                console.log(err.response.data.errors);
                setDelError({ showDialog: true, error: err.response });
            })
            .finally(() => { setDeleting(false); console.log("Deletion complete") });
    };




    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            width: 54,
            editable: false
        },
        {
            field: "name",
            headerName: "Name",
            width: 240,
            editable: false
        },
        {
            field: "officialId",
            headerName: "Official ID",
            width: 90,
            editable: false,
            renderCell: (params: GridCellParams) =>
                <div className="multi-line-cell">
                    <div>{params.row.idType}:</div>
                    <div>{params.row.idNumber}</div>
                </div>
            ,
        },
        {
            field: "fullAddress",
            headerName: "Full Address",
            width: 210,
            editable: false,
            renderCell: (params: GridCellParams) =>
                <div className="multi-line-cell">
                    <div>{params.row.street}{params.row.houseNo ? (params.row.houseNo?.toUpperCase() === "BRAK" ? "" : ` ${params.row.houseNo}`) : ""}</div>
                    <div>{params.row.postalCode} {params.row.city} ({params.row.country})</div>
                </div>
            ,
        },
        {
            field: "email",
            headerName: "Email",
            width: 180,
            editable: false
        },
        {
            field: "failOverEmail",
            headerName: "Failover Email",
            width: 180,
            editable: false
        },
        {
            field: "action",
            headerName: "Action",
            width: 90,
            disableColumnMenu: true,
            hideSortIcons: true,
            headerAlign: "center",
            align: "center",
            renderCell: (params: GridCellParams) => {
                return (
                    <>
                        <IconButton title="Delete" disabled={params.row.vehicles > 0} onClick={() => setDeletion({ showDialog: true, carrierId: params.id })}>
                            <Icon className="fas fa-trash-alt" />
                        </IconButton>

                        <Link to={AdminRoutes.CarrierEdit.replace(":id", params.id as string)} title="Edit" tabIndex={-1}>
                            <IconButton>
                                <Icon className="fas fa-edit"></Icon>
                            </IconButton>
                        </Link>
                    </>
                );
            }
        }
    ];

    const rows: GridRowData[] = apiData?.map(d => ({
        id: d.id,
        name: d.name,
        idType: d.idType,
        idNumber: d.idNumber,
        street: d.street,
        houseNo: d.houseNo,
        city: d.city,
        postalCode: d.postalCode,
        country: d.country,
        email: d.email,
        failOverEmail: d.failOverEmail,
        vehicles: d.vehicles
    })) ?? [];


    const classes = useGridStyles();
    return (
        <>
            <Loader isLoading={loading || deleting} />
            <PageContainerDivStyled style={{ width: "1080px", margin: "auto" }}>
                <div className="actions">
                    <h3 className="actions-left">Carriers</h3>
                    <div className="actions-right">
                        {!communicationError &&
                            <Link to={AdminRoutes.CarrierAdd} className="link-button">
                                <Button title="Add Carrier" color="primary" tabIndex={-1}><Icon className="fas fa-plus" />New Carrier</Button>
                            </Link>
                        }
                    </div>
                </div>
                {communicationError && <ServerCommunicationError errType={communicationError} />}
                {apiData &&
                    <PageGridDivStyled className="grid-container">
                        <DataGrid
                            className={classes.root}
                            pagination
                            columns={columns}
                            rows={rows}
                            density="standard"
                            // density="compact"
                            showColumnRightBorder={true}
                            showCellRightBorder={false}
                            pageSize={pageSize}
                            onPageSizeChange={(ps) => { setPageSize(ps.pageSize) }}
                            rowsPerPageOptions={[20, 50, 100]}
                            disableSelectionOnClick={true}
                        />
                    </PageGridDivStyled>
                }

                <Dialog id="ask-for-deletion" className="visent" open={deletion?.showDialog}>
                    <DialogTitle>Carrier Deletion</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you want to delete carrier '{deletion?.carrierId}'?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={() => setDeletion({ showDialog: false, carrierId: undefined })}>Cancel</Button>
                        <Button color="primary" onClick={handleCarrierDelete}>OK</Button>
                    </DialogActions>
                </Dialog>

                <Dialog id="deletion-error" className="visent" open={delError?.showDialog}>
                    <DialogTitle>Carrier Deletion Error</DialogTitle>
                    <DialogContent>
                        {delError?.error?.status && <DialogContentText>Response status: {delError?.error?.status}</DialogContentText>}
                        <DialogContentText>Could not delete carrier</DialogContentText>
                        {delError?.error?.data?.errors && <DialogContentText>{delError?.error?.data?.errors}</DialogContentText>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDelError({ ...delError, showDialog: false })}>Close</Button>
                    </DialogActions>
                </Dialog>

            </PageContainerDivStyled>
        </>
    );
}