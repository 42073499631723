import styled from "styled-components";

export const FlexDivStyled = styled.div`
  display: flex;
`;

interface StatusIconDivStyledProps {
  size: number;
  iconColor: string;
}
export const StatusIconDivStyled = styled.div`
  width: ${({ size }: StatusIconDivStyledProps) => `${size}px`};
  height: ${({ size }: StatusIconDivStyledProps) => `${size}px`};
  background-color: ${({ iconColor }: StatusIconDivStyledProps) => iconColor};
  border-radius: 50%;
`;

interface IconIStyledProps {
  color?: string;
  size?: number;
}
export const IconIStyled = styled.i`
  color: ${({ color }: IconIStyledProps) => color};
  font-size: ${({ size }: IconIStyledProps) => (size ? `${size}px` : undefined)};
`;

export const NotificationBoxContainerFlexDivStyled = styled.div`
  minheight: 100px;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
