/*
 * -----------------------------------------------------------------
 * ## Contents of this file can be generated automatically        ##
 * ## based on a SWAGGER file by runing a command:                ##
 * ## > npx swagger-typescript-api -p swagger.json -n models.tsx  ##
 * -----------------------------------------------------------------
*/

export interface AdUser {
  "@odata.type"?: string | null;
  id?: string | null;
  userPrincipalName?: string | null;
  mail?: string | null;
  displayName?: string | null;
  givenName?: string | null;
  surname?: string | null;
}

export enum AdminAction {
  Create = "Create",
  Update = "Update",
  Delete = "Delete",
}

export enum AdminChangeObject {
  SystemSettings = "SystemSettings",
  Vehicle = "Vehicle",
  Carrier = "Carrier",
  Depot = "Depot",
  Supplier = "Supplier",
  ApplicationUser = "ApplicationUser",
}

export interface AdminLogEntry {
  id?: string | null;
  objectType?: AdminChangeObject;
  action?: AdminAction;
  userId?: string | null;
  userName?: string | null;
  before?: any;
  after?: any;
}

export interface AppUser {
  userPrincipalName: string;
  firstName?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  assignments?: AppUserAssignment[] | null;
}

export interface AppUserAssignment {
  integrationPartnerId?: string | null;
  plannerId?: string | null;
  enabled?: boolean | null;
}

export interface ApplicationUser {
  userPrincipalName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  authorizedPlanners?: AuthorizedPlanner[] | null;
}

export interface AuthorizedPlanner {
  integrationPartnerId?: string | null;
  plannerId?: string | null;
  userPrincipalName?: string | null;
  enabled?: boolean | null;
  integrationPartner?: IntegrationPartner;
  userPrincipalNameNavigation?: ApplicationUser;
}

export interface AutomationStatus {
  automationEnabled?: boolean;
  communicationChannel?: PuescChannel;
}

export interface CarrierInfo {
  /** @format int32 */
  id?: number | null;
  name?: string | null;
  idType?: string | null;
  idNumber?: string | null;
  street?: string | null;
  houseNumber?: string | null;
  city?: string | null;
  postalCode?: string | null;
  country?: string | null;
  email?: string | null;
  failOverEmail?: string | null;
}

export interface CarrierKit {
  carrier?: VWebCarrier;
  idTypes?: IdTypeSimple[] | null;
}

export interface CarrierSimple {
  /** @format int32 */
  carrierId: number;
  carrierName?: string | null;
}

export interface ClientConfig {
  clientErrorMessage?: string | null;
  serverErrorMessage?: string | null;
}

export interface CustomerInfo {
  /** @format int32 */
  id?: number | null;
  name?: string | null;
  idType?: string | null;
  idNumber?: string | null;
  street?: string | null;
  houseNumber?: string | null;
  city?: string | null;
  postalCode?: string | null;
  country?: string | null;
}

export interface DepotInfo {
  /** @format int32 */
  id?: number | null;
  name?: string | null;

  /** @format int32 */
  supplierId?: number | null;
  supplierName?: string | null;
  street?: string | null;
  houseNumber?: string | null;
  city?: string | null;
  postalCode?: string | null;
  country?: string | null;
  district?: string | null;

  /** @format double */
  lat?: number | null;

  /** @format double */
  lon?: number | null;
}

export interface DepotKit {
  depot?: DepotWeb;
  suppliers?: SupplierSimple[] | null;
}

export interface DepotWeb {
  /** @format int32 */
  id: number;

  /** @format int32 */
  supplierId: number;
  name: string;
  street: string;
  houseNumber?: string | null;
  city: string;
  postalCode?: string | null;

  /** @pattern [A-Z][A-Z] */
  country?: string | null;
  district?: string | null;
}

export enum EntryAppliesTo {
  Order = "Order",
  Trip = "Trip",
}

export interface FilterCriteria {
  /** @format date-time */
  dateFrom?: string | null;

  /** @format date-time */
  dateTo?: string | null;
  tripSourceId?: string | null;
  tripId?: string | null;
  orderId?: string | null;
  sentNumber?: string | null;
  orderVmi?: boolean | null;
  vehicleId?: string | null;
  orderStatus?: OrderStatus[] | null;
  sentStatus?: SentStatus[] | null;
  sentTaskStatus?: SentTaskStatus[] | null;
}

export interface History {
  tripSourceId?: string | null;
  tripId?: string | null;
  orderId?: string | null;
  entries?: HistoryItem[] | null;
}

export interface HistoryItem {
  /** @format date-time */
  timestamp?: string;
  appliesTo?: EntryAppliesTo;
  isError?: boolean;
  isSent?: boolean;
  description?: string | null;
}

export interface IdTypeSimple {
  typeId?: string | null;
  typeName?: string | null;
  sentOil?: boolean;
}

export interface InfoAboutControl {
  tripSourceId?: string | null;
  tripId?: string | null;
  infosAboutControl?: InfoAboutControlItem[] | null;
}

export interface InfoAboutControlItem {
  /** @format date-time */
  timestamp?: string | null;
  orderId?: string | null;
  placeOfControl?: string | null;
  recommendationsForControl?: string | null;
}

export interface IntegrationPartner {
  integrationPartnerId?: string | null;
  integrationPartnerName?: string | null;
  integrationPartnerType?: string | null;
  integrationMethod?: string | null;
  integrationPartnerEmail?: string | null;
  integrationPartnerHttpUri?: string | null;
  integrationPartnerHttpMethod?: string | null;
  integrationPartnerHttpHdr?: string | null;
  integrationPartnerEnabled?: boolean | null;

  /** @format date-time */
  integrationPartnerCreated?: string;
  integrationPartnerCreatedBy?: string | null;

  /** @format date-time */
  integrationPartnerModified?: string | null;
  integrationPartnerModifiedBy?: string | null;
  authorizedPlanners?: AuthorizedPlanner[] | null;
}

export interface IntegrationPartnerSimple {
  partnerId: string;
  partnerName?: string | null;
}

export interface LogError {
  userName?: string | null;
  source?: string | null;
  errorMessage?: string | null;
  errorStack?: string | null;
  componentStack?: string | null;
}

export interface OfficialInfo {
  tripSourceId?: string | null;
  tripId?: string | null;
  officialInfos?: OfficialInfoItem[] | null;
}

export interface OfficialInfoItem {
  /** @format date-time */
  timestamp?: string | null;
  infoType?: string | null;
  infoRecipient?: string | null;
  infoName?: string | null;
  infoValue?: string | null;
  infoDescription?: string | null;
}

export interface OrderDetails {
  tripSourceId?: string | null;
  tripId?: string | null;
  orderId?: string | null;
  trip?: TripInfo;
  order?: OrderInfo;
  sent?: SentInfo;
  depot?: DepotInfo;
  vehicle?: VehicleInfo;
  carrier?: CarrierInfo;
  shipTo?: ShipToInfo;
  customer?: CustomerInfo;
}

export interface OrderFilter {
  filter?: FilterCriteria;
}

export interface OrderInfo {
  vmi?: boolean;
  productType?: string | null;

  /** @format double */
  plannedAmount?: number;

  /** @format double */
  actualAmount?: number | null;
  status?: OrderStatus;
  isCompliant?: boolean | null;
  typeOfNonCompliance?: string | null;
  gasPumpNumber?: string | null;

  /** @format double */
  gasPumpMeter?: number | null;
}

export interface OrderList {
  listType?: OrderListType;
  hasMore?: boolean;
  orders?: WebOrder[] | null;
}

export enum OrderListType {
  Fuel = "Fuel",
  LPG = "LPG",
}

export enum OrderStatus {
  Planned = "Planned",
  Closed = "Closed",
  Cancelled = "Cancelled",
}

export interface OrdersToProcess {
  tripSourceId: string;
  tripId: string;
  orderId: string;
}

export interface PlannerKit {
  appUser?: AppUser;
  integrationPartners?: IntegrationPartnerSimple[] | null;
}

export enum PuescChannel {
  WebService = "WebService",
  Email = "Email",
}

export interface ResponseBody {
  type?: string | null;
  title?: string | null;

  /** @format int32 */
  status?: number;

  /** @format uuid */
  traceId?: string;
  errors?: any;
  messages?: any;
}

export interface SentInfo {
  sentProcess?: string | null;
  multipleSentNumber?: string | null;
  sentNumber?: string | null;
  senderKey?: string | null;
  carrierKey?: string | null;
  recipientKey?: string | null;
  sentStatus?: string | null;
  account?: string | null;
}

export enum SentStatus {
  Registered = "Registered",
  Ready = "Ready",
  Closed = "Closed",
  Cancelled = "Cancelled",
  Expired = "Expired",
  Emergency = "Emergency",
  None = "None",
}

export enum SentTaskStatus {
  None = "None",
  Enqueued = "Enqueued",
  Failed = "Failed",
}

export interface ShipToInfo {
  /** @format int32 */
  id?: number | null;
  street?: string | null;
  houseNumber?: string | null;
  city?: string | null;
  postalCode?: string | null;
  district?: string | null;
  email?: string | null;

  /** @format double */
  lat?: number | null;

  /** @format double */
  lon?: number | null;
}

export interface SupplierKit {
  supplier?: VWebSupplier;
  idTypes?: IdTypeSimple[] | null;
}

export interface SupplierSimple {
  /** @format int32 */
  supplierId: number;
  supplierName?: string | null;
}

export interface SystemSettings {
  automationStatus?: boolean | null;
  communicationChannel?: PuescChannel;
}

export interface TaskErrors {
  tripSourceId?: string | null;
  tripId?: string | null;
  errors?: TaskErrorsItem[] | null;
}

export interface TaskErrorsItem {
  appliesTo?: EntryAppliesTo;
  actionKey?: string | null;

  /** @format int64 */
  taskId?: number;
  inSent?: boolean;
  onHold?: boolean;

  /** @format date-time */
  firstAttemptAt?: string | null;

  /** @format date-time */
  lastAttemptAt?: string | null;

  /** @format int32 */
  attemptCount?: number;
  errCategory?: string | null;
  type?: string | null;
  code?: string | null;
  desc?: string | null;
}

export interface TripInfo {
  /** @format date-time */
  plannedStartDate?: string | null;

  /** @format date-time */
  startTransportDate?: string | null;

  /** @format date-time */
  endTransportDate?: string | null;

  /** @format int32 */
  orderCount?: number;

  /** @format double */
  totalAmount?: number;
}

export interface UpdateSent {
  tripSourceId: string;
  tripId: string;
  orderId: string;

  /** @pattern [S][E][N][T][Z][B][1234567890]{8}[1234567890]{6} */
  multipleSentNumber?: string | null;

  /** @pattern [S][E][N][T][1234567890]{8}[1234567890]{6} */
  sentNumber?: string | null;

  /** @pattern [K][S][-][123456789]{2}[XLANTCYDRUEFZPHMJSKW]{2} */
  senderKey?: string | null;

  /** @pattern [K][D][-][123456789]{2}[XLANTCYDRUEFZPHMJSKW]{2} */
  carrierKey?: string | null;

  /** @pattern [K][R][-][123456789]{2}[XLANTCYDRUEFZPHMJSKW]{2} */
  recipientKey?: string | null;
}

export interface VWebCarrier {
  /** @format int32 */
  id?: number | null;
  name?: string | null;
  idType?: string | null;
  idNumber?: string | null;
  street?: string | null;
  houseNo?: string | null;
  city?: string | null;
  postalCode?: string | null;
  country?: string | null;
  email?: string | null;
  failOverEmail?: string | null;

  /** @format int32 */
  vehicles?: number | null;
}

export interface VWebSupplier {
  /** @format int32 */
  id?: number | null;
  name?: string | null;
  idType?: string | null;
  idNumber?: string | null;
  street?: string | null;
  houseNo?: string | null;
  city?: string | null;
  postalCode?: string | null;
  country?: string | null;
  email?: string | null;
  own?: boolean | null;
  sentCreator?: boolean | null;
  sent120Sender?: boolean | null;
  canBeOwn?: boolean | null;
  canSent120?: boolean | null;

  /** @format int32 */
  depots?: number | null;
}

export interface VehicleInfo {
  id?: string | null;
  truckNumber?: string | null;
  trailerNumber?: string | null;

  /** @format int32 */
  carrierId?: number | null;
  permitRoad?: string | null;
  geoLocNo?: string | null;
  failOverGeoLocNo?: string | null;
}

export interface VehicleItem {
  vehicleId: string;
  permitRoad?: string | null;
  geoLocNo?: string | null;
  failOverGeoLocNo?: string | null;
  carrier: CarrierSimple;
}

export interface VehicleKit {
  vehicle?: VehicleItem;
  carriers?: CarrierSimple[] | null;
}

export interface WebOrder {
  isCancelled?: boolean;
  orderStatus?: OrderStatus;
  isSelectionEnabled?: boolean;
  isSentDataEditable?: boolean;
  tripSourceId: string;
  tripId: string;
  orderId: string;
  shiftId?: string | null;
  vehicleId?: string | null;

  /** @format date-time */
  plannedStartDate?: string;
  multipleSentNumber?: string | null;
  sentNumber?: string | null;
  senderKey?: string | null;
  carrierKey?: string | null;
  recipientKey?: string | null;
  hasInfoAboutControl?: boolean;
  hasOfficialInfo?: boolean;
  hasTaskErrors?: boolean;
  closedIncompliant?: boolean;
  sentStatus?: SentStatus;
  sentTaskStatus?: SentTaskStatus;
  emergencyEligible?: boolean
}
