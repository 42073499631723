import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../api/api";
import ServerCommunicationError, { CommunicationErrorType } from "../../common/ServerCommunicationIssue";
import { VehicleItem } from "../../api/models";
import { DataGrid, GridColDef, GridRowData, GridValueGetterParams, GridCellParams } from "@material-ui/data-grid";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { Button, Icon, IconButton } from "@material-ui/core";
import { PageContainerDivStyled, PageGridDivStyled } from "../pageStyledComponents";
import { useGridStyles } from "../../common/gridCustomTheme";
import Loader from "../../common/Loader";
import "./admin.css";
import { AdminRoutes } from "../../routes/routeUtils";



// function getThemePaletteMode(palette: any): string {
//   return palette.type || palette.mode;
// }

// const defaultTheme = createTheme();
// const useStyles = makeStyles(
//   (theme: Theme) => {
//     const isDark = getThemePaletteMode(theme.palette) === 'dark';

//     return{
//       root:{
//         '& .MuiDataGrid-cell--editing':{
//           backgroundColor:'rgb(255,215,115, 0.19)',
//           color:'#1a3e72'
//         },
//         '& .Mui-error':{
//           backgroundColor:'rgb(255,215,115, 0.19)',
//           color:'#1a3e72'
//         }
//       }
//     }
//   },
//   {defaultTheme}
// );

export default function VehiclesPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [communicationError, setCommunicationError] = useState<CommunicationErrorType>();
  const [apiData, setApiData] = useState<VehicleItem[]>();
  const [pageSize, setPageSize] = useState<number>(20);

  const [deletion, setDeletion] = useState<any>({ showDialog: false, vehicleId: undefined });
  const [deleting, setDeleting] = useState<boolean>(false);
  const [delError, setDelError] = useState<any>({ showDialog: false, error: undefined });

  const getVehicles = useCallback(() => {
    setLoading(true);
    setCommunicationError(undefined);
    setApiData(undefined);

    let request: Promise<any>;
    request = API.get("/Vehicles")

    return request
      .then(response => {
        const respContent: VehicleItem[] = response.data;
        //setLoading(false);
        setApiData(respContent);
      })
      .catch(err => setCommunicationError(err))
      .finally(() => setLoading(false))
  }, []);

  useEffect(() => { getVehicles(); }, [getVehicles]);


  // DELETE Vehicle
  const handleVehicleDelete = () => {
    setDeletion({ showDialog: false, vehicleId: undefined });
    setDeleting(true);
    setDelError({ showDialog: false, error: undefined });

    console.log(`Deleting vehicle: ${deletion?.vehicleId}`);

    API.delete(`/Vehicles/${deletion?.vehicleId}`)
      .then(resp => {
        console.log(resp);
        getVehicles();
      })
      .catch(err => {
        console.log("'err.response':", err.response);
        console.log("Response status: ", err.response.status);
        console.log(err.response.data.errors);
        setDelError({ showDialog: true, error: err.response });
      })
      .finally(() => { setDeleting(false); console.log("Deletion complete") });
  };




  const columns: GridColDef[] = [
    {
      field: "vehicleId",
      headerName: "ID",
      width: 64,
      editable: false
    },
    {
      field: "permitRoad",
      headerName: "Permit Road",
      width: 120,
      editable: false
    },
    {
      field: "geoLocNo",
      headerName: "Geolocation No",
      width: 120,
      editable: false

    },
    {
      field: "failOverGeoLocNo",
      headerName: "Failover Geoloc.",
      width: 120,
      editable: false
    },
    {
      field: "carrier",
      headerName: "Carrier",
      width: 350,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => `${params.row.carrierId} ${params.row.carrierName}`
    },
    {
      field: "action",
      headerName: "Action",
      width: 90,
      disableColumnMenu: true,
      hideSortIcons: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <IconButton title="Delete" onClick={() => setDeletion({ showDialog: true, vehicleId: params.id })}>
              <Icon className="fas fa-trash-alt" />
            </IconButton>

            <Link to={AdminRoutes.VehicleEdit.replace(":id", params.id as string)} title="Edit" tabIndex={-1}>
              <IconButton>
                <Icon className="fas fa-edit"></Icon>
              </IconButton>
            </Link>
          </>
        );
      }
    }
  ];

  const rows: GridRowData[] = apiData?.map(v => ({
    id: v.vehicleId,
    vehicleId: v.vehicleId,
    permitRoad: v.permitRoad,
    geoLocNo: v.geoLocNo,
    failOverGeoLocNo: v.failOverGeoLocNo,
    carrierId: v.carrier.carrierId,
    carrierName: v.carrier.carrierName
  })) ?? [];


  const classes = useGridStyles();
  return (
    <>
      <Loader isLoading={loading || deleting} />
      <PageContainerDivStyled style={{ width: "890px", margin: "auto" }}>
        <div className="actions">
          <h3 className="actions-left">Vehicles</h3>
          <div className="actions-right">
            {!communicationError &&
              <Link to={AdminRoutes.VehicleAdd} className="link-button">
                <Button title="Add Vehicle" color="primary" tabIndex={-1}><Icon className="fas fa-plus" />New Vehicle</Button>
              </Link>
            }
          </div>
        </div>
        {communicationError && <ServerCommunicationError errType={communicationError} />}
        {apiData &&
          <PageGridDivStyled className="grid-container">
            <DataGrid
              className={classes.root}
              pagination
              columns={columns}
              rows={rows}
              density="compact"
              showColumnRightBorder={true}
              showCellRightBorder={false}
              pageSize={pageSize}
              onPageSizeChange={(ps) => { setPageSize(ps.pageSize) }}
              rowsPerPageOptions={[20, 50, 100]}
              disableSelectionOnClick={true}
            />
          </PageGridDivStyled>
        }

        <Dialog id="ask-for-deletion" className="visent" open={deletion?.showDialog}>
          <DialogTitle>Vehicle Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete vehicle '{deletion?.vehicleId}'?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setDeletion({ showDialog: false, vehicleId: undefined })}>Cancel</Button>
            <Button color="primary" onClick={handleVehicleDelete}>OK</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="deletion-error" className="visent" open={delError?.showDialog}>
          <DialogTitle>Vehicle Deletion Error</DialogTitle>
          <DialogContent>
            {delError?.error?.status && <DialogContentText>Response status: {delError?.error?.status}</DialogContentText>}
            <DialogContentText>Could not delete vehicle</DialogContentText>
            {delError?.error?.data?.errors && <DialogContentText>{delError?.error?.data?.errors}</DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDelError({ ...delError, showDialog: false })}>Close</Button>
          </DialogActions>
        </Dialog>

      </PageContainerDivStyled>
    </>
  );
}


