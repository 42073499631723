import React from "react";
import { getOrderStatusText, orderStatusesOrdered, orderStatusIconRenderer, tooltipRow } from "../../services/statusesRendering";
import GridHeaderIcon from "../gridTooltips/GridHeaderIcon";
import GridHeaderTooltip from "../gridTooltips/GridHeaderTooltip";
import { TooltipHeaderDivStyled } from "./styledComponents";

export function CellHeaderOrderStatusComponent() {
  const iconWidth = 15;
  const iconSize = 18;
  return (
    <GridHeaderTooltip
      tooltipContent={
        <div>
          <TooltipHeaderDivStyled>Order status</TooltipHeaderDivStyled>
          {orderStatusesOrdered.map((x) => {
            return (
              <React.Fragment key={x}>{tooltipRow(orderStatusIconRenderer(x, iconSize), getOrderStatusText(x), iconWidth)}</React.Fragment>
            );
          })}
        </div>
      }>
      <GridHeaderIcon></GridHeaderIcon>
    </GridHeaderTooltip>
  );
}
