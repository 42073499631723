import styled from "styled-components";
import { appColors } from "../common/colors";

export const PageContainerDivStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageGridDivStyled = styled.div`
  flex-grow: 1;
  width: 100%;
  background-color: ${appColors.white};
`;
