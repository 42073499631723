import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import Header from "./common/Header";
import Footer from "./common/Footer";
import FuelDeliveriesPage from "./pages/FuelDeliveriesPage";
import LpgDeliveriesPage from "./pages/LpgDeliveriesPage";
import { ErrorBoundary } from "./common/ErrorBoundary";
import { getClientConfigFromServer } from "./api/configuration";
import styled from "styled-components";
import "date-fns";
import { appColors } from "./common/colors";
import { msalConfig } from "./authConfig";
import NotificationBox, { NotificationBoxType } from "./common/Notification";
import { MissingRightsToAccess, LpgFuelRoutes, AdminRoutes, AnyUserRoutes } from "./routes/routeUtils";
import fuelLpgGuard from "./routes/routerGuards/lpgFuelGuard";
import adminGuard from "./routes/routerGuards/adminGuard";
import anyUserGuard from "./routes/routerGuards/anyUserGuard";
import AcessForbidenPage from "./pages/AcessForbidenPage";
import UserInfoPage from "./pages/UserInfoPage";
import AdminPage from "./pages/admin/AdminPage";
import SystemSettingsPage from "./pages/admin/SystemSettingsPage";
import VehiclesPage from "./pages/admin/VehiclesPage";
import VehicleEdit from "./pages/admin/VehicleEdit";
import CarriersPage from "./pages/admin/CarriersPage";
import CarrierEdit from "./pages/admin/CarrierEdit";
import AdminHistoryPage from "./pages/admin/AdminHistoryPage";
import SuppliersPage from "./pages/admin/SuppliersPage";
import SupplierEdit from "./pages/admin/SupplierEdit";
import UsersPage from "./pages/admin/UsersPage";
import UserEdit from "./pages/admin/UserEdit";
import DepotsPage from "./pages/admin/DepotsPage";
import DepotEdit from "./pages/admin/DepotEdit";
import PlannerPage from "./pages/admin/PlannerPage";

const AppDivStyled = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 1280px;
  align-self: center;
`;

const AppContentMainStyled = styled.main`
  flex-grow: 1;
  margin-top: 105px;
  margin-bottom: 40px;
  padding: 10px;
  background-color: ${appColors.lightGray};
`;

const UnauthenticatedTemplateDivStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const theme = createMuiTheme({
  typography: {
    fontSize: 11,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      dark: `${appColors.orange}`,
      main: `${appColors.orange}`,
    },
    secondary: {
      dark: `${appColors.blue}`,
      main: `${appColors.blue}`,
    },
  },
  overrides: {
    MuiDialogTitle: {
      root: {
        backgroundColor: appColors.gray,
        borderBottom: `1px solid ${appColors.orange}`,
        padding: "0px 24px",
      },
    },
    MuiDialogContent: {
      root: {
        borderBottom: `1px solid ${appColors.orange}`,
        padding: "2px 12px",
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: appColors.gray,
        padding: "4px 8px",
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: "2px",
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: "rgba(0, 0, 0, 0.84)",
      },
    },
  },
});

function AppContent() {
  const isUserAuthenticated = useIsAuthenticated();
  React.useEffect(() => {
    if (isUserAuthenticated) {
      getClientConfigFromServer();

      const fiveMinutes = 5 * 60 * 1000;
      const interval = setInterval(() => getClientConfigFromServer(), fiveMinutes);
      return () => clearInterval(interval);
    }
  }, [isUserAuthenticated]);

  return (
    <AppDivStyled>
      <Router>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ErrorBoundary>
              <>
                <Header />
                <AppContentMainStyled>
                  <AuthenticatedTemplate>
                    <GuardProvider guards={[fuelLpgGuard, adminGuard, anyUserGuard]}>
                      <Switch>
                        <GuardedRoute path={AnyUserRoutes.UserInfo}>
                          <UserInfoPage />
                        </GuardedRoute>
                        <GuardedRoute path={LpgFuelRoutes.Fuel}>
                          <FuelDeliveriesPage />
                        </GuardedRoute>
                        <GuardedRoute path={LpgFuelRoutes.Lpg}>
                          <LpgDeliveriesPage />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.SystemSettings}>
                          <SystemSettingsPage />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.VehicleEdit}>
                          <VehicleEdit />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.VehicleAdd}>
                          <VehicleEdit />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.Vehicles}>
                          <VehiclesPage />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.Carriers}>
                          <CarriersPage />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.CarrierEdit}>
                          <CarrierEdit />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.CarrierAdd}>
                          <CarrierEdit />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.Depots}>
                          <DepotsPage />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.DepotEdit}>
                          <DepotEdit />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.DepotAdd}>
                          <DepotEdit />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.Suppliers}>
                          <SuppliersPage />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.SupplierEdit}>
                          <SupplierEdit />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.SupplierAdd}>
                          <SupplierEdit />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.UserEdit}>
                          <UserEdit />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.UserAdd}>
                          <UserEdit />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.Users}>
                          <UsersPage />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.Planner}>
                          <PlannerPage />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.AdminHistory}>
                          <AdminHistoryPage />
                        </GuardedRoute>
                        <GuardedRoute path={AdminRoutes.AdminPage}>
                          <AdminPage />
                        </GuardedRoute>
                        <GuardedRoute path={MissingRightsToAccess}>
                          <AcessForbidenPage />
                        </GuardedRoute>
                        <GuardedRoute path="*">
                          <Redirect to={LpgFuelRoutes.Fuel} />
                        </GuardedRoute>
                      </Switch>
                    </GuardProvider>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <UnauthenticatedTemplateDivStyled>
                      <NotificationBox
                        type={NotificationBoxType.Warning}
                        message="To continue please sign in using button in top right corner"></NotificationBox>
                    </UnauthenticatedTemplateDivStyled>
                  </UnauthenticatedTemplate>
                </AppContentMainStyled>
              </>
            </ErrorBoundary>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Router>
      <Footer />
    </AppDivStyled>
  );
}

export const msalInstance = new PublicClientApplication(msalConfig);
export default function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <AppContent />
    </MsalProvider>
  );
}
