import React, { SyntheticEvent, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AdminRoutes } from "../../routes/routeUtils";
import API from "../../api/api";
import ServerCommunicationError, { CommunicationErrorType } from "../../common/ServerCommunicationIssue";
import { Button, Icon, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { PageContainerDivStyled } from "../pageStyledComponents";
import { validateIdNumber, validatePostalCode } from "../../services/miscellaneous";
import Loader from "../../common/Loader";
import { VWebCarrier, CarrierKit, IdTypeSimple } from "../../api/models";


export default function CarrierEdit() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [carrierEdited, setCarrierEdited] = useState<VWebCarrier>();
  const [carrierLoaded, setCarrierLoaded] = useState<VWebCarrier>();
  const [carrierLoading, setCarrierLoading] = useState<boolean>(false);
  const [carrierError, setCarrierError] = useState<CommunicationErrorType>();
  const [idTypes, setIdTypes] = useState<IdTypeSimple[]>();

  const [carrierSaving, setCarrierSaving] = useState<boolean>(false);
  const [carrierSaveError, setCarrierSaveError] = useState<any>();
  const [carrierSaveSuccess, setCarrierSaveSuccess] = useState<boolean>();
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [noChangesDialogOpen, setNoChangesDialogOpen] = useState<boolean>(false);

  const [anyChange, setAnyChange] = useState<boolean>(false);
  const [isAllValid, setIsAllValid] = useState<boolean>(true);
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState(false);
  const [isPostalCodeValid, setIsPostalCodeValid] = useState<boolean>(true);
  const [isIdNumberValid, setIsIdNumberValid] = useState<boolean>(true);


  useEffect(() => {
    setCarrierLoading(true);
    setCarrierError(undefined);

    console.log("Retrieving carrier kit...");
    let req: Promise<any>;
    req = API.get(`/Carriers/CarrierKit/${id || '0'}`);
    req.then(resp => {
      let respContent: CarrierKit = resp.data;
      let tmpCarrier: VWebCarrier = {
        id: NaN,
        name: '',
        idType: '',
        idNumber: '',
        street: '',
        houseNo: '',
        city: '',
        postalCode: '',
        country: 'PL',
        email: '',
        failOverEmail: '',
      };

      if (id && respContent.carrier) tmpCarrier = respContent?.carrier!;

      setCarrierLoaded(tmpCarrier);
      setCarrierEdited(tmpCarrier);
      setIdTypes(respContent?.idTypes || []);
    })
      .catch(err => setCarrierError(err))
      .finally(() => setCarrierLoading(false))
  }, [id]);


  useEffect(() => {
    // Discover changes
    let hasChanged: boolean =
      Number.isFinite(carrierLoaded?.id) !== Number.isFinite(carrierEdited?.id) ||
      carrierLoaded?.id !== carrierEdited?.id ||
      carrierLoaded?.name !== carrierEdited?.name ||
      carrierLoaded?.idType !== carrierEdited?.idType ||
      carrierLoaded?.idNumber !== carrierEdited?.idNumber ||
      carrierLoaded?.street !== carrierEdited?.street ||
      carrierLoaded?.houseNo !== carrierEdited?.houseNo ||
      carrierLoaded?.city !== carrierEdited?.city ||
      carrierLoaded?.postalCode !== carrierEdited?.postalCode ||
      carrierLoaded?.country !== carrierEdited?.country ||
      carrierLoaded?.email !== carrierEdited?.email ||
      carrierLoaded?.failOverEmail !== carrierEdited?.failOverEmail;

    // Validate postal code
    let isCodeOk: boolean = validatePostalCode(carrierEdited?.postalCode, carrierEdited?.country);

    // Validate ID number
    let isIdNumberOk: boolean = validateIdNumber(carrierEdited?.idType, carrierEdited?.idNumber);

    // Validate form data
    let isAllOk: boolean = true;
    if (!Number.isFinite(carrierEdited?.id)) isAllOk = false;
    if (!carrierEdited?.name?.trim()) isAllOk = false;
    if (!carrierEdited?.idType?.trim()) isAllOk = false;
    if (!validateIdNumber(carrierEdited?.idType, carrierEdited?.idNumber, false)) isAllOk = false;
    if (!carrierEdited?.street?.trim()) isAllOk = false;
    if (!carrierEdited?.city?.trim()) isAllOk = false;
    if (!validatePostalCode(carrierEdited?.postalCode, carrierEdited?.country, false)) isAllOk = false;
    if (!carrierEdited?.country?.trim()) isAllOk = false;

    // Memorize state
    setIsAllValid(isAllOk);
    setAnyChange(hasChanged);
    setIsPostalCodeValid(isCodeOk);
    setIsIdNumberValid(isIdNumberOk);

    console.log(`isAllOk: ${isAllOk}\nhasChanged: ${hasChanged}\nisCodeOk: ${isCodeOk}\nisIdNumberOk: ${isIdNumberOk}`);
    if (hasChanged) {
      console.log("Loaded:", carrierLoaded);
      console.log("Edited:", carrierEdited);
    }
  }, [carrierEdited, carrierLoaded]);


  // Submit data
  function handleSubmit(event: SyntheticEvent | null) {
    event?.preventDefault();

    console.log("Initial Carrier:", carrierLoaded);
    console.log("Modified Carrier:", carrierEdited);
    console.log("Posting to API...")

    setCarrierSaving(true);
    setCarrierSaveError(undefined);
    let request: Promise<any>;
    if (id) {
      request = API.put("/Carriers", carrierEdited);
    }
    else {
      request = API.post("/Carriers", carrierEdited);
    }

    request
      .then(resp => {
        console.log("Successfully posted");
        console.log(resp);
        setCarrierSaveSuccess(true);
      })
      .catch(err => {
        setCarrierSaveError(err.response);
        console.log("Ooops! An error occurred.")
        console.log(err.response);
        console.log("Errors:", err.response?.data?.errors);
        setErrorDialogOpen(true);
      })
      .finally(() => setCarrierSaving(false));

  };


  // Close without saving
  const handleClose = (confirmToClose: boolean = true) => {
    if (confirmToClose && anyChange && isAllValid)
      setConfirmCloseDialogOpen(true);
    else
      history.push(AdminRoutes.Carriers);
  }


  // Redirect to 'Carriers' after successful save
  useEffect(() => { if (carrierSaveSuccess) history.push(AdminRoutes.Carriers) }, [history, carrierSaveSuccess]);

  return (
    <>
      <Loader isLoading={carrierSaving || carrierLoading} />

      <PageContainerDivStyled >

        <div className="edit-main" style={{ width: "500px" }}>
          <div className="edit-header">{id ? "Edit Carrier" : "Add Carrier"}</div>
          <div className="edit-contents">

            {!carrierError &&
              <form onSubmit={handleSubmit}>

                <div style={{ display: "flex", justifyContent: "space-between" }}>

                  <div style={{ width: "6em" }}>
                    <label htmlFor="carrierId">Carrier ID</label>
                    {!id && <input type="number" id="carrierId" name="carrierId" className="visent" style={{ width: "5em"}} value={carrierEdited?.id || NaN} required onChange={(e) => setCarrierEdited({ ...carrierEdited, id: parseInt(e.target.value) } as VWebCarrier)} />}
                    {id && <input type="number" id="carrierId" name="carrierId" className="visent" style={{ width: "5em"}} defaultValue={id} required disabled />}
                  </div>

                  <div style={{ width: "26em" }}>
                    <label htmlFor="carrierName">Name</label>
                    <input type="text" id="carrierName" name="carrierName" className="visent" style={{ width: "25em" }} value={carrierEdited?.name || ''} required onChange={(e) => setCarrierEdited({ ...carrierEdited, name: e.target.value } as VWebCarrier)} />
                  </div>

                </div>


                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "22em" }}>
                    <label htmlFor="idType">ID Type</label>
                    <select id="idType" name="idType" className="visent" style={{ width: "21em" }} required value={carrierEdited?.idType?.toString()} onChange={(e) => setCarrierEdited({ ...carrierEdited, idType: e.target.value } as VWebCarrier)}>
                      <option key="-" defaultValue="-">--pick ID type--</option>
                      {idTypes?.map((s) => (<option key={s.typeId?.toString()} value={s.typeId?.toString()} >{`${s.typeId} - ${s.typeName}`}</option>))}
                    </select>
                  </div>

                  <div style={{ width: "10em" }}>
                    <label htmlFor="idNumber">ID Number</label>
                    <input type="text" id="idNumber" name="idNumber" className={isIdNumberValid ? "visent" : "visent edit-input-error"} style={{ maxWidth: "9em" }} required value={carrierEdited?.idNumber || ''} onChange={(e) => setCarrierEdited({ ...carrierEdited, idNumber: e.target.value })} />
                  </div>
                </div>


                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "22em" }}>
                    <label htmlFor="street">Street</label>
                    <input type="text" id="street" name="street" className="visent" style={{ width: "21em" }} required value={carrierEdited?.street || ''} onChange={(e) => setCarrierEdited({ ...carrierEdited, street: e.target.value } as VWebCarrier)} />
                  </div>

                  <div style={{ width: "10em" }}>
                    <label htmlFor="houseNo">House Number</label>
                    <input type="text" id="houseNo" name="houseNo" className="visent" style={{ maxWidth: "9em" }} value={carrierEdited?.houseNo || ''} onChange={(e) => setCarrierEdited({ ...carrierEdited, houseNo: e.target.value } as VWebCarrier)} />
                  </div>
                </div>


                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ maxWidth: "7em" }}>
                    <label htmlFor="postalCode">Postal Code</label>
                    <input type="text" id="postalCode" name="postalCode" className={isPostalCodeValid ? "visent" : "visent edit-input-error"} style={{ maxWidth: "6em" }} value={carrierEdited?.postalCode || ''} required onChange={(e) => setCarrierEdited({ ...carrierEdited, postalCode: e.target.value } as VWebCarrier)} />
                  </div>

                  <div style={{ width: "14em" }}>
                    <label htmlFor="city">City</label>
                    <input type="text" id="city" name="city" className="visent" style={{ width: "13em" }} value={carrierEdited?.city || ''} required onChange={(e) => setCarrierEdited({ ...carrierEdited, city: e.target.value })} />
                  </div>

                  <div style={{ width: "10em" }}>
                    <label htmlFor="country">Country</label>
                    <select id="country" name="country" className="visent" style={{ maxWidth: "11em" }} value={carrierEdited?.country || ''} required onChange={(e) => setCarrierEdited({ ...carrierEdited, country: e.target.value })}>
                      <option value="" >--</option>
                      <option value="PL" >PL - Poland</option>
                      <option value="IE" >IE - Ireland</option>
                      <option value="CH" >CH - Switzerland</option>
                      <option value="LT" >LT - Lithuania</option>
                      <option value="LV" >LV - Latvia</option>
                      <option value="DE" >DE - Germany</option>
                      <option value="SK" >SK - Slovakia</option>
                      <option value="CZ" >CZ - Czech Rep.</option>
                      <option value="RU" >RU - Russia</option>
                      <option value="BY" >BY - Belarus</option>
                    </select>
                  </div>
                </div>


                <label htmlFor="email">Email</label>
                <input type="mail" id="email" name="email" className="visent" value={carrierEdited?.email || ''} onChange={(e) => setCarrierEdited({ ...carrierEdited, email: e.target.value })} />

                <label htmlFor="failOverEmail">Failover Email</label>
                <input type="mail" id="failOverEmail" name="failOverEmail" className="visent" value={carrierEdited?.failOverEmail || ''} onChange={(e) => setCarrierEdited({ ...carrierEdited, failOverEmail: e.target.value })} />


                <div className="edit-form-buttons-bar">

                  <Button style={{ width: "100px" }} color="secondary" onClick={() => handleClose(true)}>
                    <Icon className="fas fa-undo" />
                    Cancel
                  </Button>

                  <Button type="submit" color="primary" style={{ width: "100px" }} disabled={!isAllValid || !anyChange}>
                    <Icon className="fas fa-save" />
                    Save
                  </Button>

                </div>
              </form>
            }

            {carrierError && <ServerCommunicationError errType={carrierError} />}

          </div>

        </div>

        <Dialog id="dialog-error-save" className="visent" open={errorDialogOpen} >
          <DialogTitle>Data Save Error</DialogTitle>
          <DialogContent>
            {carrierSaveError?.status && <DialogContentText>Response status: {carrierSaveError?.status}</DialogContentText>}
            <DialogContentText>Could not save the data.</DialogContentText>
            {carrierSaveError?.data?.errors &&
              <DialogContentText>
                {typeof (carrierSaveError?.data?.errors) === "string" ? carrierSaveError?.data?.errors : JSON.stringify(carrierSaveError?.data?.errors)}
              </DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setErrorDialogOpen(false)} color="primary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="dialog-no-chnages" className="visent" open={noChangesDialogOpen} >
          <DialogTitle>No Changes</DialogTitle>
          <DialogContent>
            <DialogContentText>No changes were discovered. No need to save data.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNoChangesDialogOpen(false)} color="secondary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="dialog-confirm-close" className="visent" open={confirmCloseDialogOpen} >
          <DialogTitle>Not Saved Changes</DialogTitle>
          <DialogContent>
            <DialogContentText>There are changes that have not been saved.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleSubmit(null)} color="primary" autoFocus>Save & Close</Button>
            <Button onClick={() => setConfirmCloseDialogOpen(false)} color="secondary" autoFocus>Back</Button>
            <Button onClick={() => handleClose(false)} color="secondary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

      </PageContainerDivStyled>
    </>
  );
}
