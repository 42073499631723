import styled from "styled-components";
import { appColors } from "./colors";

export enum NotificationBoxType {
  Info,
  Success,
  Warning,
  Error,
}

interface AlertDivStyledProps {
  color: string;
  backgroundColor: string;
  width?: number;
}

const AlertDivStyled = styled.div`
  width: ${({ width }: AlertDivStyledProps) => (width ? `${width}px` : undefined)};
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 6px;
  text-align: center;
  font-size: 16px;
  background-color: #ecf7e9;
  color: ${({ color }: AlertDivStyledProps) => color};
  background-color: ${({ backgroundColor }: AlertDivStyledProps) => backgroundColor};
  border-color: ${({ color }: AlertDivStyledProps) => color};
`;

interface NotificationBoxProps {
  message: string;
  type?: NotificationBoxType;
  backgroundColor?: string;
  width?: number;
}

export default function NotificationBox({ message, type, backgroundColor, width }: NotificationBoxProps) {
  function getColors(type: NotificationBoxType | undefined): AlertDivStyledProps {
    if (type === NotificationBoxType.Error) {
      return {
        color: appColors.red,
        backgroundColor: backgroundColor || appColors.lightRed,
      };
    }

    if (type === NotificationBoxType.Warning) {
      return {
        color: appColors.orange,
        backgroundColor: backgroundColor || appColors.lightOrange,
      };
    }

    if (type === NotificationBoxType.Info) {
      return {
        color: appColors.green,
        backgroundColor: backgroundColor || appColors.lightGreen,
      };
    }

    return {
      color: appColors.green,
      backgroundColor: backgroundColor || appColors.lightGreen,
    };
  }

  return (
    <div>
      <AlertDivStyled {...{ ...getColors(type), width: width }}>{message}</AlertDivStyled>
    </div>
  );
}
