import styled from "styled-components";
import { appColors } from "../colors";

export const TooltipHeaderDivStyled = styled.div`
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid ${appColors.white};
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
`;
