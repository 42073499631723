import React, { ErrorInfo } from "react";
import { logAppGlobalError, logError } from "../api/errorReporter";

window.onerror = (event: Event | string, _?, __?, ___?, error?: Error) => {
  logAppGlobalError(event, error);
};

export class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}
