import styled from "styled-components";

export const CellOuterDivStyled = styled.div`
  display: flex;
  height: 95%;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  margin: 0px 2px;
`;

export const CellMenuDivStyled = styled.div`
  font-size: 20px;
  flex-grow: 1;
  text-align: center;
  color: rgb(67, 67, 67, 0.5);
  cursor: pointer;

  &:hover {
    font-size: 23px;
    color: rgb(67, 67, 67, 0.9);
  }
`;

interface EditModeCellContainerDivStyledProps {
  fontSize: number;
  size?: number;
  color?: string;
}

export const EditModeCellContainerDivStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  padding-left: 1px;
  text-align: center;
  font-size: ${({ fontSize }: EditModeCellContainerDivStyledProps) => `${fontSize}px`};
  height: ${({ size }: EditModeCellContainerDivStyledProps) => (size ? `${size}px` : "95%")};
  width: ${({ size }: EditModeCellContainerDivStyledProps) => (size ? ` ${size}px` : "95%")};
  background-color: ${({ color }: EditModeCellContainerDivStyledProps) => (color ? color : "transparent")};
`;

interface SentStatusClosedIncompliantContainerDivStyledProps {
  iconColor: string;
  size: number;
}
export const SentStatusClosedIncompliantContainerDivStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 50%;
  border: ${({ iconColor }: SentStatusClosedIncompliantContainerDivStyledProps) => `3px solid ${iconColor}`};
  width: ${({ size }: SentStatusClosedIncompliantContainerDivStyledProps) => `${size}px`};
  height: ${({ size }: SentStatusClosedIncompliantContainerDivStyledProps) => `${size}px`};
  color: ${({ iconColor }: SentStatusClosedIncompliantContainerDivStyledProps) => iconColor};
  font-size: ${({ size }: SentStatusClosedIncompliantContainerDivStyledProps) => `${size / 2}px`};
`;
