import { TextField } from "@material-ui/core";
import { TaskErrorsItem } from "../../api/models";
import { booleanToString, dateWithTimeToString } from "../formatHelper";
import { SpacerHorizontalDivStyled, SpacerSize, SpacerVerticalDivStyled } from "../Spacer";
import { FlexDivStyled } from "../styledComponents";
import GridDialogItemContainer from "./GridDialogItemContainer";

interface TaskErrorItemDialogProps {
  item: TaskErrorsItem;
  isOpen: boolean;
  onCloseAction: () => void;
}

export default function TaskErrorItemDialog({ item, isOpen, onCloseAction }: TaskErrorItemDialogProps) {
  return (
    <GridDialogItemContainer isOpen={isOpen} onCloseAction={onCloseAction}>
      <>
        <FlexDivStyled>
          <TextField value={item.appliesTo} fullWidth disabled label="Applies to" variant="filled" size="small" />
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium }} />
          <TextField value={item.actionKey} fullWidth disabled label="Action key" variant="filled" size="small" />
        </FlexDivStyled>
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        <FlexDivStyled>
          <TextField value={item.taskId} fullWidth disabled label="Task id" variant="filled" size="small" />
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium }} />
          <TextField value={booleanToString(item.onHold)} fullWidth disabled label="On hold" variant="filled" size="small" />
        </FlexDivStyled>
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        <FlexDivStyled>
          <TextField
            value={dateWithTimeToString(item.firstAttemptAt)}
            fullWidth
            disabled
            label="First attempt at"
            variant="filled"
            size="small"
          />
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium }} />
          <TextField
            value={dateWithTimeToString(item.lastAttemptAt)}
            fullWidth
            disabled
            label="Last attempt at"
            variant="filled"
            size="small"
          />
        </FlexDivStyled>

        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        <FlexDivStyled>
          <TextField value={item.errCategory} fullWidth disabled label="Error category" variant="filled" size="small" />
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium }} />
          <TextField value={item.type} fullWidth disabled label="Type" variant="filled" size="small" />
          <SpacerHorizontalDivStyled {...{ size: SpacerSize.Medium }} />
          <TextField value={item.code} fullWidth disabled label="Code" variant="filled" size="small" />
        </FlexDivStyled>
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
        <TextField label="Description" fullWidth disabled value={item.desc} multiline contentEditable={false} rows={12} variant="filled" />
        <SpacerVerticalDivStyled {...{ size: SpacerSize.Medium }} />
      </>
    </GridDialogItemContainer>
  );
}
