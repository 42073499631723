import React, { SyntheticEvent, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AdminRoutes } from "../../routes/routeUtils";
import API from "../../api/api";
import ServerCommunicationError, { CommunicationErrorType } from "../../common/ServerCommunicationIssue";
import { Button, Icon, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { VehicleItem, VehicleKit, CarrierSimple } from "../../api/models";
import { PageContainerDivStyled } from "../pageStyledComponents";
import { validateGeoloc } from "../../services/miscellaneous";
import Loader from "../../common/Loader";

// Auxiliary objects
interface FormValidation {
  isIdOk: boolean,
  isPermitRoadOk: boolean,
  isGeolocOk: boolean,
  isFailoverGeolocOk: boolean,
  isCarrierOk: boolean
}


export default function VehicleEdit() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [vehicleEdited, setVehicleEdited] = useState<VehicleItem>();
  const [vehicleLoaded, setVehicleLoaded] = useState<VehicleItem>();
  const [vehicleLoading, setVehicleLoading] = useState<boolean>(false);
  const [vehicleError, setVehicleError] = useState<CommunicationErrorType>();
  const [carriers, setCarriers] = useState<CarrierSimple[]>();

  const [geolocBoxAlert, setGeolocBoxAlert] = useState<boolean>(false);
  const [fogeolocBoxAlert, setFogeolocBoxAlert] = useState<boolean>(false);

  const [vehicleSaving, setVehicleSaving] = useState<boolean>(false);
  const [vehicleSaveError, setVehicleSaveError] = useState<any>();
  const [vehicleSaveSuccess, setVehicleSaveSuccess] = useState<boolean>();
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [noChangesDialogOpen, setNoChangesDialogOpen] = useState<boolean>(false);

  const [anyChange, setAnyChange] = useState<boolean>(false);
  const [isAllValid, setIsAllValid] = useState(true);
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState(false);

  // Get data
  useEffect(() => {
    setVehicleLoading(true);
    setVehicleError(undefined);

    console.log(`Retrieving vehicle '${id}'`);

    let req: Promise<any>;
    req = API.get(`/Vehicles/VehicleKit/${id || "_"}`);
    req.then(resp => {
      let respContent: VehicleKit = resp.data;
      let tmpVehicle: VehicleItem = {
        vehicleId: '',
        permitRoad: '',
        geoLocNo: '',
        failOverGeoLocNo: '',
        carrier: {
          carrierId: NaN,
          carrierName: ''
        }
      };

      if (id && respContent?.vehicle) tmpVehicle = respContent.vehicle;

      tmpVehicle.carrier.carrierName = undefined;
      tmpVehicle.failOverGeoLocNo = tmpVehicle?.failOverGeoLocNo || '';
      setVehicleLoaded(tmpVehicle);
      setVehicleEdited(tmpVehicle);
      setCarriers(respContent?.carriers || []);
    })
      .catch(err => setVehicleError(err))
      .finally(() => setVehicleLoading(false))

  }, [id]);




  // Validate data changed in form controls 
  useEffect(() => {
    const geolocValid = validateGeoloc(vehicleEdited?.geoLocNo as string, false);
    const fogeolocValid = validateGeoloc(vehicleEdited?.failOverGeoLocNo as string, true);

    // Validate single values
    const newValidation: FormValidation = {
      isIdOk: vehicleEdited?.vehicleId.trim() !== '',
      isPermitRoadOk: vehicleEdited?.permitRoad?.trim() !== '',
      isGeolocOk: vehicleEdited?.geoLocNo !== '' && geolocValid,
      isFailoverGeolocOk: vehicleEdited?.failOverGeoLocNo === '' || fogeolocValid,
      isCarrierOk: !isNaN(vehicleEdited?.carrier?.carrierId as number) && vehicleEdited?.carrier?.carrierId !== -Infinity
    }

    // Check if all single values valid
    let allOK: boolean = true;
    Object.entries(newValidation).forEach(([key, value]) => {
      if (!value) allOK = false;
    });

    // Discover changes
    let hasChanged: boolean =
      vehicleLoaded?.vehicleId !== vehicleEdited?.vehicleId ||
      vehicleLoaded?.permitRoad !== vehicleEdited?.permitRoad ||
      vehicleLoaded?.geoLocNo !== vehicleEdited?.geoLocNo ||
      vehicleLoaded?.failOverGeoLocNo !== vehicleEdited?.failOverGeoLocNo ||
      vehicleLoaded?.carrier.carrierId !== vehicleEdited?.carrier.carrierId;

    setIsAllValid(allOK);
    setAnyChange(hasChanged);
    setGeolocBoxAlert(!geolocValid);
    setFogeolocBoxAlert(!fogeolocValid);
  }, [vehicleEdited, vehicleLoaded]);


  // function anyChange(): boolean {
  //   return (
  //     vehicleLoaded?.vehicleId !== vehicleEdited?.vehicleId ||
  //     vehicleLoaded?.permitRoad !== vehicleEdited?.permitRoad ||
  //     vehicleLoaded?.geoLocNo !== vehicleEdited?.geoLocNo ||
  //     vehicleLoaded?.failOverGeoLocNo !== vehicleEdited?.failOverGeoLocNo ||
  //     vehicleLoaded?.carrier.carrierId !== vehicleEdited?.carrier.carrierId
  //   );
  // }


  // Submit data
  function handleSubmit(event: SyntheticEvent | null) {
    event?.preventDefault();

    console.log("Initial Vehicle:", vehicleLoaded);
    console.log("Modified Vehicle:", vehicleEdited);
    console.log("Posting to API...")

    setVehicleSaving(true);
    setVehicleSaveError(undefined);
    let request: Promise<any>;
    if (id) {
      request = API.put("/Vehicles", vehicleEdited);
    }
    else {
      request = API.post("/Vehicles", vehicleEdited);
    }

    request
      .then(resp => {
        console.log("Successfully posted");
        console.log(resp);
        setVehicleSaveSuccess(true);
      })
      .catch(err => {
        setVehicleSaveError(err.response);
        console.log("Ooops! An error occurred.")
        console.log(err.response);
        console.log(err.response?.data?.errors);
        setErrorDialogOpen(true);
      })
      .finally(() => setVehicleSaving(false));

  };


  // Close without saving
  const handleClose = (confirmToClose: boolean = true) => {
    if (confirmToClose && anyChange && isAllValid)
      setConfirmCloseDialogOpen(true);
    else
      history.push(AdminRoutes.Vehicles);
  }


  // Redirect to 'Vehicles' after successful save
  useEffect(() => { if (vehicleSaveSuccess) history.push(AdminRoutes.Vehicles) }, [history, vehicleSaveSuccess]);

  return (
    <>
      <Loader isLoading={vehicleSaving || vehicleLoading} />

      <PageContainerDivStyled >

        <div className="edit-main">
          <div className="edit-header">{id ? "Edit Vehicle" : "Add Vehicle"}</div>
          <div className="edit-contents">

            {!vehicleError &&
              <form onSubmit={handleSubmit}>

                <label htmlFor="vehicleId">Vehicle ID</label>
                {!id && <input type="text" id="vehicleId" name="vehicleId" className="visent" value={vehicleEdited?.vehicleId} required onChange={(e) => setVehicleEdited({ ...vehicleEdited, vehicleId: e.target.value } as VehicleItem)} />}
                {id && <input type="text" id="vehicleId" name="vehicleId" className="visent" defaultValue={vehicleEdited?.vehicleId} required disabled />}

                <label htmlFor="permitRoad">Permit Road</label>
                <input type="text" id="permitRoad" name="permitRoad" className="visent" value={vehicleEdited?.permitRoad || ''} required onChange={(e) => setVehicleEdited({ ...vehicleEdited, permitRoad: e.target.value } as VehicleItem)} />

                <label htmlFor="geoLocNo">Geolocation Number</label>
                <input type="text" id="geoLocNo" name="geoLocNo" value={vehicleEdited?.geoLocNo || ''} required className={geolocBoxAlert ? "visent edit-input-error" : "visent"} onChange={(e) => setVehicleEdited({ ...vehicleEdited, geoLocNo: e.target.value.toUpperCase() } as VehicleItem)} />

                <label htmlFor="failOverGeoLocNo">Failover Geolocation Number</label>
                <input type="text" id="failOverGeoLocNo" name="failOverGeoLocNo" value={vehicleEdited?.failOverGeoLocNo || ''} className={fogeolocBoxAlert ? "visent edit-input-error" : "visent"} onChange={(e) => setVehicleEdited({ ...vehicleEdited, failOverGeoLocNo: e.target.value.toUpperCase() } as VehicleItem)} />

                <label htmlFor="carrierId">Carrier</label>
                <select id="carrierId" name="carrierId" className="visent" value={vehicleEdited?.carrier.carrierId.toString()} required onChange={(e) => setVehicleEdited({ ...vehicleEdited, carrier: { carrierId: parseInt(e.target.value), carrierName: undefined } } as VehicleItem)}>
                  <option key="-" defaultValue={-Infinity}>--pick carrier--</option>
                  {carriers?.map((c) => (<option key={c.carrierId.toString()} value={c.carrierId.toString()} >{`${c.carrierId} ${c.carrierName}`}</option>))}
                </select>

                <div className="edit-form-buttons-bar">

                  {/* <Button style={{ width: "100px" }} color="secondary" onClick={() => history.push(AdminRoutes.Vehicles)}> */}
                  <Button style={{ width: "100px" }} color="secondary" onClick={() => handleClose(true)}>
                    <Icon className="fas fa-undo" />
                    Cancel
                  </Button>

                  <Button type="submit" color="primary" style={{ width: "100px" }} disabled={!isAllValid || !anyChange}>
                    <Icon className="fas fa-save" />
                    Save
                  </Button>

                </div>
              </form>
            }

            {vehicleError && <ServerCommunicationError errType={vehicleError} />}

          </div>

        </div>

        <Dialog id="dialog-error-save" className="visent" open={errorDialogOpen} >
          <DialogTitle>Data Save Error</DialogTitle>
          <DialogContent>
            {vehicleSaveError?.status && <DialogContentText>Response status: {vehicleSaveError?.status}</DialogContentText>}
            <DialogContentText>Could not save the data.</DialogContentText>
            {vehicleSaveError?.data?.errors && <DialogContentText>{vehicleSaveError?.data?.errors}</DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setErrorDialogOpen(false)} color="primary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="dialog-no-chnages" className="visent" open={noChangesDialogOpen} >
          <DialogTitle>No Changes</DialogTitle>
          <DialogContent>
            <DialogContentText>No changes were discovered. No need to save data.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNoChangesDialogOpen(false)} color="secondary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog id="dialog-confirm-close" className="visent" open={confirmCloseDialogOpen} >
          <DialogTitle>Not Saved Changes</DialogTitle>
          <DialogContent>
            <DialogContentText>There are changes that have not been saved.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleSubmit(null)} color="primary" autoFocus>Save & Close</Button>
            <Button onClick={() => setConfirmCloseDialogOpen(false)} color="secondary" autoFocus>Back</Button>
            <Button onClick={() => handleClose(false)} color="secondary" autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

      </PageContainerDivStyled>
    </>
  );
}
