import { Paper } from "@material-ui/core";
import styled from "styled-components";
import { appColors } from "../../colors";

export const SectionPaperStyled = styled(Paper)`
  width: ${({ isFullWidth }: { isFullWidth?: boolean }) => (isFullWidth ? "100%" : "31.8%")};
  margin: 5px;
  padding: 5px 5px 8px 5px;
`;

export const SectionHeaderDivStyled = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  background-color: #f5f5f5;
  border-bottom: 1px solid ${appColors.orange};
  border-radius: 1px;
  padding: 1px 10px 3px 5px;
`;

export const SectionRowContainer = styled.div`
  display: flex;
`;
