import { GuardFunctionRouteProps, GuardToRoute, Next } from "react-router-guards/dist/types";
import { isUserInRole } from "../../common/secrityUtils";
import { MissingRightsToAccess, AdminRoutes } from "../routeUtils";

export default function adminGuard(to: GuardToRoute, _: GuardFunctionRouteProps | null, next: Next): void {
  const supportedRoutes = Object.values(AdminRoutes);

  if (supportedRoutes.find((x) => x === to.history.location.pathname)) {
    if (!isUserInRole("Admin")) {
      next.redirect(MissingRightsToAccess);
      return;
    }
  }

  next();
  return;
}
