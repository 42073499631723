import React from "react";
import { Button, FormControlLabel, Switch } from "@material-ui/core";
import ConfirmationDialog from "./dialogs/ConfirmationDialog";
import styled from "styled-components";

export enum FilterMode {
  Default,
  Custom,
}

const PageTitleDivStyled = styled.div`
  display: flex;
  justify-content: space-between;

  h3 {
    margin-left: 10px;
    font-size: 16px !important;
    text-transform: uppercase;
    color: #313131;
    font-weight: bold !important;
  }
`;

const FilterDivStyled = styled.div`
  display: flex;
  align-items: center;
`;

const ProcessButtonStyled = styled.div`
  width: 200px;
  margin-right: 10px;
`;

interface PageTitleProps {
  title: string;
  showProcessButton: boolean;
  defaultFilterMode?: FilterMode;
  onFilteringStatusChanged: (mode: FilterMode) => void;
  onRefreshButtonCliecked: () => void;
  onProcessButtonClickConfirmed: () => void;
}

export default function PageTitle({
  title,
  showProcessButton: processButtonVisible,
  defaultFilterMode,
  onFilteringStatusChanged,
  onRefreshButtonCliecked,
  onProcessButtonClickConfirmed,
}: PageTitleProps) {
  const [filterMode, setFilterMode] = React.useState<FilterMode>(defaultFilterMode || FilterMode.Default);

  const [isConfirmationVisible, setIsConfirmationVisible] = React.useState<boolean>(false);

  const onConfirmationClicked = (isConfirmed: boolean) => {
    setIsConfirmationVisible(false);

    if (isConfirmed) {
      onProcessButtonClickConfirmed();
    }
  };

  const setFilterModeAction = (currentValue: FilterMode) => {
    let newValue: FilterMode = currentValue === FilterMode.Default ? FilterMode.Custom : FilterMode.Default;
    onFilteringStatusChanged(newValue);

    return newValue;
  };

  const getPageTitle = () => {
    if (filterMode === FilterMode.Default) {
      return `${title} - default view `;
    }

    return `${title} - custom filters view `;
  };

  return (
    <>
      <PageTitleDivStyled>
        <h3>{getPageTitle()}</h3>
        <FilterDivStyled>
          {processButtonVisible && (
            <ProcessButtonStyled>
              <Button
                style={{ marginRight: 10 }}
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => setIsConfirmationVisible(true)}
                fullWidth>
                Process
              </Button>
            </ProcessButtonStyled>
          )}
          <div>
            {filterMode === FilterMode.Default && (
              <Button variant="contained" color="primary" size="large" onClick={() => onRefreshButtonCliecked()}>
                Refresh
              </Button>
            )}
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch checked={filterMode === FilterMode.Custom} onChange={() => setFilterMode(setFilterModeAction)} color="primary" />
              }
              label="Set custom filters"
            />
          </div>
        </FilterDivStyled>
      </PageTitleDivStyled>
      <ConfirmationDialog isOpen={isConfirmationVisible} onCloseAction={onConfirmationClicked}></ConfirmationDialog>
    </>
  );
}
