import React from "react";
import { TripInfo } from "../../../api/models";
import { dateToString } from "../../formatHelper";
import { MasterDataMissing } from "./MasterDataMissing";
import { SectionHeaderDivStyled, SectionPaperStyled, SectionRowContainer } from "./sectionsStyledComponents";
import { SectionValue } from "./SectionValue";

export function TripInfoSection({ tripId, tripInfo }: { tripId: string; tripInfo: TripInfo | undefined }) {
  // Total width: 174
  const labelWidth = 110;
  const valueWidth = 174 - labelWidth;
  return (
    <SectionPaperStyled elevation={4}>
      <SectionHeaderDivStyled>Trip Information</SectionHeaderDivStyled>
      {!tripInfo && <MasterDataMissing />}
      {tripInfo && (
        <>
          <SectionValue label="Trip ID" value={tripId} labelWidth={labelWidth}></SectionValue>
          <SectionValue label="Planned start date" value={dateToString(tripInfo.plannedStartDate)} labelWidth={labelWidth}></SectionValue>
          <SectionRowContainer>
            <SectionValue
              label="Start transp. date"
              value={dateToString(tripInfo.startTransportDate)}
              labelWidth={labelWidth}
              valueWidth={valueWidth}></SectionValue>
            <SectionValue
              label="End transp. date"
              value={dateToString(tripInfo.endTransportDate)}
              labelWidth={labelWidth}
              valueWidth={valueWidth}></SectionValue>
          </SectionRowContainer>
          <SectionRowContainer>
            <SectionValue
              label="Number of orders"
              value={tripInfo.orderCount?.toString()}
              labelWidth={labelWidth}
              valueWidth={valueWidth}></SectionValue>
            <SectionValue
              label="Total amount"
              value={tripInfo.totalAmount?.toString()}
              labelWidth={labelWidth}
              valueWidth={valueWidth}></SectionValue>
          </SectionRowContainer>
        </>
      )}
    </SectionPaperStyled>
  );
}
