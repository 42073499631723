import NotificationBox, { NotificationBoxType } from "../../Notification";
import { NotificationBoxContainerFlexDivStyled } from "../../styledComponents";

export function MasterDataMissing() {
  return (
    <NotificationBoxContainerFlexDivStyled>
      <NotificationBox type={NotificationBoxType.Error} width={300} message="Master data missing" />
    </NotificationBoxContainerFlexDivStyled>
  );
}
