import React from "react";
import { SentInfo } from "../../../api/models";
import { MasterDataMissing } from "./MasterDataMissing";
import { SectionHeaderDivStyled, SectionPaperStyled, SectionRowContainer } from "./sectionsStyledComponents";
import { SectionValue } from "./SectionValue";

export function SentInformationSection({ sentInfo, isLpg }: { sentInfo: SentInfo | undefined; isLpg: boolean }) {
  const labelWidth = 120;
  const valueWidth = 200;
  return (
    <SectionPaperStyled elevation={4} isFullWidth={true}>
      <SectionHeaderDivStyled>SENT information</SectionHeaderDivStyled>
      {!sentInfo && <MasterDataMissing />}
      {sentInfo && (
        <>
          <SectionRowContainer>
            <SectionValue label="Sent process" value={sentInfo.sentProcess} labelWidth={labelWidth} valueWidth={valueWidth} />
            {isLpg && (
              <SectionValue label="Multi SENT Number" value={sentInfo.multipleSentNumber} labelWidth={labelWidth} valueWidth={valueWidth} />
            )}

            <SectionValue label="SENT Number" value={sentInfo.sentNumber} labelWidth={labelWidth} valueWidth={valueWidth} />
          </SectionRowContainer>
          <SectionRowContainer>
            <SectionValue label="Sender key" value={sentInfo.senderKey} labelWidth={labelWidth} valueWidth={valueWidth} />
            <SectionValue label="Carier key" value={sentInfo.carrierKey} labelWidth={labelWidth} valueWidth={valueWidth} />
            <SectionValue label="Recipient key" value={sentInfo.recipientKey} labelWidth={labelWidth} valueWidth={valueWidth} />
          </SectionRowContainer>
          <SectionRowContainer>
            <SectionValue label="Sent status" value={sentInfo.sentStatus} labelWidth={labelWidth} valueWidth={valueWidth} />
            <SectionValue label="Account" value={sentInfo.account} labelWidth={labelWidth} valueWidth={valueWidth} />
          </SectionRowContainer>
        </>
      )}
    </SectionPaperStyled>
  );
}
