import React from "react";
import API from "../../api/api";

import { DialogPayload, getCommunicationErrorType } from "../../api/common";
import { DataGrid, GridCellParams, GridColDef, GridRowParams } from "@material-ui/data-grid";
import { prepareDialogGridData } from "../../services/dialogGrid";
import GridDialogContainer from "./GridDialogContainer";
import { History, HistoryItem, TaskErrorsItem } from "../../api/models";
import SentHistoryItemDialog from "./SentHistoryItemDialog";
import { logGridError } from "../../api/errorReporter";
import ServerCommunicationError, { CommunicationErrorType } from "../ServerCommunicationIssue";
import { CellHeaderTooltipComponent } from "../gridHeaders/CellHeaderTooltipComponent";
import { appColors } from "../colors";
import { IconIStyled } from "../styledComponents";
import { useGridStyles } from "../gridCustomTheme";

interface SentHistoryDialogProps {
  dialogPayload: DialogPayload;
  isOpen: boolean;
  onCloseAction: () => void;
}

export default function SentHistoryDialog({ dialogPayload, isOpen, onCloseAction }: SentHistoryDialogProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [communicationError, setCommunicationError] = React.useState<CommunicationErrorType>();
  const [sentHistory, setSentHistory] = React.useState<History>();

  const [isDetailsOpen, setIsDetailsOpen] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<HistoryItem>();

  React.useEffect(() => {
    setSentHistory(undefined);
    setCommunicationError(undefined);
    setLoading(true);
    API.get(`/history/${dialogPayload.tripSourceId}/${dialogPayload.tripId}/${dialogPayload.orderId}`)
      .then((response: any) => setSentHistory(response.data as History))
      .catch((err: any) => setCommunicationError(getCommunicationErrorType(err)))
      .finally(() => setLoading(false));
  }, [dialogPayload]);

  const renderCommunicationWIthPuesc = (params: GridCellParams) => {
    const rowData: HistoryItem = params.row as any as HistoryItem;
    if (rowData.isSent) {
      return <IconIStyled className="far fa-check-circle" {...{ size: 18, color: appColors.green }} />;
    }

    return <></>;
  };

  const renderIsErrorCell = (params: GridCellParams) => {
    const rowData: HistoryItem = params.row as any as HistoryItem;

    if (rowData.isError) {
      return <IconIStyled className="fas fa-exclamation-circle" {...{ size: 18, color: appColors.red }} />;
    }

    return <></>;
  };

  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: "Date",
      width: 120,
      type: "dateTime",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "appliesTo",
      headerName: "Applies to",
      width: 60,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "isSent",
      width: 30,
      disableColumnMenu: true,
      sortable: false,
      renderCell: renderCommunicationWIthPuesc,
      renderHeader: () => <CellHeaderTooltipComponent tooltipText="Communication with PUESC?" />,
    },
    {
      field: "isError",
      width: 30,
      disableColumnMenu: true,
      sortable: false,
      renderCell: renderIsErrorCell,
      renderHeader: () => <CellHeaderTooltipComponent tooltipText="Error?" />,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  const onRowClicked = (param: GridRowParams) => {
    var rowData = param.row as any as TaskErrorsItem;
    setSelectedItem(rowData);
    setIsDetailsOpen(true);
  };

  const classes = useGridStyles();

  return (
    <>
      <GridDialogContainer
        title="Sent history"
        dialogPayload={dialogPayload}
        isOpen={isOpen}
        loading={loading}
        onCloseAction={onCloseAction}>
        <>
          {communicationError && <ServerCommunicationError errType={communicationError} />}
          {sentHistory && (
            <DataGrid
              className={classes.root}
              rows={prepareDialogGridData(sentHistory.entries)}
              columns={columns}
              density="compact"
              disableColumnReorder
              disableSelectionOnClick
              disableColumnResize
              headerHeight={48}
              pageSize={50}
              rowsPerPageOptions={[]}
              onRowClick={onRowClicked}
              sortingMode="client"
              sortModel={[{ field: 'timestamp', sort: 'desc' }]}
              onError={logGridError}></DataGrid>
          )}
        </>
      </GridDialogContainer>
      {selectedItem && (
        <SentHistoryItemDialog
          item={selectedItem}
          isOpen={isDetailsOpen}
          onCloseAction={() => setIsDetailsOpen(false)}></SentHistoryItemDialog>
      )}
    </>
  );
}
