import { Checkbox } from "@material-ui/core";
import React from "react";

export function CellIsSelectedComponent({
  isSelected,
  isSelectionEnabled,
}: {
  isSelected: boolean;
  isSelectionEnabled: boolean | undefined;
}): React.ReactElement {
  return React.useMemo(
    () => <Checkbox disabled={!isSelectionEnabled} checked={isSelected} disableRipple></Checkbox>,
    [isSelected, isSelectionEnabled]
  );
}
