import { Configuration } from "@azure/msal-browser";
import { AZURE_AD_SETTINGS } from "./common/secrityUtils";

export const msalConfig: Configuration = {
  auth: {
    clientId: AZURE_AD_SETTINGS.clientId,
    authority: AZURE_AD_SETTINGS.authority,
    postLogoutRedirectUri: AZURE_AD_SETTINGS.postLogoutRedirectUri,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

export const loginScopes = ["openid", "profile", "user.read"];
export const apiScopes = AZURE_AD_SETTINGS.azureAdApiScopes.replace(" ", "").split(",");
