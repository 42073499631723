export const appColors = {
  gray: "#e7eaec",
  lightGray: "#e5e5e5",
  blue: "#004b87",
  lightBlue: "rgb(0, 75, 135, 0.07)",
  green: "#43b02a",
  lightGreen: "rgb(67, 176, 42, 0.07)",
  orange: "#ed8b00",
  lightOrange: "rgb(237, 139, 0, 0.07)",
  red: "#da291c",
  lightRed: "rgb(218, 41, 28, 0.07)",
  white: "white",
  grey: "grey",
  lightgrey: "lightgrey",
  black: "black",
};
