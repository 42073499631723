import React from "react";
import { CarrierInfo } from "../../../api/models";
import { addressLine1, addressLine2 } from "../../formatHelper";
import { MasterDataMissing } from "./MasterDataMissing";
import { SectionHeaderDivStyled, SectionPaperStyled, SectionRowContainer } from "./sectionsStyledComponents";
import { SectionAddressValue, SectionValue } from "./SectionValue";

export function CarrierInfoSection({ carrier }: { carrier: CarrierInfo | undefined }) {
  const labelWidth = 90;
  const valueWidth = 85;
  return (
    <SectionPaperStyled elevation={4}>
      <SectionHeaderDivStyled>Carrier</SectionHeaderDivStyled>
      {!carrier && <MasterDataMissing />}
      {carrier && (
        <>
          <SectionRowContainer>
            <SectionValue label="ID" value={carrier.id?.toString()} labelWidth={labelWidth} valueWidth={valueWidth}></SectionValue>
            <SectionValue label="NIP" value={carrier.idNumber?.toString()} labelWidth={labelWidth} valueWidth={valueWidth}></SectionValue>
          </SectionRowContainer>
          <SectionValue label="Name" value={carrier.name?.toString()} labelWidth={labelWidth}></SectionValue>
          <SectionAddressValue
            label="Address"
            labelWidth={labelWidth}
            addressLine1={addressLine1(carrier.street, carrier.houseNumber!)}
            addressLine2={addressLine2(carrier.postalCode, carrier.city, carrier.country)}></SectionAddressValue>
          <SectionValue label="Email" value={carrier.email} labelWidth={labelWidth}></SectionValue>
          <SectionValue label="Failover email" value={carrier.failOverEmail} labelWidth={labelWidth}></SectionValue>
        </>
      )}
    </SectionPaperStyled>
  );
}
