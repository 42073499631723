export enum LpgFuelRoutes {
  Fuel = "/fuel",
  Lpg = "/lpg",
}

export enum AdminRoutes {
  AdminPage = "/admin",
  SystemSettings = "/admin/settings",
  Vehicles = "/admin/vehicles",
  //VehicleDelete = "/admin/vehicle/:id",
  VehicleEdit = "/admin/vehicle/:id",
  VehicleAdd = "/admin/vehicle",
  Carriers = "/admin/carriers",
  //CarrierDelete = "/admin/carrier/:id",
  CarrierEdit = "/admin/carrier/:id",
  CarrierAdd = "/admin/carrier",
  Depots = "/admin/depots",
  //DepotDelete = "/admin/depot/:id",
  DepotEdit = "/admin/depot/:id",
  DepotAdd = "/admin/depot",
  Suppliers = "/admin/suppliers",
  //SupplierDelete = "/admin/supplier/:id",
  SupplierEdit = "/admin/supplier/:id",
  SupplierAdd = "/admin/supplier",
  Users = "/admin/users",
  //UserDelete = "/admin/user/:upn",
  UserEdit = "/admin/user/:upn",
  UserAdd = "/admin/user",
  Planner = "/admin/planner/:upn",
  //PlannerDelete = "/admin/planner/:upn/:partner",
  PlannerEdit = "/admin/planner/:upn/:partner",
  AdminHistory = "/admin/history"
}

export enum AnyUserRoutes {
  UserInfo = "/userinfo"
}

export const MissingRightsToAccess = "/missingRightToAccess";
