import React from "react";
import { DepotInfo } from "../../../api/models";
import { addressLine1, addressLine2, latLonToString } from "../../formatHelper";
import { MasterDataMissing } from "./MasterDataMissing";
import { SectionHeaderDivStyled, SectionPaperStyled, SectionRowContainer } from "./sectionsStyledComponents";
import { SectionAddressValue, SectionValue } from "./SectionValue";

export function DepotInfoSection({ depot }: { depot: DepotInfo | undefined }) {
  const labelWidth = 80;
  return (
    <SectionPaperStyled elevation={4}>
      <SectionHeaderDivStyled>Place of Loading</SectionHeaderDivStyled>
      {!depot && <MasterDataMissing />}
      {depot && (
        <>
          <SectionValue label="Depot ID" value={depot.id?.toString()} labelWidth={labelWidth}></SectionValue>
          <SectionValue label="Depot Name" value={depot.name} labelWidth={labelWidth}></SectionValue>
          <SectionRowContainer>
            <SectionValue label="Supplier ID" value={depot.supplierId?.toString()} labelWidth={labelWidth}></SectionValue>
            <SectionValue label="Supplier Name" value={depot.supplierName}></SectionValue>
          </SectionRowContainer>
          <SectionAddressValue
            label="Address"
            addressLine1={addressLine1(depot.street, depot.houseNumber)}
            addressLine2={addressLine2(depot.postalCode, depot.city, depot.country, depot.district)}
            labelWidth={labelWidth}></SectionAddressValue>
          <SectionValue label="Lat/Lon" value={latLonToString(depot.lat, depot.lon)} labelWidth={labelWidth}></SectionValue>
        </>
      )}
    </SectionPaperStyled>
  );
}
